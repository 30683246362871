
footer {
    background: $color-lightgrey;
    margin: 20px 0 0 230px;
    transition: margin-left .2s ease;
    overflow: hidden;
    min-height: 90px;
    font-size: 14px;
    color: lighten($font-color,25%);

    .inner{
        overflow: hidden;
        margin: $space auto;
        overflow: hidden;
        max-width: 1200px;
        padding: 0 $space;
    }
    .right{
        float: right;

    }

    #lang-nav{
        margin-bottom: $space*2;
        li {
            float: left;
            list-style: none;
            margin-right: 30px;
            font-size: 16px;

            svg{
                width: 18px;
                height: 18px;
            }
        }
    }
    #meta-nav{
        li {
            float: left;
            list-style: none;
            margin-right: $space;

            a{
                color: lighten($font-color,25%);
            }
        }
    }
}

@media(max-width:1679px){
    footer{
        margin-left: 17%;
    }
}

@media(max-width:1449px){
    footer{
        margin-left: 20%;
    }
}

@media(max-width:1179px){
    footer{
        margin-left: 22%;
    }
}

@media(max-width:1023px){
    footer{
        margin-left: 0;
    }
}
@media(max-width:1023px){
    footer{
        .right{
            float: none;
            display: block;

            ul{
                display: block;
                width: 100%;
                overflow: hidden;
            }
        }
        #lang-nav, #meta-nav{
            margin-bottom: $space;        
        }
    }
}