.meta-row{
    overflow: hidden;
    margin-bottom: $space;
    
    .dvgui-form-group{
        margin-top: -$space; 
        margin-bottom:  10px; 
    }
    .btn{
        margin-bottom: 20px;
    }
}
#belvoto{
    width: 100%;
    height: 100vh;
    height: calc(100vh - 125px);
    margin-top: 1px;
}