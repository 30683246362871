$input-padding-top:                     $space;
$input-margin-bottom:                   $space;

$input-font-color:                      $color-darkgrey;
$input-bg-color:                        $color-white;
$input-border-color:                    $color-grey;
$input-font-size:                       16px;
$input-font-weight:                     400;
$input-linehight:                       25px;

$input-label-font-color:                $color-grey;
$input-label-font-size:                 16px;
$input-label-opacity:                   1;
$input-focus-label-font-color:          darken($color-grey, 30);
$input-focus-label-font-size:           13px;
$input-help-font-color:                 $color-darkgrey;

$input-radio-color:                     $color-green;


.dvgui-form-group {  
	position: relative;    
  	margin: 0 0 $input-margin-bottom 0;  
  	padding-top: $input-padding-top;   
    width: 100%;

	.dvgui-text,
    .dvgui-select,
    .dvgui-radiogroup,     
    .dvgui-checkboxgroup,     
    .dvgui-documentupload,
    .dvgui-imageupload{     
        display: block;
        width: 100%;
        padding: 5px 15px;
        border-radius: 0; 
		border: solid 1px $input-border-color;
		background: $input-bg-color;
        color: $input-font-color;
		font-size: $input-font-size;
        font-weight: $input-font-weight;
        line-height: $input-linehight;
		box-shadow: none;
        position: relative;
        font-family: $font-light;

        
        ~ label {
			width: 100%;
			position: absolute;  
            top: 26px;       
			left: 15px;       
			bottom: 0;  
			pointer-events: none;     
            
            font-size: $input-label-font-size;
            transition: 0.2s ease all;
            color: $input-label-font-color;
            line-height: $line-height;
            margin: 0;
        }
        ~ .help-block {
            color: $input-help-font-color;
            display: none;
            &:not(:empty){
                opacity: 1;
            }
        }
        /*
   		&.edited:not([readonly]),
		&:focus:not([readonly]) {
			~ label {
				color: $input-focus-label-font-color;  
				left: 0; 
				opacity: 1;
			}
		}  */
		&:focus:not([readonly]) {
			~ .help-block {
				color: $input-help-font-color;    
                display: block;
				opacity: 1;
			}
		} 
        
        &[readonly],
        &.edited,
        &:focus:not([readonly]),  
        &.focus:not([readonly]) {			 
            ~ label {
				left: 0; 
				color: $input-focus-label-font-color;  
                top: 0; 
                font-size: $input-focus-label-font-size;            
            }   
        }
        &[disabled],
        &[readonly]{
            cursor: not-allowed;
        }
        
    }
    .trumbowyg-box {
        margin: 0 auto;
        ~ label {
			width: 100%;
			position: absolute;  
			bottom: 0;  
			pointer-events: none;     
           
            transition: 0.2s ease all;
            line-height: $line-height;
            margin: 0;
            
            left: 0; 
            color: $input-focus-label-font-color;  
            top: 0; 
            font-size: $input-focus-label-font-size;            

        }
    }
 
    .dvgui-select {     
		padding: 2px 30px 2px 10px;
        appearance: none;
        text-indent: -2px;
        outline: 0;
        appearance: none;
        text-indent: -2px;
        outline: 0;
        ~ label {        
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='10' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23666666' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: right 18px bottom 12px;
        }
   		&.edited:not([readonly]),
		&:focus:not([readonly]) {
			~ label {
				background-position: right 8px bottom 12px;
			}
		}  
    }
    .help-block { 
		position: absolute;
	  	margin: 2px 0 0 0;   
	  	@include opacity(0);   
	  	font-size: 13px;
	}
    &.child{
        margin-top: -35px;
        
        .dvgui-text,
        .dvgui-select,
        .dvgui-radiogroup,
        .dvgui-checkboxgroup{     
            
            &[readonly],
            &.edited,
            &:focus:not([readonly]),  
            &.focus:not([readonly]) {			 
                ~ label {
                    font-size: 0;            
                }   
            }
        }
    }

	.help-block { 
		position: absolute;
	  	margin: 2px 0 0 0;   
	  	@include opacity(0);   
	  	font-size: 13px;
	}
    
    .dvgui-documentupload-container,
    .dvgui-imageupload-container{
        display: flex;
        
        .documentupload-button,
        .imageupload-button{
            margin-right: 50px;
            overflow: hidden;
            max-height: 35px;
        }
        
        .documentupload-result,
        .imageupload-result{
            max-width: 390px;
            width: calc(100% - 240px);
        }
        
        .documentupload-item,
        .imageupload-item{
            height: 35px;
            margin-bottom: 5px;
            
            p {
                text-overflow: "...";
                overflow: hidden;
                white-space: nowrap;
                width: calc(100% - 70px);
                display: inline-block;
                padding-top: 5px;
                
            }
        }
        .documentupload-delete,
        .imageupload-delete{
            margin-left: 20px;
            float: right;
        }
    }
    .dvgui-documentupload,
    .dvgui-imageupload{
        display: block; 
        opacity: 0;
        position: absolute;
        top: 20px;
        left: 0;
        font-size: 50px !important;
        direction: ltr;
        cursor: pointer;
        width: 185px;
        height: 35px;
        
        ~ label {        
            top: 0; 
            color: $input-focus-label-font-color;  
            left: 0; 
            opacity: 1;
            font-size: $input-focus-label-font-size;            
        }
        .dvgui-upload-delete{
            margin-left: 20px;
            border: 1px solid $color-darkgrey;
            padding: 5px;
            text-align: center;
            vertical-align: middle;
            display: inline-block;
            background: $color-grey;
            line-height: 10px;
            cursor: pointer;
            
            &:hover{
                background: $color-green;
            }
        }
        .note{
            margin: 0;
            margin-left: $space;
            flex-grow: 2;
        }
        .loading{
            width: 100px;
            height: 30px;
            margin-left: $space;
        }
        .dvgui-imageupload-preview{
            height: 60px;
            vertical-align: top;
        }

    }
    
    .dvgui-radio {
        position:relative;
        display: flex;
        margin: 10px 0;

        &.inline{
            display: inline-block;
            margin-right: $space;
        }
        input[type=radio]   { 
            visibility: hidden;
            position: absolute;   
        }

        label {   
            cursor:pointer; 
            padding-left: 30px;
            margin-bottom: 0;
            line-height: 21px;
            position: relative;
        }

        label > span { 
            display:block;    
            position:absolute; 
            left:0; 
            -webkit-transition-duration:0.3s; 
            -moz-transition-duration:0.3s; 
            transition-duration:0.3s;
        }

        label > span.inc   { 
          background: #fff;
          left:-20px;  
          top:-20px;
          height:60px;
          width:60px;
          opacity:0; 
          border-radius:50% !important;
          -moz-border-radius:50% !important;
          -webkit-border-radius:50% !important;
        }

        label > .box {
            top: 0px;
            height:20px;   
            width:20px;
            border-radius:50% !important;
           -moz-border-radius:50% !important;
           -webkit-border-radius:50% !important;
           z-index:5;
           border: solid 1px $input-font-color;
        }

        label > .check {
          top: 5px;
          left: 5px;   
          width: 10px;
          height: 10px;
          background: $input-font-color;  
          opacity:0; 
          z-index:6;  

          border-radius:50% !important;
          -moz-border-radius:50% !important;
          -webkit-border-radius:50% !important;

          -webkit-transform   :scale(0);
          -moz-transform      :scale(0);
          transform           :scale(0);
        }

        /* handling click events */

        /* when radio is checked */
        label > span.inc {
          -webkit-animation   :growCircle 0.3s ease;
          -moz-animation      :growCircle 0.3s ease;
          animation           :growCircle 0.3s ease;   
        }

        input[type=radio]:checked ~ label > .check {
          opacity:1; 
          -webkit-transform   :scale(1);
          -moz-transform      :scale(1);
          transform           :scale(1);
        }

        // Disabled state
        input[type=radio]:disabled ~ label,
        input[type=radio][disabled] ~ label {
            cursor: not-allowed;   
            @include opacity(0.5); 
        }

        input[type=radio]:disabled ~ label > .box,
        input[type=radio][disabled] ~ label > .box { 
            cursor: not-allowed;
            @include opacity(0.5);
        }

        input[type=radio]:disabled:checked ~ label > .check, 
        input[type=radio][disabled]:checked ~ label > .check {           
            cursor: not-allowed;
            @include opacity(0.5);
        }
    }
    .dvgui-radiogroup,
    .dvgui-checkboxgroup{

        ~ label {        
            top: 0; 
            color: $input-focus-label-font-color;  
            left: 0; 
            opacity: 1;
            font-size: $input-focus-label-font-size;            
        }


    }

    .dvgui-checkbox {
        position:relative;
        display: flex;
        margin: 10px 0;
        &:first-child{
            margin-top: 8px;
        }

        ~ label {        
            top: 0; 
            font-size: 13px;            
            color: $input-focus-label-font-color;  

            width: 100%;
            position: absolute;  
            left: 0;
        }

        // Inline checkbox

        &.md-checkbox-inline {
          display: inline-block;    
        }

        .form-inline &.md-checkbox-inline {
          margin-right: 20px;
          top: 3px;
        }

        input[type=checkbox]   { 
            visibility: hidden;
            position: absolute;   
        }

        label {   
            cursor:pointer; 
            padding-left: 30px;  
        }

        label > span { 
            display:block;    
            position:absolute; 
            left:0; 
            -webkit-transition-duration:0.3s; 
            -moz-transition-duration:0.3s; 
            transition-duration:0.3s;
        }

        label > span.inc   { 
          background: #fff;
          left:-20px;  
          top:-20px;
          height:60px;
          width:60px;
          opacity:0; 
          border-radius:50% !important; 
          -moz-border-radius:50% !important; 
          -webkit-border-radius:50% !important;
        }

        label > .box {
          top:4px;
          height:20px;   
          width:20px;
          z-index:5;
          -webkit-transition-delay:0.2s; -moz-transition-delay:0.2s; transition-delay:0.2s;
          border: solid 1px $input-font-color;
        }

        label > .check {
          top: 0px;
          left: 6px;
          width: 10px;
          height: 20px;
          border:2px solid $input-font-color;  
          border-top: none;
          border-left: none;
          opacity:0; 
          z-index:5;
          -webkit-transform:rotate(180deg); 
          -moz-transform:rotate(180deg); 
          transform:rotate(180deg);
          -webkit-transition-delay:0.3s; 
          -moz-transition-delay:0.3s; transition-delay:0.3s;
        }

        /* handling click events */

        /* when checkbox is checked */
        label > span.inc {
          -webkit-animation   :growCircle 0.3s ease;
          -moz-animation      :growCircle 0.3s ease;
          animation           :growCircle 0.3s ease;   
        }

        input[type=checkbox]:checked ~ label > .box { 
          opacity:0;
          -webkit-transform   :scale(0) rotate(-180deg);
          -moz-transform        :scale(0) rotate(-180deg);
          transform                 :scale(0) rotate(-180deg);
        }

        input[type=checkbox]:checked ~ label > .check {
          opacity:1; 
          -webkit-transform   :scale(1) rotate(45deg);
          -moz-transform      :scale(1) rotate(45deg);
          transform           :scale(1) rotate(45deg);
        }

        // Disabled state
        input[type=checkbox]:disabled ~ label,
        input[type=checkbox][disabled] ~ label {
            cursor: not-allowed;   
            @include opacity(0.5); 
        }

        input[type=checkbox]:disabled ~ label > .box,
        input[type=checkbox][disabled] ~ label > .box { 
            cursor: not-allowed;
            @include opacity(0.5);
        }

        input[type=checkbox]:disabled:checked ~ label > .check, 
        input[type=checkbox][disabled]:checked ~ label > .check {           
            cursor: not-allowed;
            @include opacity(0.5);
        } 
    }
    
	&.has-error {
		
	}   
    .dvForm_missed{
        background: #fde3e3;
    }
} 


@-webkit-keyframes growCircle {
  0%, 100%   { -webkit-transform:scale(0); opacity: 0.5}
  70%        { background: $input-radio-color; -webkit-transform:scale(1.25); }
}
@-moz-keyframes growCircle {
  0%, 100%   { -moz-transform:scale(0); opacity: 0.5}
  70%        { background: $input-radio-color; -moz-transform:scale(1.25); }
}
@keyframes growCircle {
  0%, 100%   { transform:scale(0); opacity: 0.5}
  70%        { background: $input-radio-color; transform:scale(1.25); }
}

.label-group{
    label{
        width:140px;
        flex:0 0 auto
    }
        
    span {
        font-weight:400;
        
        small{
            font-size:70%;
            font-weight:400
        }
    }
}
.lang-switch{
    display: none;
    
    &.lang-active{
        display: block;
    }
}
.lang-select{
    border-bottom: solid 2px $color-green;
    li{
        display: inline-block;
        margin-right: 10px;
        margin-left: 10px;
        padding: 4px 8px;
        background: $color-grey;
        width: 50px;
        text-align: center;
        cursor: pointer;
        
        &.selected{
            background: $color-green;
            color: $color-white;
        }
    }
}
.marker-radio{
    vertical-align: middle;
    margin-top: -7px;
}
.form-inline{
    display: inline-block;
    
    &.w-25{
        width: 25%;
    }
}
.dvForm_missed{
    background: #fde3e3;
}