@mixin reset_spaces{
    padding: 0;
    margin: 0;
}
@mixin text {
  font-family: $font-color;
  font-weight: normal;
  font-size: 14px;
}
@mixin placeholder($color: $input-color-placeholder) {
  &::-moz-placeholder           { color: $color; opacity: 1; } // See https://github.com/twbs/bootstrap/pull/11526
  &:-ms-input-placeholder       { color: $color; } // Internet Explorer 10+
  &::-webkit-input-placeholder  { color: $color; } // Safari and Chrome
}
@mixin border-radius($radius, $important: '') {
  -webkit-border-radius: $radius#{$important};
  -moz-border-radius: $radius#{$important};
  -ms-border-radius: $radius#{$important};  
  -o-border-radius: $radius#{$important};      
  border-radius: $radius#{$important}; 
}
@mixin opacity($value, $important: '') {
  opacity: $value #{$important};
  filter: alpha(opacity=#{$value * 100}) #{$important};
}
