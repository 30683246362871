main{
    margin: 98px 0 0 0;
    margin-left: 13%;

    min-height: calc(100% - 200px);

    .inner{
        overflow: hidden;
    }
    
    section{
        margin: 0 80px;
        margin-bottom: $space;
        
        &.full{
            margin: 0;
            
            .title{
                margin: 0 80px;
                margin-bottom: $space;
            }
        }
    }
    nav{
        &.breadcrumb-nav{
            position: absolute;
            top: 20px;
            margin: 0 0 0 80px;
            
            svg{
                height: 10px;
                width: 10px;
                fill: $color-grey;
                margin: 0 10px;
            }
        }
    }
    ul{
        margin: 0;
        padding: $space;
    }
}

.title{
    margin-bottom: 50px;

    h1{
        font-family: $font-standard;
        font-weight: normal;
        margin-bottom: $space;
        color: $color-green;
        font-size: 44px;
        max-width: 70%;
        min-width: 730px;
    }
    p{
        max-width: 70%;
        min-width: 800px;
        font-size: 20px;
        color: $color-darkgrey;
    }
}
.col-row{
    display: flex;
    .col-100 {
        margin-right: 0;
        width: 100%;
        position: relative;

    }
    
    .col-50 {
        margin-right: $space*2;
        width: 50%;
        width: calc(50% - #{$space});
        position: relative;

    }
    .col-70{
        margin-right: $space*2;
        width: 70%;
        width: calc(70% - #{$space});
        position: relative;
    }
    .col-30{
        /*margin-right: $space*2;*/
        width: 30%;
        width: calc(30% - #{$space});
        position: relative;
    }
    .col-27{
        margin-right: $space*2;
        width: 27%;
        width: calc(27% - #{$space});
        position: relative;
    }
    .col-60{
        margin-right: $space*2;
        width: 60%;
        width: calc(60% - #{$space});
        position: relative;
    }
    .col-07{
        margin-right: $space*1;
        width: 7%;
        width: calc(7% - #{$space});
        position: relative;
    }
    .col-50, .col-70, .col-30, .col-60, .col-27, .col-07{
        /*
        &:nth-child(2){
            margin-right: 0;
        }*/
        img{
            max-width: 100%;
            
            &.full{
                width: 100%;
                display: block;
            }
        }
        p.spacer{
            display: block;
            height: 50px;
        }
        .bottom{
            position: absolute;
            bottom: 0;
            width: 100%;
        }
        &.nmr{
            margin-right: 0;
        }
    }
}
.steps-image{
    max-width: 100%;
    max-height: 600px;
    border: solid 1px $color-midgrey;
}
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin-bottom: 20px;
    
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
.learnCardsContainer{
    overflow: scroll;
    transition: 1s;
    padding-top: 10px;
    border-top: solid 1px #000000;
    margin-bottom: 0px;
    &:last-of-type{
        border-bottom: solid 1px #000000;
        margin-bottom: 20px;
    }
    .learnCardsTitle{
        cursor: pointer;
        section:hover{
           background-color: #e9e9e9; 
        }
        span {
            font-size: 25px;
        }
        svg {
            transition: 1s;
            height: 15px;
            width: 15px;
            margin-right: 10px;
        }
    }
}
.learnCardsContent{
    display:none;
}
@media(max-width:1679px){
    main{
        margin-left: 17%;
    }
}

@media(max-width:1449px){
    main{
        margin-left: 20%;
    }
}

@media(max-width:1179px){
    main{
        margin-left: 22%;
    }
}

@media(max-width:1023px){
    main{
        margin-left: 0;
        margin-top: 40px;
    }
}

@media(max-width:1024px){
    main{
        margin-top: 140px;
        
        section{
            margin: 0 40px;
            &.full{
                .title{
                    margin: 0 30px;
                }
            }
        }
    }
}
@media(max-width:530px){
    main{
        section{
            margin: 0 20px;
        }
    }
}
