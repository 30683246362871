#maps-container {
    height: 100%;
    width: 100%;
    background: #fff;
}
#maps-overlay-bg{
    background: #222222;
    width:100%;
    height: 100%;
    position: absolute;
    z-index: 1008;
    display: none;
}
#maps-overlay{
    background: #fff;
    width:620px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);    position: absolute;
    z-index: 1009;
    display: none;
    overflow: auto;
    max-height: 90%;
    
    h2{
        font-size: 25px;
    }
}
#maps-overlay-inner{
    margin: 20px;
    overflow: hidden;
    
    a, a:link, a:active, a:visited{
        color: $color-green;
    }
    a:hover{
        text-decoration: underline;
    }
    a.btn:hover{
        text-decoration: none;
    }
    p {
        margin-bottom: 20px;
    }
    
    h1{
        font-size: 24px;
        color: $color-darkgrey;
        margin: 0 0 $space 0;
    }
    h2{
        font-size: 20px;
        color: $color-darkgrey;
        margin: 0 0 $space 0;
    }
    h3{
        font-size: 18px;
        color: $color-darkgrey;
        margin: 0 0 $space 0;
    }
}
.maps-info{
    position: absolute;
    bottom: 20px;
    left: 20px;
    padding: 10px 15px 10px 10px;
    border: 2px solid rgba(0,0,0,0.2);
    border-top-left-radius: 2px;
    z-index: 980;
    background: #fff;
    font-size: 14px;
}
.maps-marker-info{
    vertical-align: middle;
    margin-right: 10px;
    height: 20px;
    width: 20px;
}
.maps-switch {
    position: absolute;
    top: 80px;
    left: 10px;
    z-index: 999;
}
.maps-switch li {
    list-style: none;
}
.maps-switch li a {
    background: $color-green;
    color: #000;
    font-size: 16px;
    font-weight: 700;
    padding: 8px 15px;
    display: block;
    margin-top: 10px;
}
.maps-new-poi, .maps-admin{
    position: absolute;
    top: 10px;
    left: 70px;
    background: #000;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding: 8px 15px;
    z-index: 999;
    font-size: 16px;
    cursor: pointer;
}
.maps-new-poi{
    left: 210px;
}
.maps-tooltip-icon{
    height: 16px;
    width: 16px;
    fill: $color-darkgrey;
    vertical-align:middle;
    margin-left: 5px;
}