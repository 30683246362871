.tiles{
    overflow: hidden;
    width: 100%;
    
    .tile{
        display: block;
        width: 24.4%;
        margin: 0.3%;
        height: 200px;
        float: left;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        background-color: lighten($color-green,30%);
        
        
        h2{
            max-width: calc(100% - #{4*$space});
            min-height: 30px;
            bottom: $space*2;
            left: $space*2;
            background: #fff;
            position: absolute;
            color: #666;
            padding: $space;
            font-size: 20px;
            font-weight: 400;
            z-index: 10;
            }
        p{
            display: none;
            position: absolute;
            width: 100%;
            height: 100%;
            background: $color-green;
            top: 0;
            left: 0;
            padding: $space;
            opacity: 0.5;
            z-index: 5;
        }
        &:hover{
            p{
                display: block;
            }
        }
        
    }       
    &.large{
        .tile{
            display: block;
            width: 32.7%;
            margin: 0.3%;
            height: 300px;
        }
    }
}
@media(max-width:1600px){
    .tiles{
        .tile{
            h2{
                font-size: 18px;
            }
        }
    }
}
@media(max-width:1350px){
    .tiles{
        .tile{
            width: 32.7%;
        }
    }
} 
@media(max-width:1350px){
    .tiles{
        &.large{
            .tile{
                width: 49.3%;
            }
        }
    }
}
@media(max-width:800px){
    .tiles{
        .tile{
            width: 49.3%;
        }
    }
}
@media(max-width:680px){
    .tiles{
        &.large{
            .tile{
                width: 99.3%;
            }
        }
    }
}
@media(max-width:530px){
    .tiles{
        .tile{
            width: 99.3%;
        }
    }
}
