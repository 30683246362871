.stepnav{
    width: 100%;
    display: table;
    margin-bottom: 20px;
    margin-left: 13px;
    
    ul {      
        display: table-row;
        margin-left: -3px;
        
        li{
            
            div{
                width: 100%;
                display: table;
                margin: 20px 0 0 12.5px;
            }
        }
    }
    
    li{
        display: table-cell;
        position: relative;
        
        a{
            &.level2{
                display: block;
                text-align: center;
                vertical-align: middle;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                background: $color-darkgrey;
                color: #fff;
                line-height: 32px;
                font-size: 16px;
                margin-top: 60px;
                
                &:after{
                    content: '';
                    height: 4px;
                    width: 100%;
                    position: absolute;
                    transform: translateY(-100%);

                    background: $color-darkgrey;
                    width: calc(100% - 25px);
                    top: 77px;
                    left: 25px;
                    z-index: 11;
                }
            }
            b{
                position: absolute;
                background: $color-green;
                width: 20px;
                height: 20px;
                display: block;
                top: 66px;
                left: 50%;
                z-index: 80;
                color: #fff;
                line-height: 22px;
            }
        }
        
        span{
            display: block;
            text-align: center;
            vertical-align: middle;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: $color-grey;
            line-height: 50px;
            font-size: 24px;
            position: absolute;
            margin-left: -10px;
            top: 0;
            
            &:after{
                content: '';
                height: 10px;
                width: 4px;
                position: absolute;
                background: $color-grey;
                top: 60px;
                left: 23px;
                z-index: 10;
                transform: translateY(-100%);
            }
        }
        &.done{
            a{
                background: $color-green;
                &:after{
                    background: $color-green;
                }
            }
            span{
                background: lighten($color-green,40%);
                &:after{
                    background: lighten($color-green,40%);
                }
            }
        }
        &.last{
            width: 55px;
            a{
                border-radius: 20%;
                svg{
                    width: 16px;
                    height: 16px;
                    fill: #fff;
                }
                &:after{
                    display: none;
                }
            }
            span{
                border-radius: 20%;
                font-size: 12px;
            }
        }
    }
}
.progressbar{
    width: 100%;
    display: table;
    margin-bottom: 20px;
    
    ul {      
        display: table-row;
        margin-left: -3px;
        
        li{
            
            div{
                width: 100%;
                display: table;
                margin: 20px 0 0 12.5px;
            }
        }
    }
    
    li{
        display: table-cell;
        position: relative;
        
        a{
            display: block;
            text-align: center;
            vertical-align: middle;
            border-radius: 20%;
            width: 30px;
            height: 30px;
            background: $color-darkgrey;
            color: #fff;
            line-height: 32px;
            font-size: 16px;
            margin-top: 60px;

            &:after{
                content: '';
                height: 4px;
                width: 100%;
                position: absolute;
                transform: translateY(-100%);

                background: $color-darkgrey;
                width: calc(100% - 25px);
                top: 77px;
                left: 25px;
                z-index: 11;
            }
        }
        
        &.done{
            a{
                background: $color-green;
                &:after{
                    background: $color-green;
                }
            }
        }
        &.last{
            width: 55px;
            a{
                border-radius: 20%;
                svg{
                    width: 16px;
                    height: 16px;
                    fill: #fff;
                }
                &:after{
                    display: none;
                }
            }
        }
    }
}
h3{
    &.lessons{
        font-size: 20px;
        a{
            display: inline-block;
            padding: 5px 15px;
            background: $color-green;
            border: 0;
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            margin-right: 5px;
            &:hover,{
                background: lighten($color-green, 15%);
                text-decoration: none;
            }
            &.selected{
                background: lighten($color-green, 25%);
                color: $color-darkgrey;
                text-decoration: none;                
            }
        }
    }
}
#validation-relevance{
    width: 100%;
    max-width: 800px;
    height: 325px;
    margin-bottom: $space;
    margin-top: $space;
    
    .validation-relevance-step{
        height: 100%;
        background: $color-grey;
        float: left;
        margin-right: 1px;
        position: relative;
        
        .validation-relevance-label-vertical{
            writing-mode: vertical-rl;
            color: $color-white;
            padding: 10px calc(50% - 8px);
            overflow: hidden;
            white-space: nowrap;
        }
        
        .validation-relevance-label{
            background: $color-white;
            position: absolute;
            top: -25px;
            width: 100%;
            height: 25px;
            text-align: center;
        }
        .validation-relevance-bar{
            background: $color-green;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            
            .ui-resizable-handle{
                width: 100%;
                height: 25px;
                z-index: 20;
                position: absolute;
            }
            .handle{
                background: $color-darkgrey;
                width: 100%;
                height: 25px;
                top: 0;
                z-index: 10;
                text-align: center;
                position: absolute;
                cursor: ns-resize;
                
                svg{
                    height: 15px;
                    width: 15px;
                    fill: $color-white;
                    margin: 5px auto;
                    display: block;
                    vertical-align: middle;
                }
                
                .percent{
                    color: $color-darkgrey;
                    font-size: 14px;
                    top: -20px;
                    position: absolute;
                    transform: translateX(-50%);
                }
            }
            .percent-calc{
                display: none;
            }
        }

    }
}
#validation-profile{
    width: 100%;

    td{
        &.label{
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 0;
            &.tr{
                text-align: right;
            }
        }
   
        &.em{
            text-align: center;
            
            width: 8%;
            background: $color-darkgrey;
            &.bg{
                background: $color-green;
            }
            &.nobg{
                background: none;
            }
        }
        &.sp{
            width: 5%;
            text-align: right;
            padding-right: 10px;
        }
        &.sp2{
            width: 10%;
            text-align: right;
        }
    }
}
.validation-final{
    text-align: center;

    h1{
        font-size: 24px;
        background: $color-grey;
        padding: 10px;
        margin: 30px auto 30px auto;
        max-width: 450px;
        color: $color-darkgrey;
        
        .hl{
            font-size: 40px;
            color: $color-green;
            vertical-align: middle;
        }
    }
    .cert{
        margin: 0 20% 30px 20%;
        font-size: 20px;
    }
    p{
        margin: 20px 0;
    }
    table{
        margin: auto;
        td{
            text-align: left;
        }
    }
}
.certificates{
    margin: $space 0;
    
    a {
        line-height: $space;
        
        svg{
            height: $space;
            width: $space;
            fill: $color-green;
            vertical-align: middle;
        }
    }
}
.step-explanation-btn{
    margin-top: $space;
}
.step-explanation{
    margin-top: $space/2;
    margin-bottom: $space;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    padding:0 $space;
    
    &.open{
        padding: $space;
        max-height: 800px;
        border: solid 1px $color-green;
        background: lighten($color-grey, 15%);
    }
}
.lesson-lead{
    border: solid 1px $color-darkgrey;
    padding: 10px;
    margin: 10px 0 20px 0;
}
h4.lesson-title{
    color: $color-green;
    font-size: 20px;
    margin: 10px 0 20px 0;
}
.lessonstep{
    font-size: 16px;
    padding: 10px 0;
    margin: 0;
    display: none;
    &.show{
        display: block;
    }
    ul{
        margin-left: 0;
        padding-left: 20px;
    }
}
.prevnext-lesson{
    &:hover{
        color: $color-green;
    }
    i{
        color: $color-green;
        font-size: 20px;
        vertical-align: middle;
    }
    &.fl{
        i{
            margin-right: 15px;
        }
    }
    &.fr{
        i{
            margin-left: 15px;
        }
    }
}
.lessonstep-image{
    display: none;
    &.show{
        display: block;
    }
}
.readnext{
    margin: 20px auto 0 auto;
}
