@import url("//hello.myfonts.net/count/394ac3");
@font-face {
  font-family: "UniversLTPro-45Light";
  src: url("/dist/fonts/394AC3_0_0.eot");
  src: url("/dist/fonts/394AC3_0_0.eot?#iefix") format("embedded-opentype"), url("/dist/fonts/394AC3_0_0.woff2") format("woff2"), url("/dist/fonts/394AC3_0_0.woff") format("woff"), url("/dist/fonts/394AC3_0_0.ttf") format("truetype");
}
@font-face {
  font-family: "UniversLTPro-55Roman";
  src: url("/dist/fonts/394AC3_1_0.eot");
  src: url("/dist/fonts/394AC3_1_0.eot?#iefix") format("embedded-opentype"), url("/dist/fonts/394AC3_1_0.woff2") format("woff2"), url("/dist/fonts/394AC3_1_0.woff") format("woff"), url("/dist/fonts/394AC3_1_0.ttf") format("truetype");
}
@font-face {
  font-family: "UniversLTPro-65Bold";
  src: url("/dist/fonts/394AC3_2_0.eot");
  src: url("/dist/fonts/394AC3_2_0.eot?#iefix") format("embedded-opentype"), url("/dist/fonts/394AC3_2_0.woff2") format("woff2"), url("/dist/fonts/394AC3_2_0.woff") format("woff"), url("/dist/fonts/394AC3_2_0.ttf") format("truetype");
}
@font-face {
  font-family: "fontawesome";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/dist/fonts/fa-regular-400.eot");
  src: url("/dist/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("/dist/fonts/fa-regular-400.woff2") format("woff2"), url("/dist/fonts/fa-regular-400.woff") format("woff"), url("/dist/fonts/fa-regular-400.ttf") format("truetype"), url("/dist/fonts/fa-regular-400.svg#fontawesome") format("svg");
}
.far {
  font-family: "fontawesome";
  font-weight: 400;
}

@font-face {
  font-family: "fontawesome";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("/dist/fonts/fa-solid-900.eot");
  src: url("/dist/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("/dist/fonts/fa-solid-900.woff2") format("woff2"), url("/dist/fonts/fa-solid-900.woff") format("woff"), url("/dist/fonts/fa-solid-900.ttf") format("truetype"), url("/dist/fonts/fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas {
  font-family: "fontawesome";
  font-weight: 900;
}

/*** main *********************************************************************/
/*** colors *******************************************************************/
* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333333;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: #333333;
  font-weight: normal;
  font-size: 14px;
  background: #fff;
  font-family: "UniversLTPro-45Light", sans-serif;
  font-size: 18px;
  color: #666;
  width: 100%;
  height: 100%;
}

h1 {
  margin-bottom: 20px;
  color: #88bd24;
  font-size: 35px;
}

h2 {
  margin-bottom: 20px;
  color: #666;
  font-size: 30px;
}

h3 {
  margin-bottom: 20px;
  color: #666;
  font-size: 25px;
}

.fr {
  float: right;
}

.fl {
  float: left;
}

.ul {
  text-decoration: underline;
}

.sb {
  padding-bottom: 50px;
}

.bt {
  border-top: solid 1px #999;
  margin-top: 30px;
  padding-top: 30px;
}

.bb {
  border-bottom: solid 1px #999;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

p {
  margin-bottom: 20px;
}

.mb {
  margin-bottom: 20px;
}

.ml {
  margin-left: 20px;
}

.mr {
  margin-right: 20px;
}

.mt {
  margin-top: 20px;
}

.mt2 {
  margin-top: 40px;
}

.mt3 {
  margin-top: 60px;
}

.oh {
  overflow: hidden;
}

.tog {
  cursor: pointer;
}

.emojione {
  display: inline-block;
  background-repeat: no-repeat;
  font-size: inherit;
  line-height: normal;
  text-indent: -9999em;
  vertical-align: middle;
}

.emojione {
  width: 25px;
  height: 25px;
  background-image: url("/dist/emojione//sprite-25.png");
  background-size: 1075px;
}

.emojione-0023-20e3 {
  background-position-x: -25px;
  background-position-y: 0px;
}

.emojione-0023 {
  background-position-x: -525px;
  background-position-y: -700px;
}

.emojione-002a-20e3 {
  background-position-x: 0px;
  background-position-y: -25px;
}

.emojione-002a {
  background-position-x: -25px;
  background-position-y: -25px;
}

.emojione-0030-20e3 {
  background-position-x: -50px;
  background-position-y: 0px;
}

.emojione-0030 {
  background-position-x: -50px;
  background-position-y: -25px;
}

.emojione-0031-20e3 {
  background-position-x: 0px;
  background-position-y: -50px;
}

.emojione-0031 {
  background-position-x: -25px;
  background-position-y: -50px;
}

.emojione-0032-20e3 {
  background-position-x: -50px;
  background-position-y: -50px;
}

.emojione-0032 {
  background-position-x: -75px;
  background-position-y: 0px;
}

.emojione-0033-20e3 {
  background-position-x: -75px;
  background-position-y: -25px;
}

.emojione-0033 {
  background-position-x: -75px;
  background-position-y: -50px;
}

.emojione-0034-20e3 {
  background-position-x: 0px;
  background-position-y: -75px;
}

.emojione-0034 {
  background-position-x: -25px;
  background-position-y: -75px;
}

.emojione-0035-20e3 {
  background-position-x: -50px;
  background-position-y: -75px;
}

.emojione-0035 {
  background-position-x: -75px;
  background-position-y: -75px;
}

.emojione-0036-20e3 {
  background-position-x: -100px;
  background-position-y: 0px;
}

.emojione-0036 {
  background-position-x: -100px;
  background-position-y: -25px;
}

.emojione-0037-20e3 {
  background-position-x: -100px;
  background-position-y: -50px;
}

.emojione-0037 {
  background-position-x: -100px;
  background-position-y: -75px;
}

.emojione-0038-20e3 {
  background-position-x: 0px;
  background-position-y: -100px;
}

.emojione-0038 {
  background-position-x: -25px;
  background-position-y: -100px;
}

.emojione-0039-20e3 {
  background-position-x: -50px;
  background-position-y: -100px;
}

.emojione-0039 {
  background-position-x: -75px;
  background-position-y: -100px;
}

.emojione-00a9 {
  background-position-x: -100px;
  background-position-y: -100px;
}

.emojione-00ae {
  background-position-x: -125px;
  background-position-y: 0px;
}

.emojione-1f004 {
  background-position-x: -125px;
  background-position-y: -25px;
}

.emojione-1f0cf {
  background-position-x: -125px;
  background-position-y: -50px;
}

.emojione-1f170 {
  background-position-x: -125px;
  background-position-y: -75px;
}

.emojione-1f171 {
  background-position-x: -125px;
  background-position-y: -100px;
}

.emojione-1f17e {
  background-position-x: 0px;
  background-position-y: -125px;
}

.emojione-1f17f {
  background-position-x: -25px;
  background-position-y: -125px;
}

.emojione-1f18e {
  background-position-x: -50px;
  background-position-y: -125px;
}

.emojione-1f191 {
  background-position-x: -75px;
  background-position-y: -125px;
}

.emojione-1f192 {
  background-position-x: -100px;
  background-position-y: -125px;
}

.emojione-1f193 {
  background-position-x: -125px;
  background-position-y: -125px;
}

.emojione-1f194 {
  background-position-x: -150px;
  background-position-y: 0px;
}

.emojione-1f195 {
  background-position-x: -150px;
  background-position-y: -25px;
}

.emojione-1f196 {
  background-position-x: -150px;
  background-position-y: -50px;
}

.emojione-1f197 {
  background-position-x: -150px;
  background-position-y: -75px;
}

.emojione-1f198 {
  background-position-x: -150px;
  background-position-y: -100px;
}

.emojione-1f199 {
  background-position-x: -150px;
  background-position-y: -125px;
}

.emojione-1f19a {
  background-position-x: 0px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1e8 {
  background-position-x: -25px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1e9 {
  background-position-x: -50px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1ea {
  background-position-x: -75px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1eb {
  background-position-x: -100px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1ec {
  background-position-x: -125px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1ee {
  background-position-x: -150px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1f1 {
  background-position-x: -175px;
  background-position-y: 0px;
}

.emojione-1f1e6-1f1f2 {
  background-position-x: -175px;
  background-position-y: -25px;
}

.emojione-1f1e6-1f1f4 {
  background-position-x: -175px;
  background-position-y: -50px;
}

.emojione-1f1e6-1f1f6 {
  background-position-x: -175px;
  background-position-y: -75px;
}

.emojione-1f1e6-1f1f7 {
  background-position-x: -175px;
  background-position-y: -100px;
}

.emojione-1f1e6-1f1f8 {
  background-position-x: -175px;
  background-position-y: -125px;
}

.emojione-1f1e6-1f1f9 {
  background-position-x: -175px;
  background-position-y: -150px;
}

.emojione-1f1e6-1f1fa {
  background-position-x: 0px;
  background-position-y: -175px;
}

.emojione-1f1e6-1f1fc {
  background-position-x: -25px;
  background-position-y: -175px;
}

.emojione-1f1e6-1f1fd {
  background-position-x: -50px;
  background-position-y: -175px;
}

.emojione-1f1e6-1f1ff {
  background-position-x: -75px;
  background-position-y: -175px;
}

.emojione-1f1e6 {
  background-position-x: -100px;
  background-position-y: -175px;
}

.emojione-1f1e7-1f1e6 {
  background-position-x: -125px;
  background-position-y: -175px;
}

.emojione-1f1e7-1f1e7 {
  background-position-x: -150px;
  background-position-y: -175px;
}

.emojione-1f1e7-1f1e9 {
  background-position-x: -175px;
  background-position-y: -175px;
}

.emojione-1f1e7-1f1ea {
  background-position-x: -200px;
  background-position-y: 0px;
}

.emojione-1f1e7-1f1eb {
  background-position-x: -200px;
  background-position-y: -25px;
}

.emojione-1f1e7-1f1ec {
  background-position-x: -200px;
  background-position-y: -50px;
}

.emojione-1f1e7-1f1ed {
  background-position-x: -200px;
  background-position-y: -75px;
}

.emojione-1f1e7-1f1ee {
  background-position-x: -200px;
  background-position-y: -100px;
}

.emojione-1f1e7-1f1ef {
  background-position-x: -200px;
  background-position-y: -125px;
}

.emojione-1f1e7-1f1f1 {
  background-position-x: -200px;
  background-position-y: -150px;
}

.emojione-1f1e7-1f1f2 {
  background-position-x: -200px;
  background-position-y: -175px;
}

.emojione-1f1e7-1f1f3 {
  background-position-x: 0px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1f4 {
  background-position-x: -25px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1f6 {
  background-position-x: -50px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1f7 {
  background-position-x: -75px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1f8 {
  background-position-x: -100px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1f9 {
  background-position-x: -125px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1fb {
  background-position-x: -150px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1fc {
  background-position-x: -175px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1fe {
  background-position-x: -200px;
  background-position-y: -200px;
}

.emojione-1f1e7-1f1ff {
  background-position-x: -225px;
  background-position-y: 0px;
}

.emojione-1f1e7 {
  background-position-x: -225px;
  background-position-y: -25px;
}

.emojione-1f1e8-1f1e6 {
  background-position-x: -225px;
  background-position-y: -50px;
}

.emojione-1f1e8-1f1e8 {
  background-position-x: -225px;
  background-position-y: -75px;
}

.emojione-1f1e8-1f1e9 {
  background-position-x: -225px;
  background-position-y: -100px;
}

.emojione-1f1e8-1f1eb {
  background-position-x: -225px;
  background-position-y: -125px;
}

.emojione-1f1e8-1f1ec {
  background-position-x: -225px;
  background-position-y: -150px;
}

.emojione-1f1e8-1f1ed {
  background-position-x: -225px;
  background-position-y: -175px;
}

.emojione-1f1e8-1f1ee {
  background-position-x: -225px;
  background-position-y: -200px;
}

.emojione-1f1e8-1f1f0 {
  background-position-x: 0px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f1 {
  background-position-x: -25px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f2 {
  background-position-x: -50px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f3 {
  background-position-x: -75px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f4 {
  background-position-x: -100px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f5 {
  background-position-x: -125px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1f7 {
  background-position-x: -150px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1fa {
  background-position-x: -175px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1fb {
  background-position-x: -200px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1fc {
  background-position-x: -225px;
  background-position-y: -225px;
}

.emojione-1f1e8-1f1fd {
  background-position-x: -250px;
  background-position-y: 0px;
}

.emojione-1f1e8-1f1fe {
  background-position-x: -250px;
  background-position-y: -25px;
}

.emojione-1f1e8-1f1ff {
  background-position-x: -250px;
  background-position-y: -50px;
}

.emojione-1f1e8 {
  background-position-x: -250px;
  background-position-y: -75px;
}

.emojione-1f1e9-1f1ea {
  background-position-x: -250px;
  background-position-y: -100px;
}

.emojione-1f1e9-1f1ec {
  background-position-x: -250px;
  background-position-y: -125px;
}

.emojione-1f1e9-1f1ef {
  background-position-x: -250px;
  background-position-y: -150px;
}

.emojione-1f1e9-1f1f0 {
  background-position-x: -250px;
  background-position-y: -175px;
}

.emojione-1f1e9-1f1f2 {
  background-position-x: -250px;
  background-position-y: -200px;
}

.emojione-1f1e9-1f1f4 {
  background-position-x: -250px;
  background-position-y: -225px;
}

.emojione-1f1e9-1f1ff {
  background-position-x: 0px;
  background-position-y: -250px;
}

.emojione-1f1e9 {
  background-position-x: -25px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1e6 {
  background-position-x: -50px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1e8 {
  background-position-x: -75px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1ea {
  background-position-x: -100px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1ec {
  background-position-x: -125px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1ed {
  background-position-x: -150px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1f7 {
  background-position-x: -175px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1f8 {
  background-position-x: -200px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1f9 {
  background-position-x: -225px;
  background-position-y: -250px;
}

.emojione-1f1ea-1f1fa {
  background-position-x: -250px;
  background-position-y: -250px;
}

.emojione-1f1ea {
  background-position-x: -275px;
  background-position-y: 0px;
}

.emojione-1f1eb-1f1ee {
  background-position-x: -275px;
  background-position-y: -25px;
}

.emojione-1f1eb-1f1ef {
  background-position-x: -275px;
  background-position-y: -50px;
}

.emojione-1f1eb-1f1f0 {
  background-position-x: -275px;
  background-position-y: -75px;
}

.emojione-1f1eb-1f1f2 {
  background-position-x: -275px;
  background-position-y: -100px;
}

.emojione-1f1eb-1f1f4 {
  background-position-x: -275px;
  background-position-y: -125px;
}

.emojione-1f1eb-1f1f7 {
  background-position-x: -275px;
  background-position-y: -150px;
}

.emojione-1f1eb {
  background-position-x: -275px;
  background-position-y: -175px;
}

.emojione-1f1ec-1f1e6 {
  background-position-x: -275px;
  background-position-y: -200px;
}

.emojione-1f1ec-1f1e7 {
  background-position-x: -275px;
  background-position-y: -225px;
}

.emojione-1f1ec-1f1e9 {
  background-position-x: -275px;
  background-position-y: -250px;
}

.emojione-1f1ec-1f1ea {
  background-position-x: 0px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1eb {
  background-position-x: -25px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1ec {
  background-position-x: -50px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1ed {
  background-position-x: -75px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1ee {
  background-position-x: -100px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f1 {
  background-position-x: -125px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f2 {
  background-position-x: -150px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f3 {
  background-position-x: -175px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f5 {
  background-position-x: -200px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f6 {
  background-position-x: -225px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f7 {
  background-position-x: -250px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f8 {
  background-position-x: -275px;
  background-position-y: -275px;
}

.emojione-1f1ec-1f1f9 {
  background-position-x: -300px;
  background-position-y: 0px;
}

.emojione-1f1ec-1f1fa {
  background-position-x: -300px;
  background-position-y: -25px;
}

.emojione-1f1ec-1f1fc {
  background-position-x: -300px;
  background-position-y: -50px;
}

.emojione-1f1ec-1f1fe {
  background-position-x: -300px;
  background-position-y: -75px;
}

.emojione-1f1ec {
  background-position-x: -300px;
  background-position-y: -100px;
}

.emojione-1f1ed-1f1f0 {
  background-position-x: -300px;
  background-position-y: -125px;
}

.emojione-1f1ed-1f1f2 {
  background-position-x: -300px;
  background-position-y: -150px;
}

.emojione-1f1ed-1f1f3 {
  background-position-x: -300px;
  background-position-y: -175px;
}

.emojione-1f1ed-1f1f7 {
  background-position-x: -300px;
  background-position-y: -200px;
}

.emojione-1f1ed-1f1f9 {
  background-position-x: -300px;
  background-position-y: -225px;
}

.emojione-1f1ed-1f1fa {
  background-position-x: -300px;
  background-position-y: -250px;
}

.emojione-1f1ed {
  background-position-x: -300px;
  background-position-y: -275px;
}

.emojione-1f1ee-1f1e8 {
  background-position-x: 0px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1e9 {
  background-position-x: -25px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1ea {
  background-position-x: -50px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f1 {
  background-position-x: -75px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f2 {
  background-position-x: -100px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f3 {
  background-position-x: -125px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f4 {
  background-position-x: -150px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f6 {
  background-position-x: -175px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f7 {
  background-position-x: -200px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f8 {
  background-position-x: -225px;
  background-position-y: -300px;
}

.emojione-1f1ee-1f1f9 {
  background-position-x: -250px;
  background-position-y: -300px;
}

.emojione-1f1ee {
  background-position-x: -275px;
  background-position-y: -300px;
}

.emojione-1f1ef-1f1ea {
  background-position-x: -300px;
  background-position-y: -300px;
}

.emojione-1f1ef-1f1f2 {
  background-position-x: -325px;
  background-position-y: 0px;
}

.emojione-1f1ef-1f1f4 {
  background-position-x: -325px;
  background-position-y: -25px;
}

.emojione-1f1ef-1f1f5 {
  background-position-x: -325px;
  background-position-y: -50px;
}

.emojione-1f1ef {
  background-position-x: -325px;
  background-position-y: -75px;
}

.emojione-1f1f0-1f1ea {
  background-position-x: -325px;
  background-position-y: -100px;
}

.emojione-1f1f0-1f1ec {
  background-position-x: -325px;
  background-position-y: -125px;
}

.emojione-1f1f0-1f1ed {
  background-position-x: -325px;
  background-position-y: -150px;
}

.emojione-1f1f0-1f1ee {
  background-position-x: -325px;
  background-position-y: -175px;
}

.emojione-1f1f0-1f1f2 {
  background-position-x: -325px;
  background-position-y: -200px;
}

.emojione-1f1f0-1f1f3 {
  background-position-x: -325px;
  background-position-y: -225px;
}

.emojione-1f1f0-1f1f5 {
  background-position-x: -325px;
  background-position-y: -250px;
}

.emojione-1f1f0-1f1f7 {
  background-position-x: -325px;
  background-position-y: -275px;
}

.emojione-1f1f0-1f1fc {
  background-position-x: -325px;
  background-position-y: -300px;
}

.emojione-1f1f0-1f1fe {
  background-position-x: 0px;
  background-position-y: -325px;
}

.emojione-1f1f0-1f1ff {
  background-position-x: -25px;
  background-position-y: -325px;
}

.emojione-1f1f0 {
  background-position-x: -50px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1e6 {
  background-position-x: -75px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1e7 {
  background-position-x: -100px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1e8 {
  background-position-x: -125px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1ee {
  background-position-x: -150px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1f0 {
  background-position-x: -175px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1f7 {
  background-position-x: -200px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1f8 {
  background-position-x: -225px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1f9 {
  background-position-x: -250px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1fa {
  background-position-x: -275px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1fb {
  background-position-x: -300px;
  background-position-y: -325px;
}

.emojione-1f1f1-1f1fe {
  background-position-x: -325px;
  background-position-y: -325px;
}

.emojione-1f1f1 {
  background-position-x: -350px;
  background-position-y: 0px;
}

.emojione-1f1f2-1f1e6 {
  background-position-x: -350px;
  background-position-y: -25px;
}

.emojione-1f1f2-1f1e8 {
  background-position-x: -350px;
  background-position-y: -50px;
}

.emojione-1f1f2-1f1e9 {
  background-position-x: -350px;
  background-position-y: -75px;
}

.emojione-1f1f2-1f1ea {
  background-position-x: -350px;
  background-position-y: -100px;
}

.emojione-1f1f2-1f1eb {
  background-position-x: -350px;
  background-position-y: -125px;
}

.emojione-1f1f2-1f1ec {
  background-position-x: -350px;
  background-position-y: -150px;
}

.emojione-1f1f2-1f1ed {
  background-position-x: -350px;
  background-position-y: -175px;
}

.emojione-1f1f2-1f1f0 {
  background-position-x: -350px;
  background-position-y: -200px;
}

.emojione-1f1f2-1f1f1 {
  background-position-x: -350px;
  background-position-y: -225px;
}

.emojione-1f1f2-1f1f2 {
  background-position-x: -350px;
  background-position-y: -250px;
}

.emojione-1f1f2-1f1f3 {
  background-position-x: -350px;
  background-position-y: -275px;
}

.emojione-1f1f2-1f1f4 {
  background-position-x: -350px;
  background-position-y: -300px;
}

.emojione-1f1f2-1f1f5 {
  background-position-x: -350px;
  background-position-y: -325px;
}

.emojione-1f1f2-1f1f6 {
  background-position-x: 0px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1f7 {
  background-position-x: -25px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1f8 {
  background-position-x: -50px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1f9 {
  background-position-x: -75px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1fa {
  background-position-x: -100px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1fb {
  background-position-x: -125px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1fc {
  background-position-x: -150px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1fd {
  background-position-x: -175px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1fe {
  background-position-x: -200px;
  background-position-y: -350px;
}

.emojione-1f1f2-1f1ff {
  background-position-x: -225px;
  background-position-y: -350px;
}

.emojione-1f1f2 {
  background-position-x: -250px;
  background-position-y: -350px;
}

.emojione-1f1f3-1f1e6 {
  background-position-x: -275px;
  background-position-y: -350px;
}

.emojione-1f1f3-1f1e8 {
  background-position-x: -300px;
  background-position-y: -350px;
}

.emojione-1f1f3-1f1ea {
  background-position-x: -325px;
  background-position-y: -350px;
}

.emojione-1f1f3-1f1eb {
  background-position-x: -350px;
  background-position-y: -350px;
}

.emojione-1f1f3-1f1ec {
  background-position-x: -375px;
  background-position-y: 0px;
}

.emojione-1f1f3-1f1ee {
  background-position-x: -375px;
  background-position-y: -25px;
}

.emojione-1f1f3-1f1f1 {
  background-position-x: -375px;
  background-position-y: -50px;
}

.emojione-1f1f3-1f1f4 {
  background-position-x: -375px;
  background-position-y: -75px;
}

.emojione-1f1f3-1f1f5 {
  background-position-x: -375px;
  background-position-y: -100px;
}

.emojione-1f1f3-1f1f7 {
  background-position-x: -375px;
  background-position-y: -125px;
}

.emojione-1f1f3-1f1fa {
  background-position-x: -375px;
  background-position-y: -150px;
}

.emojione-1f1f3-1f1ff {
  background-position-x: -375px;
  background-position-y: -175px;
}

.emojione-1f1f3 {
  background-position-x: -375px;
  background-position-y: -200px;
}

.emojione-1f1f4-1f1f2 {
  background-position-x: -375px;
  background-position-y: -225px;
}

.emojione-1f1f4 {
  background-position-x: -375px;
  background-position-y: -250px;
}

.emojione-1f1f5-1f1e6 {
  background-position-x: -375px;
  background-position-y: -275px;
}

.emojione-1f1f5-1f1ea {
  background-position-x: -375px;
  background-position-y: -300px;
}

.emojione-1f1f5-1f1eb {
  background-position-x: -375px;
  background-position-y: -325px;
}

.emojione-1f1f5-1f1ec {
  background-position-x: -375px;
  background-position-y: -350px;
}

.emojione-1f1f5-1f1ed {
  background-position-x: 0px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f0 {
  background-position-x: -25px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f1 {
  background-position-x: -50px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f2 {
  background-position-x: -75px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f3 {
  background-position-x: -100px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f7 {
  background-position-x: -125px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f8 {
  background-position-x: -150px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1f9 {
  background-position-x: -175px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1fc {
  background-position-x: -200px;
  background-position-y: -375px;
}

.emojione-1f1f5-1f1fe {
  background-position-x: -225px;
  background-position-y: -375px;
}

.emojione-1f1f5 {
  background-position-x: -250px;
  background-position-y: -375px;
}

.emojione-1f1f6-1f1e6 {
  background-position-x: -275px;
  background-position-y: -375px;
}

.emojione-1f1f6 {
  background-position-x: -300px;
  background-position-y: -375px;
}

.emojione-1f1f7-1f1ea {
  background-position-x: -325px;
  background-position-y: -375px;
}

.emojione-1f1f7-1f1f4 {
  background-position-x: -350px;
  background-position-y: -375px;
}

.emojione-1f1f7-1f1f8 {
  background-position-x: -375px;
  background-position-y: -375px;
}

.emojione-1f1f7-1f1fa {
  background-position-x: -400px;
  background-position-y: 0px;
}

.emojione-1f1f7-1f1fc {
  background-position-x: -400px;
  background-position-y: -25px;
}

.emojione-1f1f7 {
  background-position-x: -400px;
  background-position-y: -50px;
}

.emojione-1f1f8-1f1e6 {
  background-position-x: -400px;
  background-position-y: -75px;
}

.emojione-1f1f8-1f1e7 {
  background-position-x: -400px;
  background-position-y: -100px;
}

.emojione-1f1f8-1f1e8 {
  background-position-x: -400px;
  background-position-y: -125px;
}

.emojione-1f1f8-1f1e9 {
  background-position-x: -400px;
  background-position-y: -150px;
}

.emojione-1f1f8-1f1ea {
  background-position-x: -400px;
  background-position-y: -175px;
}

.emojione-1f1f8-1f1ec {
  background-position-x: -400px;
  background-position-y: -200px;
}

.emojione-1f1f8-1f1ed {
  background-position-x: -400px;
  background-position-y: -225px;
}

.emojione-1f1f8-1f1ee {
  background-position-x: -400px;
  background-position-y: -250px;
}

.emojione-1f1f8-1f1ef {
  background-position-x: -400px;
  background-position-y: -275px;
}

.emojione-1f1f8-1f1f0 {
  background-position-x: -400px;
  background-position-y: -300px;
}

.emojione-1f1f8-1f1f1 {
  background-position-x: -400px;
  background-position-y: -325px;
}

.emojione-1f1f8-1f1f2 {
  background-position-x: -400px;
  background-position-y: -350px;
}

.emojione-1f1f8-1f1f3 {
  background-position-x: -400px;
  background-position-y: -375px;
}

.emojione-1f1f8-1f1f4 {
  background-position-x: 0px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1f7 {
  background-position-x: -25px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1f8 {
  background-position-x: -50px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1f9 {
  background-position-x: -75px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1fb {
  background-position-x: -100px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1fd {
  background-position-x: -125px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1fe {
  background-position-x: -150px;
  background-position-y: -400px;
}

.emojione-1f1f8-1f1ff {
  background-position-x: -175px;
  background-position-y: -400px;
}

.emojione-1f1f8 {
  background-position-x: -200px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1e6 {
  background-position-x: -225px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1e8 {
  background-position-x: -250px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1e9 {
  background-position-x: -275px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1eb {
  background-position-x: -300px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1ec {
  background-position-x: -325px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1ed {
  background-position-x: -350px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1ef {
  background-position-x: -375px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1f0 {
  background-position-x: -400px;
  background-position-y: -400px;
}

.emojione-1f1f9-1f1f1 {
  background-position-x: -425px;
  background-position-y: 0px;
}

.emojione-1f1f9-1f1f2 {
  background-position-x: -425px;
  background-position-y: -25px;
}

.emojione-1f1f9-1f1f3 {
  background-position-x: -425px;
  background-position-y: -50px;
}

.emojione-1f1f9-1f1f4 {
  background-position-x: -425px;
  background-position-y: -75px;
}

.emojione-1f1f9-1f1f7 {
  background-position-x: -425px;
  background-position-y: -100px;
}

.emojione-1f1f9-1f1f9 {
  background-position-x: -425px;
  background-position-y: -125px;
}

.emojione-1f1f9-1f1fb {
  background-position-x: -425px;
  background-position-y: -150px;
}

.emojione-1f1f9-1f1fc {
  background-position-x: -425px;
  background-position-y: -175px;
}

.emojione-1f1f9-1f1ff {
  background-position-x: -425px;
  background-position-y: -200px;
}

.emojione-1f1f9 {
  background-position-x: -425px;
  background-position-y: -225px;
}

.emojione-1f1fa-1f1e6 {
  background-position-x: -425px;
  background-position-y: -250px;
}

.emojione-1f1fa-1f1ec {
  background-position-x: -425px;
  background-position-y: -275px;
}

.emojione-1f1fa-1f1f2 {
  background-position-x: -425px;
  background-position-y: -300px;
}

.emojione-1f1fa-1f1f8 {
  background-position-x: -425px;
  background-position-y: -325px;
}

.emojione-1f1fa-1f1fe {
  background-position-x: -425px;
  background-position-y: -350px;
}

.emojione-1f1fa-1f1ff {
  background-position-x: -425px;
  background-position-y: -375px;
}

.emojione-1f1fa {
  background-position-x: -425px;
  background-position-y: -400px;
}

.emojione-1f1fb-1f1e6 {
  background-position-x: 0px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1e8 {
  background-position-x: -25px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1ea {
  background-position-x: -50px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1ec {
  background-position-x: -75px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1ee {
  background-position-x: -100px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1f3 {
  background-position-x: -125px;
  background-position-y: -425px;
}

.emojione-1f1fb-1f1fa {
  background-position-x: -150px;
  background-position-y: -425px;
}

.emojione-1f1fb {
  background-position-x: -175px;
  background-position-y: -425px;
}

.emojione-1f1fc-1f1eb {
  background-position-x: -200px;
  background-position-y: -425px;
}

.emojione-1f1fc-1f1f8 {
  background-position-x: -225px;
  background-position-y: -425px;
}

.emojione-1f1fc {
  background-position-x: -250px;
  background-position-y: -425px;
}

.emojione-1f1fd-1f1f0 {
  background-position-x: -275px;
  background-position-y: -425px;
}

.emojione-1f1fd {
  background-position-x: -300px;
  background-position-y: -425px;
}

.emojione-1f1fe-1f1ea {
  background-position-x: -325px;
  background-position-y: -425px;
}

.emojione-1f1fe-1f1f9 {
  background-position-x: -350px;
  background-position-y: -425px;
}

.emojione-1f1fe {
  background-position-x: -375px;
  background-position-y: -425px;
}

.emojione-1f1ff-1f1e6 {
  background-position-x: -400px;
  background-position-y: -425px;
}

.emojione-1f1ff-1f1f2 {
  background-position-x: -425px;
  background-position-y: -425px;
}

.emojione-1f1ff-1f1fc {
  background-position-x: -450px;
  background-position-y: 0px;
}

.emojione-1f1ff {
  background-position-x: -450px;
  background-position-y: -25px;
}

.emojione-1f201 {
  background-position-x: -450px;
  background-position-y: -50px;
}

.emojione-1f202 {
  background-position-x: -450px;
  background-position-y: -75px;
}

.emojione-1f21a {
  background-position-x: -450px;
  background-position-y: -100px;
}

.emojione-1f22f {
  background-position-x: -450px;
  background-position-y: -125px;
}

.emojione-1f232 {
  background-position-x: -450px;
  background-position-y: -150px;
}

.emojione-1f233 {
  background-position-x: -450px;
  background-position-y: -175px;
}

.emojione-1f234 {
  background-position-x: -450px;
  background-position-y: -200px;
}

.emojione-1f235 {
  background-position-x: -450px;
  background-position-y: -225px;
}

.emojione-1f236 {
  background-position-x: -450px;
  background-position-y: -250px;
}

.emojione-1f237 {
  background-position-x: -450px;
  background-position-y: -275px;
}

.emojione-1f238 {
  background-position-x: -450px;
  background-position-y: -300px;
}

.emojione-1f239 {
  background-position-x: -450px;
  background-position-y: -325px;
}

.emojione-1f23a {
  background-position-x: -450px;
  background-position-y: -350px;
}

.emojione-1f250 {
  background-position-x: -450px;
  background-position-y: -375px;
}

.emojione-1f251 {
  background-position-x: -450px;
  background-position-y: -400px;
}

.emojione-1f300 {
  background-position-x: -450px;
  background-position-y: -425px;
}

.emojione-1f301 {
  background-position-x: 0px;
  background-position-y: -450px;
}

.emojione-1f302 {
  background-position-x: -25px;
  background-position-y: -450px;
}

.emojione-1f303 {
  background-position-x: -50px;
  background-position-y: -450px;
}

.emojione-1f304 {
  background-position-x: -75px;
  background-position-y: -450px;
}

.emojione-1f305 {
  background-position-x: -100px;
  background-position-y: -450px;
}

.emojione-1f306 {
  background-position-x: -125px;
  background-position-y: -450px;
}

.emojione-1f307 {
  background-position-x: -150px;
  background-position-y: -450px;
}

.emojione-1f308 {
  background-position-x: -175px;
  background-position-y: -450px;
}

.emojione-1f309 {
  background-position-x: -200px;
  background-position-y: -450px;
}

.emojione-1f30a {
  background-position-x: -225px;
  background-position-y: -450px;
}

.emojione-1f30b {
  background-position-x: -250px;
  background-position-y: -450px;
}

.emojione-1f30c {
  background-position-x: -275px;
  background-position-y: -450px;
}

.emojione-1f30d {
  background-position-x: -300px;
  background-position-y: -450px;
}

.emojione-1f30e {
  background-position-x: -325px;
  background-position-y: -450px;
}

.emojione-1f30f {
  background-position-x: -350px;
  background-position-y: -450px;
}

.emojione-1f310 {
  background-position-x: -375px;
  background-position-y: -450px;
}

.emojione-1f311 {
  background-position-x: -400px;
  background-position-y: -450px;
}

.emojione-1f312 {
  background-position-x: -425px;
  background-position-y: -450px;
}

.emojione-1f313 {
  background-position-x: -450px;
  background-position-y: -450px;
}

.emojione-1f314 {
  background-position-x: -475px;
  background-position-y: 0px;
}

.emojione-1f315 {
  background-position-x: -475px;
  background-position-y: -25px;
}

.emojione-1f316 {
  background-position-x: -475px;
  background-position-y: -50px;
}

.emojione-1f317 {
  background-position-x: -475px;
  background-position-y: -75px;
}

.emojione-1f318 {
  background-position-x: -475px;
  background-position-y: -100px;
}

.emojione-1f319 {
  background-position-x: -475px;
  background-position-y: -125px;
}

.emojione-1f31a {
  background-position-x: -475px;
  background-position-y: -150px;
}

.emojione-1f31b {
  background-position-x: -475px;
  background-position-y: -175px;
}

.emojione-1f31c {
  background-position-x: -475px;
  background-position-y: -200px;
}

.emojione-1f31d {
  background-position-x: -475px;
  background-position-y: -225px;
}

.emojione-1f31e {
  background-position-x: -475px;
  background-position-y: -250px;
}

.emojione-1f31f {
  background-position-x: -475px;
  background-position-y: -275px;
}

.emojione-1f320 {
  background-position-x: -475px;
  background-position-y: -300px;
}

.emojione-1f321 {
  background-position-x: -475px;
  background-position-y: -325px;
}

.emojione-1f324 {
  background-position-x: -475px;
  background-position-y: -350px;
}

.emojione-1f325 {
  background-position-x: -475px;
  background-position-y: -375px;
}

.emojione-1f326 {
  background-position-x: -475px;
  background-position-y: -400px;
}

.emojione-1f327 {
  background-position-x: -475px;
  background-position-y: -425px;
}

.emojione-1f328 {
  background-position-x: -475px;
  background-position-y: -450px;
}

.emojione-1f329 {
  background-position-x: 0px;
  background-position-y: -475px;
}

.emojione-1f32a {
  background-position-x: -25px;
  background-position-y: -475px;
}

.emojione-1f32b {
  background-position-x: -50px;
  background-position-y: -475px;
}

.emojione-1f32c {
  background-position-x: -75px;
  background-position-y: -475px;
}

.emojione-1f32d {
  background-position-x: -100px;
  background-position-y: -475px;
}

.emojione-1f32e {
  background-position-x: -125px;
  background-position-y: -475px;
}

.emojione-1f32f {
  background-position-x: -150px;
  background-position-y: -475px;
}

.emojione-1f330 {
  background-position-x: -175px;
  background-position-y: -475px;
}

.emojione-1f331 {
  background-position-x: -200px;
  background-position-y: -475px;
}

.emojione-1f332 {
  background-position-x: -225px;
  background-position-y: -475px;
}

.emojione-1f333 {
  background-position-x: -250px;
  background-position-y: -475px;
}

.emojione-1f334 {
  background-position-x: -275px;
  background-position-y: -475px;
}

.emojione-1f335 {
  background-position-x: -300px;
  background-position-y: -475px;
}

.emojione-1f336 {
  background-position-x: -325px;
  background-position-y: -475px;
}

.emojione-1f337 {
  background-position-x: -350px;
  background-position-y: -475px;
}

.emojione-1f338 {
  background-position-x: -375px;
  background-position-y: -475px;
}

.emojione-1f339 {
  background-position-x: -400px;
  background-position-y: -475px;
}

.emojione-1f33a {
  background-position-x: -425px;
  background-position-y: -475px;
}

.emojione-1f33b {
  background-position-x: -450px;
  background-position-y: -475px;
}

.emojione-1f33c {
  background-position-x: -475px;
  background-position-y: -475px;
}

.emojione-1f33d {
  background-position-x: -500px;
  background-position-y: 0px;
}

.emojione-1f33e {
  background-position-x: -500px;
  background-position-y: -25px;
}

.emojione-1f33f {
  background-position-x: -500px;
  background-position-y: -50px;
}

.emojione-1f340 {
  background-position-x: -500px;
  background-position-y: -75px;
}

.emojione-1f341 {
  background-position-x: -500px;
  background-position-y: -100px;
}

.emojione-1f342 {
  background-position-x: -500px;
  background-position-y: -125px;
}

.emojione-1f343 {
  background-position-x: -500px;
  background-position-y: -150px;
}

.emojione-1f344 {
  background-position-x: -500px;
  background-position-y: -175px;
}

.emojione-1f345 {
  background-position-x: -500px;
  background-position-y: -200px;
}

.emojione-1f346 {
  background-position-x: -500px;
  background-position-y: -225px;
}

.emojione-1f347 {
  background-position-x: -500px;
  background-position-y: -250px;
}

.emojione-1f348 {
  background-position-x: -500px;
  background-position-y: -275px;
}

.emojione-1f349 {
  background-position-x: -500px;
  background-position-y: -300px;
}

.emojione-1f34a {
  background-position-x: -500px;
  background-position-y: -325px;
}

.emojione-1f34b {
  background-position-x: -500px;
  background-position-y: -350px;
}

.emojione-1f34c {
  background-position-x: -500px;
  background-position-y: -375px;
}

.emojione-1f34d {
  background-position-x: -500px;
  background-position-y: -400px;
}

.emojione-1f34e {
  background-position-x: -500px;
  background-position-y: -425px;
}

.emojione-1f34f {
  background-position-x: -500px;
  background-position-y: -450px;
}

.emojione-1f350 {
  background-position-x: -500px;
  background-position-y: -475px;
}

.emojione-1f351 {
  background-position-x: 0px;
  background-position-y: -500px;
}

.emojione-1f352 {
  background-position-x: -25px;
  background-position-y: -500px;
}

.emojione-1f353 {
  background-position-x: -50px;
  background-position-y: -500px;
}

.emojione-1f354 {
  background-position-x: -75px;
  background-position-y: -500px;
}

.emojione-1f355 {
  background-position-x: -100px;
  background-position-y: -500px;
}

.emojione-1f356 {
  background-position-x: -125px;
  background-position-y: -500px;
}

.emojione-1f357 {
  background-position-x: -150px;
  background-position-y: -500px;
}

.emojione-1f358 {
  background-position-x: -175px;
  background-position-y: -500px;
}

.emojione-1f359 {
  background-position-x: -200px;
  background-position-y: -500px;
}

.emojione-1f35a {
  background-position-x: -225px;
  background-position-y: -500px;
}

.emojione-1f35b {
  background-position-x: -250px;
  background-position-y: -500px;
}

.emojione-1f35c {
  background-position-x: -275px;
  background-position-y: -500px;
}

.emojione-1f35d {
  background-position-x: -300px;
  background-position-y: -500px;
}

.emojione-1f35e {
  background-position-x: -325px;
  background-position-y: -500px;
}

.emojione-1f35f {
  background-position-x: -350px;
  background-position-y: -500px;
}

.emojione-1f360 {
  background-position-x: -375px;
  background-position-y: -500px;
}

.emojione-1f361 {
  background-position-x: -400px;
  background-position-y: -500px;
}

.emojione-1f362 {
  background-position-x: -425px;
  background-position-y: -500px;
}

.emojione-1f363 {
  background-position-x: -450px;
  background-position-y: -500px;
}

.emojione-1f364 {
  background-position-x: -475px;
  background-position-y: -500px;
}

.emojione-1f365 {
  background-position-x: -500px;
  background-position-y: -500px;
}

.emojione-1f366 {
  background-position-x: -525px;
  background-position-y: 0px;
}

.emojione-1f367 {
  background-position-x: -525px;
  background-position-y: -25px;
}

.emojione-1f368 {
  background-position-x: -525px;
  background-position-y: -50px;
}

.emojione-1f369 {
  background-position-x: -525px;
  background-position-y: -75px;
}

.emojione-1f36a {
  background-position-x: -525px;
  background-position-y: -100px;
}

.emojione-1f36b {
  background-position-x: -525px;
  background-position-y: -125px;
}

.emojione-1f36c {
  background-position-x: -525px;
  background-position-y: -150px;
}

.emojione-1f36d {
  background-position-x: -525px;
  background-position-y: -175px;
}

.emojione-1f36e {
  background-position-x: -525px;
  background-position-y: -200px;
}

.emojione-1f36f {
  background-position-x: -525px;
  background-position-y: -225px;
}

.emojione-1f370 {
  background-position-x: -525px;
  background-position-y: -250px;
}

.emojione-1f371 {
  background-position-x: -525px;
  background-position-y: -275px;
}

.emojione-1f372 {
  background-position-x: -525px;
  background-position-y: -300px;
}

.emojione-1f373 {
  background-position-x: -525px;
  background-position-y: -325px;
}

.emojione-1f374 {
  background-position-x: -525px;
  background-position-y: -350px;
}

.emojione-1f375 {
  background-position-x: -525px;
  background-position-y: -375px;
}

.emojione-1f376 {
  background-position-x: -525px;
  background-position-y: -400px;
}

.emojione-1f377 {
  background-position-x: -525px;
  background-position-y: -425px;
}

.emojione-1f378 {
  background-position-x: -525px;
  background-position-y: -450px;
}

.emojione-1f379 {
  background-position-x: -525px;
  background-position-y: -475px;
}

.emojione-1f37a {
  background-position-x: -525px;
  background-position-y: -500px;
}

.emojione-1f37b {
  background-position-x: 0px;
  background-position-y: -525px;
}

.emojione-1f37c {
  background-position-x: -25px;
  background-position-y: -525px;
}

.emojione-1f37d {
  background-position-x: -50px;
  background-position-y: -525px;
}

.emojione-1f37e {
  background-position-x: -75px;
  background-position-y: -525px;
}

.emojione-1f37f {
  background-position-x: -100px;
  background-position-y: -525px;
}

.emojione-1f380 {
  background-position-x: -125px;
  background-position-y: -525px;
}

.emojione-1f381 {
  background-position-x: -150px;
  background-position-y: -525px;
}

.emojione-1f382 {
  background-position-x: -175px;
  background-position-y: -525px;
}

.emojione-1f383 {
  background-position-x: -200px;
  background-position-y: -525px;
}

.emojione-1f384 {
  background-position-x: -225px;
  background-position-y: -525px;
}

.emojione-1f385-1f3fb {
  background-position-x: -250px;
  background-position-y: -525px;
}

.emojione-1f385-1f3fc {
  background-position-x: -275px;
  background-position-y: -525px;
}

.emojione-1f385-1f3fd {
  background-position-x: -300px;
  background-position-y: -525px;
}

.emojione-1f385-1f3fe {
  background-position-x: -325px;
  background-position-y: -525px;
}

.emojione-1f385-1f3ff {
  background-position-x: -350px;
  background-position-y: -525px;
}

.emojione-1f385 {
  background-position-x: -375px;
  background-position-y: -525px;
}

.emojione-1f386 {
  background-position-x: -400px;
  background-position-y: -525px;
}

.emojione-1f387 {
  background-position-x: -425px;
  background-position-y: -525px;
}

.emojione-1f388 {
  background-position-x: -450px;
  background-position-y: -525px;
}

.emojione-1f389 {
  background-position-x: -475px;
  background-position-y: -525px;
}

.emojione-1f38a {
  background-position-x: -500px;
  background-position-y: -525px;
}

.emojione-1f38b {
  background-position-x: -525px;
  background-position-y: -525px;
}

.emojione-1f38c {
  background-position-x: -550px;
  background-position-y: 0px;
}

.emojione-1f38d {
  background-position-x: -550px;
  background-position-y: -25px;
}

.emojione-1f38e {
  background-position-x: -550px;
  background-position-y: -50px;
}

.emojione-1f38f {
  background-position-x: -550px;
  background-position-y: -75px;
}

.emojione-1f390 {
  background-position-x: -550px;
  background-position-y: -100px;
}

.emojione-1f391 {
  background-position-x: -550px;
  background-position-y: -125px;
}

.emojione-1f392 {
  background-position-x: -550px;
  background-position-y: -150px;
}

.emojione-1f393 {
  background-position-x: -550px;
  background-position-y: -175px;
}

.emojione-1f396 {
  background-position-x: -550px;
  background-position-y: -200px;
}

.emojione-1f397 {
  background-position-x: -550px;
  background-position-y: -225px;
}

.emojione-1f399 {
  background-position-x: -550px;
  background-position-y: -250px;
}

.emojione-1f39a {
  background-position-x: -550px;
  background-position-y: -275px;
}

.emojione-1f39b {
  background-position-x: -550px;
  background-position-y: -300px;
}

.emojione-1f39e {
  background-position-x: -550px;
  background-position-y: -325px;
}

.emojione-1f39f {
  background-position-x: -550px;
  background-position-y: -350px;
}

.emojione-1f3a0 {
  background-position-x: -550px;
  background-position-y: -375px;
}

.emojione-1f3a1 {
  background-position-x: -550px;
  background-position-y: -400px;
}

.emojione-1f3a2 {
  background-position-x: -550px;
  background-position-y: -425px;
}

.emojione-1f3a3 {
  background-position-x: -550px;
  background-position-y: -450px;
}

.emojione-1f3a4 {
  background-position-x: -550px;
  background-position-y: -475px;
}

.emojione-1f3a5 {
  background-position-x: -550px;
  background-position-y: -500px;
}

.emojione-1f3a6 {
  background-position-x: -550px;
  background-position-y: -525px;
}

.emojione-1f3a7 {
  background-position-x: 0px;
  background-position-y: -550px;
}

.emojione-1f3a8 {
  background-position-x: -25px;
  background-position-y: -550px;
}

.emojione-1f3a9 {
  background-position-x: -50px;
  background-position-y: -550px;
}

.emojione-1f3aa {
  background-position-x: -75px;
  background-position-y: -550px;
}

.emojione-1f3ab {
  background-position-x: -100px;
  background-position-y: -550px;
}

.emojione-1f3ac {
  background-position-x: -125px;
  background-position-y: -550px;
}

.emojione-1f3ad {
  background-position-x: -150px;
  background-position-y: -550px;
}

.emojione-1f3ae {
  background-position-x: -175px;
  background-position-y: -550px;
}

.emojione-1f3af {
  background-position-x: -200px;
  background-position-y: -550px;
}

.emojione-1f3b0 {
  background-position-x: -225px;
  background-position-y: -550px;
}

.emojione-1f3b1 {
  background-position-x: -250px;
  background-position-y: -550px;
}

.emojione-1f3b2 {
  background-position-x: -275px;
  background-position-y: -550px;
}

.emojione-1f3b3 {
  background-position-x: -300px;
  background-position-y: -550px;
}

.emojione-1f3b4 {
  background-position-x: -325px;
  background-position-y: -550px;
}

.emojione-1f3b5 {
  background-position-x: -350px;
  background-position-y: -550px;
}

.emojione-1f3b6 {
  background-position-x: -375px;
  background-position-y: -550px;
}

.emojione-1f3b7 {
  background-position-x: -400px;
  background-position-y: -550px;
}

.emojione-1f3b8 {
  background-position-x: -425px;
  background-position-y: -550px;
}

.emojione-1f3b9 {
  background-position-x: -450px;
  background-position-y: -550px;
}

.emojione-1f3ba {
  background-position-x: -475px;
  background-position-y: -550px;
}

.emojione-1f3bb {
  background-position-x: -500px;
  background-position-y: -550px;
}

.emojione-1f3bc {
  background-position-x: -525px;
  background-position-y: -550px;
}

.emojione-1f3bd {
  background-position-x: -550px;
  background-position-y: -550px;
}

.emojione-1f3be {
  background-position-x: -575px;
  background-position-y: 0px;
}

.emojione-1f3bf {
  background-position-x: -575px;
  background-position-y: -25px;
}

.emojione-1f3c0 {
  background-position-x: -575px;
  background-position-y: -50px;
}

.emojione-1f3c1 {
  background-position-x: -575px;
  background-position-y: -75px;
}

.emojione-1f3c2 {
  background-position-x: -575px;
  background-position-y: -100px;
}

.emojione-1f3c3-1f3fb {
  background-position-x: -575px;
  background-position-y: -125px;
}

.emojione-1f3c3-1f3fc {
  background-position-x: -575px;
  background-position-y: -150px;
}

.emojione-1f3c3-1f3fd {
  background-position-x: -575px;
  background-position-y: -175px;
}

.emojione-1f3c3-1f3fe {
  background-position-x: -575px;
  background-position-y: -200px;
}

.emojione-1f3c3-1f3ff {
  background-position-x: -575px;
  background-position-y: -225px;
}

.emojione-1f3c3 {
  background-position-x: -575px;
  background-position-y: -250px;
}

.emojione-1f3c4-1f3fb {
  background-position-x: -575px;
  background-position-y: -275px;
}

.emojione-1f3c4-1f3fc {
  background-position-x: -575px;
  background-position-y: -300px;
}

.emojione-1f3c4-1f3fd {
  background-position-x: -575px;
  background-position-y: -325px;
}

.emojione-1f3c4-1f3fe {
  background-position-x: -575px;
  background-position-y: -350px;
}

.emojione-1f3c4-1f3ff {
  background-position-x: -575px;
  background-position-y: -375px;
}

.emojione-1f3c4 {
  background-position-x: -575px;
  background-position-y: -400px;
}

.emojione-1f3c5 {
  background-position-x: -575px;
  background-position-y: -425px;
}

.emojione-1f3c6 {
  background-position-x: -575px;
  background-position-y: -450px;
}

.emojione-1f3c7-1f3fb {
  background-position-x: -575px;
  background-position-y: -475px;
}

.emojione-1f3c7-1f3fc {
  background-position-x: -575px;
  background-position-y: -500px;
}

.emojione-1f3c7-1f3fd {
  background-position-x: -575px;
  background-position-y: -525px;
}

.emojione-1f3c7-1f3fe {
  background-position-x: -575px;
  background-position-y: -550px;
}

.emojione-1f3c7-1f3ff {
  background-position-x: 0px;
  background-position-y: -575px;
}

.emojione-1f3c7 {
  background-position-x: -25px;
  background-position-y: -575px;
}

.emojione-1f3c8 {
  background-position-x: -50px;
  background-position-y: -575px;
}

.emojione-1f3c9 {
  background-position-x: -75px;
  background-position-y: -575px;
}

.emojione-1f3ca-1f3fb {
  background-position-x: -100px;
  background-position-y: -575px;
}

.emojione-1f3ca-1f3fc {
  background-position-x: -125px;
  background-position-y: -575px;
}

.emojione-1f3ca-1f3fd {
  background-position-x: -150px;
  background-position-y: -575px;
}

.emojione-1f3ca-1f3fe {
  background-position-x: -175px;
  background-position-y: -575px;
}

.emojione-1f3ca-1f3ff {
  background-position-x: -200px;
  background-position-y: -575px;
}

.emojione-1f3ca {
  background-position-x: -225px;
  background-position-y: -575px;
}

.emojione-1f3cb-1f3fb {
  background-position-x: -250px;
  background-position-y: -575px;
}

.emojione-1f3cb-1f3fc {
  background-position-x: -275px;
  background-position-y: -575px;
}

.emojione-1f3cb-1f3fd {
  background-position-x: -300px;
  background-position-y: -575px;
}

.emojione-1f3cb-1f3fe {
  background-position-x: -325px;
  background-position-y: -575px;
}

.emojione-1f3cb-1f3ff {
  background-position-x: -350px;
  background-position-y: -575px;
}

.emojione-1f3cb {
  background-position-x: -375px;
  background-position-y: -575px;
}

.emojione-1f3cc {
  background-position-x: -400px;
  background-position-y: -575px;
}

.emojione-1f3cd {
  background-position-x: -425px;
  background-position-y: -575px;
}

.emojione-1f3ce {
  background-position-x: -450px;
  background-position-y: -575px;
}

.emojione-1f3cf {
  background-position-x: -475px;
  background-position-y: -575px;
}

.emojione-1f3d0 {
  background-position-x: -500px;
  background-position-y: -575px;
}

.emojione-1f3d1 {
  background-position-x: -525px;
  background-position-y: -575px;
}

.emojione-1f3d2 {
  background-position-x: -550px;
  background-position-y: -575px;
}

.emojione-1f3d3 {
  background-position-x: -575px;
  background-position-y: -575px;
}

.emojione-1f3d4 {
  background-position-x: -600px;
  background-position-y: 0px;
}

.emojione-1f3d5 {
  background-position-x: -600px;
  background-position-y: -25px;
}

.emojione-1f3d6 {
  background-position-x: -600px;
  background-position-y: -50px;
}

.emojione-1f3d7 {
  background-position-x: -600px;
  background-position-y: -75px;
}

.emojione-1f3d8 {
  background-position-x: -600px;
  background-position-y: -100px;
}

.emojione-1f3d9 {
  background-position-x: -600px;
  background-position-y: -125px;
}

.emojione-1f3da {
  background-position-x: -600px;
  background-position-y: -150px;
}

.emojione-1f3db {
  background-position-x: -600px;
  background-position-y: -175px;
}

.emojione-1f3dc {
  background-position-x: -600px;
  background-position-y: -200px;
}

.emojione-1f3dd {
  background-position-x: -600px;
  background-position-y: -225px;
}

.emojione-1f3de {
  background-position-x: -600px;
  background-position-y: -250px;
}

.emojione-1f3df {
  background-position-x: -600px;
  background-position-y: -275px;
}

.emojione-1f3e0 {
  background-position-x: -600px;
  background-position-y: -300px;
}

.emojione-1f3e1 {
  background-position-x: -600px;
  background-position-y: -325px;
}

.emojione-1f3e2 {
  background-position-x: -600px;
  background-position-y: -350px;
}

.emojione-1f3e3 {
  background-position-x: -600px;
  background-position-y: -375px;
}

.emojione-1f3e4 {
  background-position-x: -600px;
  background-position-y: -400px;
}

.emojione-1f3e5 {
  background-position-x: -600px;
  background-position-y: -425px;
}

.emojione-1f3e6 {
  background-position-x: -600px;
  background-position-y: -450px;
}

.emojione-1f3e7 {
  background-position-x: -600px;
  background-position-y: -475px;
}

.emojione-1f3e8 {
  background-position-x: -600px;
  background-position-y: -500px;
}

.emojione-1f3e9 {
  background-position-x: -600px;
  background-position-y: -525px;
}

.emojione-1f3ea {
  background-position-x: -600px;
  background-position-y: -550px;
}

.emojione-1f3eb {
  background-position-x: -600px;
  background-position-y: -575px;
}

.emojione-1f3ec {
  background-position-x: 0px;
  background-position-y: -600px;
}

.emojione-1f3ed {
  background-position-x: -25px;
  background-position-y: -600px;
}

.emojione-1f3ee {
  background-position-x: -50px;
  background-position-y: -600px;
}

.emojione-1f3ef {
  background-position-x: -75px;
  background-position-y: -600px;
}

.emojione-1f3f0 {
  background-position-x: -100px;
  background-position-y: -600px;
}

.emojione-1f3f3-1f308 {
  background-position-x: -125px;
  background-position-y: -600px;
}

.emojione-1f3f3 {
  background-position-x: -150px;
  background-position-y: -600px;
}

.emojione-1f3f4 {
  background-position-x: -175px;
  background-position-y: -600px;
}

.emojione-1f3f5 {
  background-position-x: -200px;
  background-position-y: -600px;
}

.emojione-1f3f7 {
  background-position-x: -225px;
  background-position-y: -600px;
}

.emojione-1f3f8 {
  background-position-x: -250px;
  background-position-y: -600px;
}

.emojione-1f3f9 {
  background-position-x: -275px;
  background-position-y: -600px;
}

.emojione-1f3fa {
  background-position-x: -300px;
  background-position-y: -600px;
}

.emojione-1f3fb {
  background-position-x: -325px;
  background-position-y: -600px;
}

.emojione-1f3fc {
  background-position-x: -350px;
  background-position-y: -600px;
}

.emojione-1f3fd {
  background-position-x: -375px;
  background-position-y: -600px;
}

.emojione-1f3fe {
  background-position-x: -400px;
  background-position-y: -600px;
}

.emojione-1f3ff {
  background-position-x: -425px;
  background-position-y: -600px;
}

.emojione-1f400 {
  background-position-x: -450px;
  background-position-y: -600px;
}

.emojione-1f401 {
  background-position-x: -475px;
  background-position-y: -600px;
}

.emojione-1f402 {
  background-position-x: -500px;
  background-position-y: -600px;
}

.emojione-1f403 {
  background-position-x: -525px;
  background-position-y: -600px;
}

.emojione-1f404 {
  background-position-x: -550px;
  background-position-y: -600px;
}

.emojione-1f405 {
  background-position-x: -575px;
  background-position-y: -600px;
}

.emojione-1f406 {
  background-position-x: -600px;
  background-position-y: -600px;
}

.emojione-1f407 {
  background-position-x: -625px;
  background-position-y: 0px;
}

.emojione-1f408 {
  background-position-x: -625px;
  background-position-y: -25px;
}

.emojione-1f409 {
  background-position-x: -625px;
  background-position-y: -50px;
}

.emojione-1f40a {
  background-position-x: -625px;
  background-position-y: -75px;
}

.emojione-1f40b {
  background-position-x: -625px;
  background-position-y: -100px;
}

.emojione-1f40c {
  background-position-x: -625px;
  background-position-y: -125px;
}

.emojione-1f40d {
  background-position-x: -625px;
  background-position-y: -150px;
}

.emojione-1f40e {
  background-position-x: -625px;
  background-position-y: -175px;
}

.emojione-1f40f {
  background-position-x: -625px;
  background-position-y: -200px;
}

.emojione-1f410 {
  background-position-x: -625px;
  background-position-y: -225px;
}

.emojione-1f411 {
  background-position-x: -625px;
  background-position-y: -250px;
}

.emojione-1f412 {
  background-position-x: -625px;
  background-position-y: -275px;
}

.emojione-1f413 {
  background-position-x: -625px;
  background-position-y: -300px;
}

.emojione-1f414 {
  background-position-x: -625px;
  background-position-y: -325px;
}

.emojione-1f415 {
  background-position-x: -625px;
  background-position-y: -350px;
}

.emojione-1f416 {
  background-position-x: -625px;
  background-position-y: -375px;
}

.emojione-1f417 {
  background-position-x: -625px;
  background-position-y: -400px;
}

.emojione-1f418 {
  background-position-x: -625px;
  background-position-y: -425px;
}

.emojione-1f419 {
  background-position-x: -625px;
  background-position-y: -450px;
}

.emojione-1f41a {
  background-position-x: -625px;
  background-position-y: -475px;
}

.emojione-1f41b {
  background-position-x: -625px;
  background-position-y: -500px;
}

.emojione-1f41c {
  background-position-x: -625px;
  background-position-y: -525px;
}

.emojione-1f41d {
  background-position-x: -625px;
  background-position-y: -550px;
}

.emojione-1f41e {
  background-position-x: -625px;
  background-position-y: -575px;
}

.emojione-1f41f {
  background-position-x: -625px;
  background-position-y: -600px;
}

.emojione-1f420 {
  background-position-x: 0px;
  background-position-y: -625px;
}

.emojione-1f421 {
  background-position-x: -25px;
  background-position-y: -625px;
}

.emojione-1f422 {
  background-position-x: -50px;
  background-position-y: -625px;
}

.emojione-1f423 {
  background-position-x: -75px;
  background-position-y: -625px;
}

.emojione-1f424 {
  background-position-x: -100px;
  background-position-y: -625px;
}

.emojione-1f425 {
  background-position-x: -125px;
  background-position-y: -625px;
}

.emojione-1f426 {
  background-position-x: -150px;
  background-position-y: -625px;
}

.emojione-1f427 {
  background-position-x: -175px;
  background-position-y: -625px;
}

.emojione-1f428 {
  background-position-x: -200px;
  background-position-y: -625px;
}

.emojione-1f429 {
  background-position-x: -225px;
  background-position-y: -625px;
}

.emojione-1f42a {
  background-position-x: -250px;
  background-position-y: -625px;
}

.emojione-1f42b {
  background-position-x: -275px;
  background-position-y: -625px;
}

.emojione-1f42c {
  background-position-x: -300px;
  background-position-y: -625px;
}

.emojione-1f42d {
  background-position-x: -325px;
  background-position-y: -625px;
}

.emojione-1f42e {
  background-position-x: -350px;
  background-position-y: -625px;
}

.emojione-1f42f {
  background-position-x: -375px;
  background-position-y: -625px;
}

.emojione-1f430 {
  background-position-x: -400px;
  background-position-y: -625px;
}

.emojione-1f431 {
  background-position-x: -425px;
  background-position-y: -625px;
}

.emojione-1f432 {
  background-position-x: -450px;
  background-position-y: -625px;
}

.emojione-1f433 {
  background-position-x: -475px;
  background-position-y: -625px;
}

.emojione-1f434 {
  background-position-x: -500px;
  background-position-y: -625px;
}

.emojione-1f435 {
  background-position-x: -525px;
  background-position-y: -625px;
}

.emojione-1f436 {
  background-position-x: -550px;
  background-position-y: -625px;
}

.emojione-1f437 {
  background-position-x: -575px;
  background-position-y: -625px;
}

.emojione-1f438 {
  background-position-x: -600px;
  background-position-y: -625px;
}

.emojione-1f439 {
  background-position-x: -625px;
  background-position-y: -625px;
}

.emojione-1f43a {
  background-position-x: -650px;
  background-position-y: 0px;
}

.emojione-1f43b {
  background-position-x: -650px;
  background-position-y: -25px;
}

.emojione-1f43c {
  background-position-x: -650px;
  background-position-y: -50px;
}

.emojione-1f43d {
  background-position-x: -650px;
  background-position-y: -75px;
}

.emojione-1f43e {
  background-position-x: -650px;
  background-position-y: -100px;
}

.emojione-1f43f {
  background-position-x: -650px;
  background-position-y: -125px;
}

.emojione-1f440 {
  background-position-x: -650px;
  background-position-y: -150px;
}

.emojione-1f441-1f5e8 {
  background-position-x: -650px;
  background-position-y: -175px;
}

.emojione-1f441 {
  background-position-x: -650px;
  background-position-y: -200px;
}

.emojione-1f442-1f3fb {
  background-position-x: -650px;
  background-position-y: -225px;
}

.emojione-1f442-1f3fc {
  background-position-x: -650px;
  background-position-y: -250px;
}

.emojione-1f442-1f3fd {
  background-position-x: -650px;
  background-position-y: -275px;
}

.emojione-1f442-1f3fe {
  background-position-x: -650px;
  background-position-y: -300px;
}

.emojione-1f442-1f3ff {
  background-position-x: -650px;
  background-position-y: -325px;
}

.emojione-1f442 {
  background-position-x: -650px;
  background-position-y: -350px;
}

.emojione-1f443-1f3fb {
  background-position-x: -650px;
  background-position-y: -375px;
}

.emojione-1f443-1f3fc {
  background-position-x: -650px;
  background-position-y: -400px;
}

.emojione-1f443-1f3fd {
  background-position-x: -650px;
  background-position-y: -425px;
}

.emojione-1f443-1f3fe {
  background-position-x: -650px;
  background-position-y: -450px;
}

.emojione-1f443-1f3ff {
  background-position-x: -650px;
  background-position-y: -475px;
}

.emojione-1f443 {
  background-position-x: -650px;
  background-position-y: -500px;
}

.emojione-1f444 {
  background-position-x: -650px;
  background-position-y: -525px;
}

.emojione-1f445 {
  background-position-x: -650px;
  background-position-y: -550px;
}

.emojione-1f446-1f3fb {
  background-position-x: -650px;
  background-position-y: -575px;
}

.emojione-1f446-1f3fc {
  background-position-x: -650px;
  background-position-y: -600px;
}

.emojione-1f446-1f3fd {
  background-position-x: -650px;
  background-position-y: -625px;
}

.emojione-1f446-1f3fe {
  background-position-x: 0px;
  background-position-y: -650px;
}

.emojione-1f446-1f3ff {
  background-position-x: -25px;
  background-position-y: -650px;
}

.emojione-1f446 {
  background-position-x: -50px;
  background-position-y: -650px;
}

.emojione-1f447-1f3fb {
  background-position-x: -75px;
  background-position-y: -650px;
}

.emojione-1f447-1f3fc {
  background-position-x: -100px;
  background-position-y: -650px;
}

.emojione-1f447-1f3fd {
  background-position-x: -125px;
  background-position-y: -650px;
}

.emojione-1f447-1f3fe {
  background-position-x: -150px;
  background-position-y: -650px;
}

.emojione-1f447-1f3ff {
  background-position-x: -175px;
  background-position-y: -650px;
}

.emojione-1f447 {
  background-position-x: -200px;
  background-position-y: -650px;
}

.emojione-1f448-1f3fb {
  background-position-x: -225px;
  background-position-y: -650px;
}

.emojione-1f448-1f3fc {
  background-position-x: -250px;
  background-position-y: -650px;
}

.emojione-1f448-1f3fd {
  background-position-x: -275px;
  background-position-y: -650px;
}

.emojione-1f448-1f3fe {
  background-position-x: -300px;
  background-position-y: -650px;
}

.emojione-1f448-1f3ff {
  background-position-x: -325px;
  background-position-y: -650px;
}

.emojione-1f448 {
  background-position-x: -350px;
  background-position-y: -650px;
}

.emojione-1f449-1f3fb {
  background-position-x: -375px;
  background-position-y: -650px;
}

.emojione-1f449-1f3fc {
  background-position-x: -400px;
  background-position-y: -650px;
}

.emojione-1f449-1f3fd {
  background-position-x: -425px;
  background-position-y: -650px;
}

.emojione-1f449-1f3fe {
  background-position-x: -450px;
  background-position-y: -650px;
}

.emojione-1f449-1f3ff {
  background-position-x: -475px;
  background-position-y: -650px;
}

.emojione-1f449 {
  background-position-x: -500px;
  background-position-y: -650px;
}

.emojione-1f44a-1f3fb {
  background-position-x: -525px;
  background-position-y: -650px;
}

.emojione-1f44a-1f3fc {
  background-position-x: -550px;
  background-position-y: -650px;
}

.emojione-1f44a-1f3fd {
  background-position-x: -575px;
  background-position-y: -650px;
}

.emojione-1f44a-1f3fe {
  background-position-x: -600px;
  background-position-y: -650px;
}

.emojione-1f44a-1f3ff {
  background-position-x: -625px;
  background-position-y: -650px;
}

.emojione-1f44a {
  background-position-x: -650px;
  background-position-y: -650px;
}

.emojione-1f44b-1f3fb {
  background-position-x: -675px;
  background-position-y: 0px;
}

.emojione-1f44b-1f3fc {
  background-position-x: -675px;
  background-position-y: -25px;
}

.emojione-1f44b-1f3fd {
  background-position-x: -675px;
  background-position-y: -50px;
}

.emojione-1f44b-1f3fe {
  background-position-x: -675px;
  background-position-y: -75px;
}

.emojione-1f44b-1f3ff {
  background-position-x: -675px;
  background-position-y: -100px;
}

.emojione-1f44b {
  background-position-x: -675px;
  background-position-y: -125px;
}

.emojione-1f44c-1f3fb {
  background-position-x: -675px;
  background-position-y: -150px;
}

.emojione-1f44c-1f3fc {
  background-position-x: -675px;
  background-position-y: -175px;
}

.emojione-1f44c-1f3fd {
  background-position-x: -675px;
  background-position-y: -200px;
}

.emojione-1f44c-1f3fe {
  background-position-x: -675px;
  background-position-y: -225px;
}

.emojione-1f44c-1f3ff {
  background-position-x: -675px;
  background-position-y: -250px;
}

.emojione-1f44c {
  background-position-x: -675px;
  background-position-y: -275px;
}

.emojione-1f44d-1f3fb {
  background-position-x: -675px;
  background-position-y: -300px;
}

.emojione-1f44d-1f3fc {
  background-position-x: -675px;
  background-position-y: -325px;
}

.emojione-1f44d-1f3fd {
  background-position-x: -675px;
  background-position-y: -350px;
}

.emojione-1f44d-1f3fe {
  background-position-x: -675px;
  background-position-y: -375px;
}

.emojione-1f44d-1f3ff {
  background-position-x: -675px;
  background-position-y: -400px;
}

.emojione-1f44d {
  background-position-x: -675px;
  background-position-y: -425px;
}

.emojione-1f44e-1f3fb {
  background-position-x: -675px;
  background-position-y: -450px;
}

.emojione-1f44e-1f3fc {
  background-position-x: -675px;
  background-position-y: -475px;
}

.emojione-1f44e-1f3fd {
  background-position-x: -675px;
  background-position-y: -500px;
}

.emojione-1f44e-1f3fe {
  background-position-x: -675px;
  background-position-y: -525px;
}

.emojione-1f44e-1f3ff {
  background-position-x: -675px;
  background-position-y: -550px;
}

.emojione-1f44e {
  background-position-x: -675px;
  background-position-y: -575px;
}

.emojione-1f44f-1f3fb {
  background-position-x: -675px;
  background-position-y: -600px;
}

.emojione-1f44f-1f3fc {
  background-position-x: -675px;
  background-position-y: -625px;
}

.emojione-1f44f-1f3fd {
  background-position-x: -675px;
  background-position-y: -650px;
}

.emojione-1f44f-1f3fe {
  background-position-x: 0px;
  background-position-y: -675px;
}

.emojione-1f44f-1f3ff {
  background-position-x: -25px;
  background-position-y: -675px;
}

.emojione-1f44f {
  background-position-x: -50px;
  background-position-y: -675px;
}

.emojione-1f450-1f3fb {
  background-position-x: -75px;
  background-position-y: -675px;
}

.emojione-1f450-1f3fc {
  background-position-x: -100px;
  background-position-y: -675px;
}

.emojione-1f450-1f3fd {
  background-position-x: -125px;
  background-position-y: -675px;
}

.emojione-1f450-1f3fe {
  background-position-x: -150px;
  background-position-y: -675px;
}

.emojione-1f450-1f3ff {
  background-position-x: -175px;
  background-position-y: -675px;
}

.emojione-1f450 {
  background-position-x: -200px;
  background-position-y: -675px;
}

.emojione-1f451 {
  background-position-x: -225px;
  background-position-y: -675px;
}

.emojione-1f452 {
  background-position-x: -250px;
  background-position-y: -675px;
}

.emojione-1f453 {
  background-position-x: -275px;
  background-position-y: -675px;
}

.emojione-1f454 {
  background-position-x: -300px;
  background-position-y: -675px;
}

.emojione-1f455 {
  background-position-x: -325px;
  background-position-y: -675px;
}

.emojione-1f456 {
  background-position-x: -350px;
  background-position-y: -675px;
}

.emojione-1f457 {
  background-position-x: -375px;
  background-position-y: -675px;
}

.emojione-1f458 {
  background-position-x: -400px;
  background-position-y: -675px;
}

.emojione-1f459 {
  background-position-x: -425px;
  background-position-y: -675px;
}

.emojione-1f45a {
  background-position-x: -450px;
  background-position-y: -675px;
}

.emojione-1f45b {
  background-position-x: -475px;
  background-position-y: -675px;
}

.emojione-1f45c {
  background-position-x: -500px;
  background-position-y: -675px;
}

.emojione-1f45d {
  background-position-x: -525px;
  background-position-y: -675px;
}

.emojione-1f45e {
  background-position-x: -550px;
  background-position-y: -675px;
}

.emojione-1f45f {
  background-position-x: -575px;
  background-position-y: -675px;
}

.emojione-1f460 {
  background-position-x: -600px;
  background-position-y: -675px;
}

.emojione-1f461 {
  background-position-x: -625px;
  background-position-y: -675px;
}

.emojione-1f462 {
  background-position-x: -650px;
  background-position-y: -675px;
}

.emojione-1f463 {
  background-position-x: -675px;
  background-position-y: -675px;
}

.emojione-1f464 {
  background-position-x: -700px;
  background-position-y: 0px;
}

.emojione-1f465 {
  background-position-x: -700px;
  background-position-y: -25px;
}

.emojione-1f466-1f3fb {
  background-position-x: -700px;
  background-position-y: -50px;
}

.emojione-1f466-1f3fc {
  background-position-x: -700px;
  background-position-y: -75px;
}

.emojione-1f466-1f3fd {
  background-position-x: -700px;
  background-position-y: -100px;
}

.emojione-1f466-1f3fe {
  background-position-x: -700px;
  background-position-y: -125px;
}

.emojione-1f466-1f3ff {
  background-position-x: -700px;
  background-position-y: -150px;
}

.emojione-1f466 {
  background-position-x: -700px;
  background-position-y: -175px;
}

.emojione-1f467-1f3fb {
  background-position-x: -700px;
  background-position-y: -200px;
}

.emojione-1f467-1f3fc {
  background-position-x: -700px;
  background-position-y: -225px;
}

.emojione-1f467-1f3fd {
  background-position-x: -700px;
  background-position-y: -250px;
}

.emojione-1f467-1f3fe {
  background-position-x: -700px;
  background-position-y: -275px;
}

.emojione-1f467-1f3ff {
  background-position-x: -700px;
  background-position-y: -300px;
}

.emojione-1f467 {
  background-position-x: -700px;
  background-position-y: -325px;
}

.emojione-1f468-1f3fb {
  background-position-x: -700px;
  background-position-y: -350px;
}

.emojione-1f468-1f3fc {
  background-position-x: -700px;
  background-position-y: -375px;
}

.emojione-1f468-1f3fd {
  background-position-x: -700px;
  background-position-y: -400px;
}

.emojione-1f468-1f3fe {
  background-position-x: -700px;
  background-position-y: -425px;
}

.emojione-1f468-1f3ff {
  background-position-x: -700px;
  background-position-y: -450px;
}

.emojione-1f468-1f468-1f466-1f466 {
  background-position-x: -700px;
  background-position-y: -475px;
}

.emojione-1f468-1f468-1f466 {
  background-position-x: -700px;
  background-position-y: -500px;
}

.emojione-1f468-1f468-1f467-1f466 {
  background-position-x: -700px;
  background-position-y: -525px;
}

.emojione-1f468-1f468-1f467-1f467 {
  background-position-x: -700px;
  background-position-y: -550px;
}

.emojione-1f468-1f468-1f467 {
  background-position-x: -700px;
  background-position-y: -575px;
}

.emojione-1f468-1f469-1f466-1f466 {
  background-position-x: -700px;
  background-position-y: -600px;
}

.emojione-1f468-1f469-1f467-1f466 {
  background-position-x: -700px;
  background-position-y: -625px;
}

.emojione-1f468-1f469-1f467-1f467 {
  background-position-x: -700px;
  background-position-y: -650px;
}

.emojione-1f468-1f469-1f467 {
  background-position-x: -700px;
  background-position-y: -675px;
}

.emojione-1f468-2764-1f468 {
  background-position-x: 0px;
  background-position-y: -700px;
}

.emojione-1f468-2764-1f48b-1f468 {
  background-position-x: -25px;
  background-position-y: -700px;
}

.emojione-1f468 {
  background-position-x: -50px;
  background-position-y: -700px;
}

.emojione-1f469-1f3fb {
  background-position-x: -75px;
  background-position-y: -700px;
}

.emojione-1f469-1f3fc {
  background-position-x: -100px;
  background-position-y: -700px;
}

.emojione-1f469-1f3fd {
  background-position-x: -125px;
  background-position-y: -700px;
}

.emojione-1f469-1f3fe {
  background-position-x: -150px;
  background-position-y: -700px;
}

.emojione-1f469-1f3ff {
  background-position-x: -175px;
  background-position-y: -700px;
}

.emojione-1f469-1f469-1f466-1f466 {
  background-position-x: -200px;
  background-position-y: -700px;
}

.emojione-1f469-1f469-1f466 {
  background-position-x: -225px;
  background-position-y: -700px;
}

.emojione-1f469-1f469-1f467-1f466 {
  background-position-x: -250px;
  background-position-y: -700px;
}

.emojione-1f469-1f469-1f467-1f467 {
  background-position-x: -275px;
  background-position-y: -700px;
}

.emojione-1f469-1f469-1f467 {
  background-position-x: -300px;
  background-position-y: -700px;
}

.emojione-1f469-2764-1f469 {
  background-position-x: -325px;
  background-position-y: -700px;
}

.emojione-1f469-2764-1f48b-1f469 {
  background-position-x: -350px;
  background-position-y: -700px;
}

.emojione-1f469 {
  background-position-x: -375px;
  background-position-y: -700px;
}

.emojione-1f46a {
  background-position-x: -400px;
  background-position-y: -700px;
}

.emojione-1f46b {
  background-position-x: -425px;
  background-position-y: -700px;
}

.emojione-1f46c {
  background-position-x: -450px;
  background-position-y: -700px;
}

.emojione-1f46d {
  background-position-x: -475px;
  background-position-y: -700px;
}

.emojione-1f46e-1f3fb {
  background-position-x: -500px;
  background-position-y: -700px;
}

.emojione-1f46e-1f3fc {
  background-position-x: 0px;
  background-position-y: 0px;
}

.emojione-1f46e-1f3fd {
  background-position-x: -550px;
  background-position-y: -700px;
}

.emojione-1f46e-1f3fe {
  background-position-x: -575px;
  background-position-y: -700px;
}

.emojione-1f46e-1f3ff {
  background-position-x: -600px;
  background-position-y: -700px;
}

.emojione-1f46e {
  background-position-x: -625px;
  background-position-y: -700px;
}

.emojione-1f46f {
  background-position-x: -650px;
  background-position-y: -700px;
}

.emojione-1f470-1f3fb {
  background-position-x: -675px;
  background-position-y: -700px;
}

.emojione-1f470-1f3fc {
  background-position-x: -700px;
  background-position-y: -700px;
}

.emojione-1f470-1f3fd {
  background-position-x: -725px;
  background-position-y: 0px;
}

.emojione-1f470-1f3fe {
  background-position-x: -725px;
  background-position-y: -25px;
}

.emojione-1f470-1f3ff {
  background-position-x: -725px;
  background-position-y: -50px;
}

.emojione-1f470 {
  background-position-x: -725px;
  background-position-y: -75px;
}

.emojione-1f471-1f3fb {
  background-position-x: -725px;
  background-position-y: -100px;
}

.emojione-1f471-1f3fc {
  background-position-x: -725px;
  background-position-y: -125px;
}

.emojione-1f471-1f3fd {
  background-position-x: -725px;
  background-position-y: -150px;
}

.emojione-1f471-1f3fe {
  background-position-x: -725px;
  background-position-y: -175px;
}

.emojione-1f471-1f3ff {
  background-position-x: -725px;
  background-position-y: -200px;
}

.emojione-1f471 {
  background-position-x: -725px;
  background-position-y: -225px;
}

.emojione-1f472-1f3fb {
  background-position-x: -725px;
  background-position-y: -250px;
}

.emojione-1f472-1f3fc {
  background-position-x: -725px;
  background-position-y: -275px;
}

.emojione-1f472-1f3fd {
  background-position-x: -725px;
  background-position-y: -300px;
}

.emojione-1f472-1f3fe {
  background-position-x: -725px;
  background-position-y: -325px;
}

.emojione-1f472-1f3ff {
  background-position-x: -725px;
  background-position-y: -350px;
}

.emojione-1f472 {
  background-position-x: -725px;
  background-position-y: -375px;
}

.emojione-1f473-1f3fb {
  background-position-x: -725px;
  background-position-y: -400px;
}

.emojione-1f473-1f3fc {
  background-position-x: -725px;
  background-position-y: -425px;
}

.emojione-1f473-1f3fd {
  background-position-x: -725px;
  background-position-y: -450px;
}

.emojione-1f473-1f3fe {
  background-position-x: -725px;
  background-position-y: -475px;
}

.emojione-1f473-1f3ff {
  background-position-x: -725px;
  background-position-y: -500px;
}

.emojione-1f473 {
  background-position-x: -725px;
  background-position-y: -525px;
}

.emojione-1f474-1f3fb {
  background-position-x: -725px;
  background-position-y: -550px;
}

.emojione-1f474-1f3fc {
  background-position-x: -725px;
  background-position-y: -575px;
}

.emojione-1f474-1f3fd {
  background-position-x: -725px;
  background-position-y: -600px;
}

.emojione-1f474-1f3fe {
  background-position-x: -725px;
  background-position-y: -625px;
}

.emojione-1f474-1f3ff {
  background-position-x: -725px;
  background-position-y: -650px;
}

.emojione-1f474 {
  background-position-x: -725px;
  background-position-y: -675px;
}

.emojione-1f475-1f3fb {
  background-position-x: -725px;
  background-position-y: -700px;
}

.emojione-1f475-1f3fc {
  background-position-x: 0px;
  background-position-y: -725px;
}

.emojione-1f475-1f3fd {
  background-position-x: -25px;
  background-position-y: -725px;
}

.emojione-1f475-1f3fe {
  background-position-x: -50px;
  background-position-y: -725px;
}

.emojione-1f475-1f3ff {
  background-position-x: -75px;
  background-position-y: -725px;
}

.emojione-1f475 {
  background-position-x: -100px;
  background-position-y: -725px;
}

.emojione-1f476-1f3fb {
  background-position-x: -125px;
  background-position-y: -725px;
}

.emojione-1f476-1f3fc {
  background-position-x: -150px;
  background-position-y: -725px;
}

.emojione-1f476-1f3fd {
  background-position-x: -175px;
  background-position-y: -725px;
}

.emojione-1f476-1f3fe {
  background-position-x: -200px;
  background-position-y: -725px;
}

.emojione-1f476-1f3ff {
  background-position-x: -225px;
  background-position-y: -725px;
}

.emojione-1f476 {
  background-position-x: -250px;
  background-position-y: -725px;
}

.emojione-1f477-1f3fb {
  background-position-x: -275px;
  background-position-y: -725px;
}

.emojione-1f477-1f3fc {
  background-position-x: -300px;
  background-position-y: -725px;
}

.emojione-1f477-1f3fd {
  background-position-x: -325px;
  background-position-y: -725px;
}

.emojione-1f477-1f3fe {
  background-position-x: -350px;
  background-position-y: -725px;
}

.emojione-1f477-1f3ff {
  background-position-x: -375px;
  background-position-y: -725px;
}

.emojione-1f477 {
  background-position-x: -400px;
  background-position-y: -725px;
}

.emojione-1f478-1f3fb {
  background-position-x: -425px;
  background-position-y: -725px;
}

.emojione-1f478-1f3fc {
  background-position-x: -450px;
  background-position-y: -725px;
}

.emojione-1f478-1f3fd {
  background-position-x: -475px;
  background-position-y: -725px;
}

.emojione-1f478-1f3fe {
  background-position-x: -500px;
  background-position-y: -725px;
}

.emojione-1f478-1f3ff {
  background-position-x: -525px;
  background-position-y: -725px;
}

.emojione-1f478 {
  background-position-x: -550px;
  background-position-y: -725px;
}

.emojione-1f479 {
  background-position-x: -575px;
  background-position-y: -725px;
}

.emojione-1f47a {
  background-position-x: -600px;
  background-position-y: -725px;
}

.emojione-1f47b {
  background-position-x: -625px;
  background-position-y: -725px;
}

.emojione-1f47c-1f3fb {
  background-position-x: -650px;
  background-position-y: -725px;
}

.emojione-1f47c-1f3fc {
  background-position-x: -675px;
  background-position-y: -725px;
}

.emojione-1f47c-1f3fd {
  background-position-x: -700px;
  background-position-y: -725px;
}

.emojione-1f47c-1f3fe {
  background-position-x: -725px;
  background-position-y: -725px;
}

.emojione-1f47c-1f3ff {
  background-position-x: -750px;
  background-position-y: 0px;
}

.emojione-1f47c {
  background-position-x: -750px;
  background-position-y: -25px;
}

.emojione-1f47d {
  background-position-x: -750px;
  background-position-y: -50px;
}

.emojione-1f47e {
  background-position-x: -750px;
  background-position-y: -75px;
}

.emojione-1f47f {
  background-position-x: -750px;
  background-position-y: -100px;
}

.emojione-1f480 {
  background-position-x: -750px;
  background-position-y: -125px;
}

.emojione-1f481-1f3fb {
  background-position-x: -750px;
  background-position-y: -150px;
}

.emojione-1f481-1f3fc {
  background-position-x: -750px;
  background-position-y: -175px;
}

.emojione-1f481-1f3fd {
  background-position-x: -750px;
  background-position-y: -200px;
}

.emojione-1f481-1f3fe {
  background-position-x: -750px;
  background-position-y: -225px;
}

.emojione-1f481-1f3ff {
  background-position-x: -750px;
  background-position-y: -250px;
}

.emojione-1f481 {
  background-position-x: -750px;
  background-position-y: -275px;
}

.emojione-1f482-1f3fb {
  background-position-x: -750px;
  background-position-y: -300px;
}

.emojione-1f482-1f3fc {
  background-position-x: -750px;
  background-position-y: -325px;
}

.emojione-1f482-1f3fd {
  background-position-x: -750px;
  background-position-y: -350px;
}

.emojione-1f482-1f3fe {
  background-position-x: -750px;
  background-position-y: -375px;
}

.emojione-1f482-1f3ff {
  background-position-x: -750px;
  background-position-y: -400px;
}

.emojione-1f482 {
  background-position-x: -750px;
  background-position-y: -425px;
}

.emojione-1f483-1f3fb {
  background-position-x: -750px;
  background-position-y: -450px;
}

.emojione-1f483-1f3fc {
  background-position-x: -750px;
  background-position-y: -475px;
}

.emojione-1f483-1f3fd {
  background-position-x: -750px;
  background-position-y: -500px;
}

.emojione-1f483-1f3fe {
  background-position-x: -750px;
  background-position-y: -525px;
}

.emojione-1f483-1f3ff {
  background-position-x: -750px;
  background-position-y: -550px;
}

.emojione-1f483 {
  background-position-x: -750px;
  background-position-y: -575px;
}

.emojione-1f484 {
  background-position-x: -750px;
  background-position-y: -600px;
}

.emojione-1f485-1f3fb {
  background-position-x: -750px;
  background-position-y: -625px;
}

.emojione-1f485-1f3fc {
  background-position-x: -750px;
  background-position-y: -650px;
}

.emojione-1f485-1f3fd {
  background-position-x: -750px;
  background-position-y: -675px;
}

.emojione-1f485-1f3fe {
  background-position-x: -750px;
  background-position-y: -700px;
}

.emojione-1f485-1f3ff {
  background-position-x: -750px;
  background-position-y: -725px;
}

.emojione-1f485 {
  background-position-x: 0px;
  background-position-y: -750px;
}

.emojione-1f486-1f3fb {
  background-position-x: -25px;
  background-position-y: -750px;
}

.emojione-1f486-1f3fc {
  background-position-x: -50px;
  background-position-y: -750px;
}

.emojione-1f486-1f3fd {
  background-position-x: -75px;
  background-position-y: -750px;
}

.emojione-1f486-1f3fe {
  background-position-x: -100px;
  background-position-y: -750px;
}

.emojione-1f486-1f3ff {
  background-position-x: -125px;
  background-position-y: -750px;
}

.emojione-1f486 {
  background-position-x: -150px;
  background-position-y: -750px;
}

.emojione-1f487-1f3fb {
  background-position-x: -175px;
  background-position-y: -750px;
}

.emojione-1f487-1f3fc {
  background-position-x: -200px;
  background-position-y: -750px;
}

.emojione-1f487-1f3fd {
  background-position-x: -225px;
  background-position-y: -750px;
}

.emojione-1f487-1f3fe {
  background-position-x: -250px;
  background-position-y: -750px;
}

.emojione-1f487-1f3ff {
  background-position-x: -275px;
  background-position-y: -750px;
}

.emojione-1f487 {
  background-position-x: -300px;
  background-position-y: -750px;
}

.emojione-1f488 {
  background-position-x: -325px;
  background-position-y: -750px;
}

.emojione-1f489 {
  background-position-x: -350px;
  background-position-y: -750px;
}

.emojione-1f48a {
  background-position-x: -375px;
  background-position-y: -750px;
}

.emojione-1f48b {
  background-position-x: -400px;
  background-position-y: -750px;
}

.emojione-1f48c {
  background-position-x: -425px;
  background-position-y: -750px;
}

.emojione-1f48d {
  background-position-x: -450px;
  background-position-y: -750px;
}

.emojione-1f48e {
  background-position-x: -475px;
  background-position-y: -750px;
}

.emojione-1f48f {
  background-position-x: -500px;
  background-position-y: -750px;
}

.emojione-1f490 {
  background-position-x: -525px;
  background-position-y: -750px;
}

.emojione-1f491 {
  background-position-x: -550px;
  background-position-y: -750px;
}

.emojione-1f492 {
  background-position-x: -575px;
  background-position-y: -750px;
}

.emojione-1f493 {
  background-position-x: -600px;
  background-position-y: -750px;
}

.emojione-1f494 {
  background-position-x: -625px;
  background-position-y: -750px;
}

.emojione-1f495 {
  background-position-x: -650px;
  background-position-y: -750px;
}

.emojione-1f496 {
  background-position-x: -675px;
  background-position-y: -750px;
}

.emojione-1f497 {
  background-position-x: -700px;
  background-position-y: -750px;
}

.emojione-1f498 {
  background-position-x: -725px;
  background-position-y: -750px;
}

.emojione-1f499 {
  background-position-x: -750px;
  background-position-y: -750px;
}

.emojione-1f49a {
  background-position-x: -775px;
  background-position-y: 0px;
}

.emojione-1f49b {
  background-position-x: -775px;
  background-position-y: -25px;
}

.emojione-1f49c {
  background-position-x: -775px;
  background-position-y: -50px;
}

.emojione-1f49d {
  background-position-x: -775px;
  background-position-y: -75px;
}

.emojione-1f49e {
  background-position-x: -775px;
  background-position-y: -100px;
}

.emojione-1f49f {
  background-position-x: -775px;
  background-position-y: -125px;
}

.emojione-1f4a0 {
  background-position-x: -775px;
  background-position-y: -150px;
}

.emojione-1f4a1 {
  background-position-x: -775px;
  background-position-y: -175px;
}

.emojione-1f4a2 {
  background-position-x: -775px;
  background-position-y: -200px;
}

.emojione-1f4a3 {
  background-position-x: -775px;
  background-position-y: -225px;
}

.emojione-1f4a4 {
  background-position-x: -775px;
  background-position-y: -250px;
}

.emojione-1f4a5 {
  background-position-x: -775px;
  background-position-y: -275px;
}

.emojione-1f4a6 {
  background-position-x: -775px;
  background-position-y: -300px;
}

.emojione-1f4a7 {
  background-position-x: -775px;
  background-position-y: -325px;
}

.emojione-1f4a8 {
  background-position-x: -775px;
  background-position-y: -350px;
}

.emojione-1f4a9 {
  background-position-x: -775px;
  background-position-y: -375px;
}

.emojione-1f4aa-1f3fb {
  background-position-x: -775px;
  background-position-y: -400px;
}

.emojione-1f4aa-1f3fc {
  background-position-x: -775px;
  background-position-y: -425px;
}

.emojione-1f4aa-1f3fd {
  background-position-x: -775px;
  background-position-y: -450px;
}

.emojione-1f4aa-1f3fe {
  background-position-x: -775px;
  background-position-y: -475px;
}

.emojione-1f4aa-1f3ff {
  background-position-x: -775px;
  background-position-y: -500px;
}

.emojione-1f4aa {
  background-position-x: -775px;
  background-position-y: -525px;
}

.emojione-1f4ab {
  background-position-x: -775px;
  background-position-y: -550px;
}

.emojione-1f4ac {
  background-position-x: -775px;
  background-position-y: -575px;
}

.emojione-1f4ad {
  background-position-x: -775px;
  background-position-y: -600px;
}

.emojione-1f4ae {
  background-position-x: -775px;
  background-position-y: -625px;
}

.emojione-1f4af {
  background-position-x: -775px;
  background-position-y: -650px;
}

.emojione-1f4b0 {
  background-position-x: -775px;
  background-position-y: -675px;
}

.emojione-1f4b1 {
  background-position-x: -775px;
  background-position-y: -700px;
}

.emojione-1f4b2 {
  background-position-x: -775px;
  background-position-y: -725px;
}

.emojione-1f4b3 {
  background-position-x: -775px;
  background-position-y: -750px;
}

.emojione-1f4b4 {
  background-position-x: 0px;
  background-position-y: -775px;
}

.emojione-1f4b5 {
  background-position-x: -25px;
  background-position-y: -775px;
}

.emojione-1f4b6 {
  background-position-x: -50px;
  background-position-y: -775px;
}

.emojione-1f4b7 {
  background-position-x: -75px;
  background-position-y: -775px;
}

.emojione-1f4b8 {
  background-position-x: -100px;
  background-position-y: -775px;
}

.emojione-1f4b9 {
  background-position-x: -125px;
  background-position-y: -775px;
}

.emojione-1f4ba {
  background-position-x: -150px;
  background-position-y: -775px;
}

.emojione-1f4bb {
  background-position-x: -175px;
  background-position-y: -775px;
}

.emojione-1f4bc {
  background-position-x: -200px;
  background-position-y: -775px;
}

.emojione-1f4bd {
  background-position-x: -225px;
  background-position-y: -775px;
}

.emojione-1f4be {
  background-position-x: -250px;
  background-position-y: -775px;
}

.emojione-1f4bf {
  background-position-x: -275px;
  background-position-y: -775px;
}

.emojione-1f4c0 {
  background-position-x: -300px;
  background-position-y: -775px;
}

.emojione-1f4c1 {
  background-position-x: -325px;
  background-position-y: -775px;
}

.emojione-1f4c2 {
  background-position-x: -350px;
  background-position-y: -775px;
}

.emojione-1f4c3 {
  background-position-x: -375px;
  background-position-y: -775px;
}

.emojione-1f4c4 {
  background-position-x: -400px;
  background-position-y: -775px;
}

.emojione-1f4c5 {
  background-position-x: -425px;
  background-position-y: -775px;
}

.emojione-1f4c6 {
  background-position-x: -450px;
  background-position-y: -775px;
}

.emojione-1f4c7 {
  background-position-x: -475px;
  background-position-y: -775px;
}

.emojione-1f4c8 {
  background-position-x: -500px;
  background-position-y: -775px;
}

.emojione-1f4c9 {
  background-position-x: -525px;
  background-position-y: -775px;
}

.emojione-1f4ca {
  background-position-x: -550px;
  background-position-y: -775px;
}

.emojione-1f4cb {
  background-position-x: -575px;
  background-position-y: -775px;
}

.emojione-1f4cc {
  background-position-x: -600px;
  background-position-y: -775px;
}

.emojione-1f4cd {
  background-position-x: -625px;
  background-position-y: -775px;
}

.emojione-1f4ce {
  background-position-x: -650px;
  background-position-y: -775px;
}

.emojione-1f4cf {
  background-position-x: -675px;
  background-position-y: -775px;
}

.emojione-1f4d0 {
  background-position-x: -700px;
  background-position-y: -775px;
}

.emojione-1f4d1 {
  background-position-x: -725px;
  background-position-y: -775px;
}

.emojione-1f4d2 {
  background-position-x: -750px;
  background-position-y: -775px;
}

.emojione-1f4d3 {
  background-position-x: -775px;
  background-position-y: -775px;
}

.emojione-1f4d4 {
  background-position-x: -800px;
  background-position-y: 0px;
}

.emojione-1f4d5 {
  background-position-x: -800px;
  background-position-y: -25px;
}

.emojione-1f4d6 {
  background-position-x: -800px;
  background-position-y: -50px;
}

.emojione-1f4d7 {
  background-position-x: -800px;
  background-position-y: -75px;
}

.emojione-1f4d8 {
  background-position-x: -800px;
  background-position-y: -100px;
}

.emojione-1f4d9 {
  background-position-x: -800px;
  background-position-y: -125px;
}

.emojione-1f4da {
  background-position-x: -800px;
  background-position-y: -150px;
}

.emojione-1f4db {
  background-position-x: -800px;
  background-position-y: -175px;
}

.emojione-1f4dc {
  background-position-x: -800px;
  background-position-y: -200px;
}

.emojione-1f4dd {
  background-position-x: -800px;
  background-position-y: -225px;
}

.emojione-1f4de {
  background-position-x: -800px;
  background-position-y: -250px;
}

.emojione-1f4df {
  background-position-x: -800px;
  background-position-y: -275px;
}

.emojione-1f4e0 {
  background-position-x: -800px;
  background-position-y: -300px;
}

.emojione-1f4e1 {
  background-position-x: -800px;
  background-position-y: -325px;
}

.emojione-1f4e2 {
  background-position-x: -800px;
  background-position-y: -350px;
}

.emojione-1f4e3 {
  background-position-x: -800px;
  background-position-y: -375px;
}

.emojione-1f4e4 {
  background-position-x: -800px;
  background-position-y: -400px;
}

.emojione-1f4e5 {
  background-position-x: -800px;
  background-position-y: -425px;
}

.emojione-1f4e6 {
  background-position-x: -800px;
  background-position-y: -450px;
}

.emojione-1f4e7 {
  background-position-x: -800px;
  background-position-y: -475px;
}

.emojione-1f4e8 {
  background-position-x: -800px;
  background-position-y: -500px;
}

.emojione-1f4e9 {
  background-position-x: -800px;
  background-position-y: -525px;
}

.emojione-1f4ea {
  background-position-x: -800px;
  background-position-y: -550px;
}

.emojione-1f4eb {
  background-position-x: -800px;
  background-position-y: -575px;
}

.emojione-1f4ec {
  background-position-x: -800px;
  background-position-y: -600px;
}

.emojione-1f4ed {
  background-position-x: -800px;
  background-position-y: -625px;
}

.emojione-1f4ee {
  background-position-x: -800px;
  background-position-y: -650px;
}

.emojione-1f4ef {
  background-position-x: -800px;
  background-position-y: -675px;
}

.emojione-1f4f0 {
  background-position-x: -800px;
  background-position-y: -700px;
}

.emojione-1f4f1 {
  background-position-x: -800px;
  background-position-y: -725px;
}

.emojione-1f4f2 {
  background-position-x: -800px;
  background-position-y: -750px;
}

.emojione-1f4f3 {
  background-position-x: -800px;
  background-position-y: -775px;
}

.emojione-1f4f4 {
  background-position-x: 0px;
  background-position-y: -800px;
}

.emojione-1f4f5 {
  background-position-x: -25px;
  background-position-y: -800px;
}

.emojione-1f4f6 {
  background-position-x: -50px;
  background-position-y: -800px;
}

.emojione-1f4f7 {
  background-position-x: -75px;
  background-position-y: -800px;
}

.emojione-1f4f8 {
  background-position-x: -100px;
  background-position-y: -800px;
}

.emojione-1f4f9 {
  background-position-x: -125px;
  background-position-y: -800px;
}

.emojione-1f4fa {
  background-position-x: -150px;
  background-position-y: -800px;
}

.emojione-1f4fb {
  background-position-x: -175px;
  background-position-y: -800px;
}

.emojione-1f4fc {
  background-position-x: -200px;
  background-position-y: -800px;
}

.emojione-1f4fd {
  background-position-x: -225px;
  background-position-y: -800px;
}

.emojione-1f4ff {
  background-position-x: -250px;
  background-position-y: -800px;
}

.emojione-1f500 {
  background-position-x: -275px;
  background-position-y: -800px;
}

.emojione-1f501 {
  background-position-x: -300px;
  background-position-y: -800px;
}

.emojione-1f502 {
  background-position-x: -325px;
  background-position-y: -800px;
}

.emojione-1f503 {
  background-position-x: -350px;
  background-position-y: -800px;
}

.emojione-1f504 {
  background-position-x: -375px;
  background-position-y: -800px;
}

.emojione-1f505 {
  background-position-x: -400px;
  background-position-y: -800px;
}

.emojione-1f506 {
  background-position-x: -425px;
  background-position-y: -800px;
}

.emojione-1f507 {
  background-position-x: -450px;
  background-position-y: -800px;
}

.emojione-1f508 {
  background-position-x: -475px;
  background-position-y: -800px;
}

.emojione-1f509 {
  background-position-x: -500px;
  background-position-y: -800px;
}

.emojione-1f50a {
  background-position-x: -525px;
  background-position-y: -800px;
}

.emojione-1f50b {
  background-position-x: -550px;
  background-position-y: -800px;
}

.emojione-1f50c {
  background-position-x: -575px;
  background-position-y: -800px;
}

.emojione-1f50d {
  background-position-x: -600px;
  background-position-y: -800px;
}

.emojione-1f50e {
  background-position-x: -625px;
  background-position-y: -800px;
}

.emojione-1f50f {
  background-position-x: -650px;
  background-position-y: -800px;
}

.emojione-1f510 {
  background-position-x: -675px;
  background-position-y: -800px;
}

.emojione-1f511 {
  background-position-x: -700px;
  background-position-y: -800px;
}

.emojione-1f512 {
  background-position-x: -725px;
  background-position-y: -800px;
}

.emojione-1f513 {
  background-position-x: -750px;
  background-position-y: -800px;
}

.emojione-1f514 {
  background-position-x: -775px;
  background-position-y: -800px;
}

.emojione-1f515 {
  background-position-x: -800px;
  background-position-y: -800px;
}

.emojione-1f516 {
  background-position-x: -825px;
  background-position-y: 0px;
}

.emojione-1f517 {
  background-position-x: -825px;
  background-position-y: -25px;
}

.emojione-1f518 {
  background-position-x: -825px;
  background-position-y: -50px;
}

.emojione-1f519 {
  background-position-x: -825px;
  background-position-y: -75px;
}

.emojione-1f51a {
  background-position-x: -825px;
  background-position-y: -100px;
}

.emojione-1f51b {
  background-position-x: -825px;
  background-position-y: -125px;
}

.emojione-1f51c {
  background-position-x: -825px;
  background-position-y: -150px;
}

.emojione-1f51d {
  background-position-x: -825px;
  background-position-y: -175px;
}

.emojione-1f51e {
  background-position-x: -825px;
  background-position-y: -200px;
}

.emojione-1f51f {
  background-position-x: -825px;
  background-position-y: -225px;
}

.emojione-1f520 {
  background-position-x: -825px;
  background-position-y: -250px;
}

.emojione-1f521 {
  background-position-x: -825px;
  background-position-y: -275px;
}

.emojione-1f522 {
  background-position-x: -825px;
  background-position-y: -300px;
}

.emojione-1f523 {
  background-position-x: -825px;
  background-position-y: -325px;
}

.emojione-1f524 {
  background-position-x: -825px;
  background-position-y: -350px;
}

.emojione-1f525 {
  background-position-x: -825px;
  background-position-y: -375px;
}

.emojione-1f526 {
  background-position-x: -825px;
  background-position-y: -400px;
}

.emojione-1f527 {
  background-position-x: -825px;
  background-position-y: -425px;
}

.emojione-1f528 {
  background-position-x: -825px;
  background-position-y: -450px;
}

.emojione-1f529 {
  background-position-x: -825px;
  background-position-y: -475px;
}

.emojione-1f52a {
  background-position-x: -825px;
  background-position-y: -500px;
}

.emojione-1f52b {
  background-position-x: -825px;
  background-position-y: -525px;
}

.emojione-1f52c {
  background-position-x: -825px;
  background-position-y: -550px;
}

.emojione-1f52d {
  background-position-x: -825px;
  background-position-y: -575px;
}

.emojione-1f52e {
  background-position-x: -825px;
  background-position-y: -600px;
}

.emojione-1f52f {
  background-position-x: -825px;
  background-position-y: -625px;
}

.emojione-1f530 {
  background-position-x: -825px;
  background-position-y: -650px;
}

.emojione-1f531 {
  background-position-x: -825px;
  background-position-y: -675px;
}

.emojione-1f532 {
  background-position-x: -825px;
  background-position-y: -700px;
}

.emojione-1f533 {
  background-position-x: -825px;
  background-position-y: -725px;
}

.emojione-1f534 {
  background-position-x: -825px;
  background-position-y: -750px;
}

.emojione-1f535 {
  background-position-x: -825px;
  background-position-y: -775px;
}

.emojione-1f536 {
  background-position-x: -825px;
  background-position-y: -800px;
}

.emojione-1f537 {
  background-position-x: 0px;
  background-position-y: -825px;
}

.emojione-1f538 {
  background-position-x: -25px;
  background-position-y: -825px;
}

.emojione-1f539 {
  background-position-x: -50px;
  background-position-y: -825px;
}

.emojione-1f53a {
  background-position-x: -75px;
  background-position-y: -825px;
}

.emojione-1f53b {
  background-position-x: -100px;
  background-position-y: -825px;
}

.emojione-1f53c {
  background-position-x: -125px;
  background-position-y: -825px;
}

.emojione-1f53d {
  background-position-x: -150px;
  background-position-y: -825px;
}

.emojione-1f549 {
  background-position-x: -175px;
  background-position-y: -825px;
}

.emojione-1f54a {
  background-position-x: -200px;
  background-position-y: -825px;
}

.emojione-1f54b {
  background-position-x: -225px;
  background-position-y: -825px;
}

.emojione-1f54c {
  background-position-x: -250px;
  background-position-y: -825px;
}

.emojione-1f54d {
  background-position-x: -275px;
  background-position-y: -825px;
}

.emojione-1f54e {
  background-position-x: -300px;
  background-position-y: -825px;
}

.emojione-1f550 {
  background-position-x: -325px;
  background-position-y: -825px;
}

.emojione-1f551 {
  background-position-x: -350px;
  background-position-y: -825px;
}

.emojione-1f552 {
  background-position-x: -375px;
  background-position-y: -825px;
}

.emojione-1f553 {
  background-position-x: -400px;
  background-position-y: -825px;
}

.emojione-1f554 {
  background-position-x: -425px;
  background-position-y: -825px;
}

.emojione-1f555 {
  background-position-x: -450px;
  background-position-y: -825px;
}

.emojione-1f556 {
  background-position-x: -475px;
  background-position-y: -825px;
}

.emojione-1f557 {
  background-position-x: -500px;
  background-position-y: -825px;
}

.emojione-1f558 {
  background-position-x: -525px;
  background-position-y: -825px;
}

.emojione-1f559 {
  background-position-x: -550px;
  background-position-y: -825px;
}

.emojione-1f55a {
  background-position-x: -575px;
  background-position-y: -825px;
}

.emojione-1f55b {
  background-position-x: -600px;
  background-position-y: -825px;
}

.emojione-1f55c {
  background-position-x: -625px;
  background-position-y: -825px;
}

.emojione-1f55d {
  background-position-x: -650px;
  background-position-y: -825px;
}

.emojione-1f55e {
  background-position-x: -675px;
  background-position-y: -825px;
}

.emojione-1f55f {
  background-position-x: -700px;
  background-position-y: -825px;
}

.emojione-1f560 {
  background-position-x: -725px;
  background-position-y: -825px;
}

.emojione-1f561 {
  background-position-x: -750px;
  background-position-y: -825px;
}

.emojione-1f562 {
  background-position-x: -775px;
  background-position-y: -825px;
}

.emojione-1f563 {
  background-position-x: -800px;
  background-position-y: -825px;
}

.emojione-1f564 {
  background-position-x: -825px;
  background-position-y: -825px;
}

.emojione-1f565 {
  background-position-x: -850px;
  background-position-y: 0px;
}

.emojione-1f566 {
  background-position-x: -850px;
  background-position-y: -25px;
}

.emojione-1f567 {
  background-position-x: -850px;
  background-position-y: -50px;
}

.emojione-1f56f {
  background-position-x: -850px;
  background-position-y: -75px;
}

.emojione-1f570 {
  background-position-x: -850px;
  background-position-y: -100px;
}

.emojione-1f573 {
  background-position-x: -850px;
  background-position-y: -125px;
}

.emojione-1f574 {
  background-position-x: -850px;
  background-position-y: -150px;
}

.emojione-1f575-1f3fb {
  background-position-x: -850px;
  background-position-y: -175px;
}

.emojione-1f575-1f3fc {
  background-position-x: -850px;
  background-position-y: -200px;
}

.emojione-1f575-1f3fd {
  background-position-x: -850px;
  background-position-y: -225px;
}

.emojione-1f575-1f3fe {
  background-position-x: -850px;
  background-position-y: -250px;
}

.emojione-1f575-1f3ff {
  background-position-x: -850px;
  background-position-y: -275px;
}

.emojione-1f575 {
  background-position-x: -850px;
  background-position-y: -300px;
}

.emojione-1f576 {
  background-position-x: -850px;
  background-position-y: -325px;
}

.emojione-1f577 {
  background-position-x: -850px;
  background-position-y: -350px;
}

.emojione-1f578 {
  background-position-x: -850px;
  background-position-y: -375px;
}

.emojione-1f579 {
  background-position-x: -850px;
  background-position-y: -400px;
}

.emojione-1f57a-1f3fb {
  background-position-x: -850px;
  background-position-y: -425px;
}

.emojione-1f57a-1f3fc {
  background-position-x: -850px;
  background-position-y: -450px;
}

.emojione-1f57a-1f3fd {
  background-position-x: -850px;
  background-position-y: -475px;
}

.emojione-1f57a-1f3fe {
  background-position-x: -850px;
  background-position-y: -500px;
}

.emojione-1f57a-1f3ff {
  background-position-x: -850px;
  background-position-y: -525px;
}

.emojione-1f57a {
  background-position-x: -850px;
  background-position-y: -550px;
}

.emojione-1f587 {
  background-position-x: -850px;
  background-position-y: -575px;
}

.emojione-1f58a {
  background-position-x: -850px;
  background-position-y: -600px;
}

.emojione-1f58b {
  background-position-x: -850px;
  background-position-y: -625px;
}

.emojione-1f58c {
  background-position-x: -850px;
  background-position-y: -650px;
}

.emojione-1f58d {
  background-position-x: -850px;
  background-position-y: -675px;
}

.emojione-1f590-1f3fb {
  background-position-x: -850px;
  background-position-y: -700px;
}

.emojione-1f590-1f3fc {
  background-position-x: -850px;
  background-position-y: -725px;
}

.emojione-1f590-1f3fd {
  background-position-x: -850px;
  background-position-y: -750px;
}

.emojione-1f590-1f3fe {
  background-position-x: -850px;
  background-position-y: -775px;
}

.emojione-1f590-1f3ff {
  background-position-x: -850px;
  background-position-y: -800px;
}

.emojione-1f590 {
  background-position-x: -850px;
  background-position-y: -825px;
}

.emojione-1f595-1f3fb {
  background-position-x: 0px;
  background-position-y: -850px;
}

.emojione-1f595-1f3fc {
  background-position-x: -25px;
  background-position-y: -850px;
}

.emojione-1f595-1f3fd {
  background-position-x: -50px;
  background-position-y: -850px;
}

.emojione-1f595-1f3fe {
  background-position-x: -75px;
  background-position-y: -850px;
}

.emojione-1f595-1f3ff {
  background-position-x: -100px;
  background-position-y: -850px;
}

.emojione-1f595 {
  background-position-x: -125px;
  background-position-y: -850px;
}

.emojione-1f596-1f3fb {
  background-position-x: -150px;
  background-position-y: -850px;
}

.emojione-1f596-1f3fc {
  background-position-x: -175px;
  background-position-y: -850px;
}

.emojione-1f596-1f3fd {
  background-position-x: -200px;
  background-position-y: -850px;
}

.emojione-1f596-1f3fe {
  background-position-x: -225px;
  background-position-y: -850px;
}

.emojione-1f596-1f3ff {
  background-position-x: -250px;
  background-position-y: -850px;
}

.emojione-1f596 {
  background-position-x: -275px;
  background-position-y: -850px;
}

.emojione-1f5a4 {
  background-position-x: -300px;
  background-position-y: -850px;
}

.emojione-1f5a5 {
  background-position-x: -325px;
  background-position-y: -850px;
}

.emojione-1f5a8 {
  background-position-x: -350px;
  background-position-y: -850px;
}

.emojione-1f5b1 {
  background-position-x: -375px;
  background-position-y: -850px;
}

.emojione-1f5b2 {
  background-position-x: -400px;
  background-position-y: -850px;
}

.emojione-1f5bc {
  background-position-x: -425px;
  background-position-y: -850px;
}

.emojione-1f5c2 {
  background-position-x: -450px;
  background-position-y: -850px;
}

.emojione-1f5c3 {
  background-position-x: -475px;
  background-position-y: -850px;
}

.emojione-1f5c4 {
  background-position-x: -500px;
  background-position-y: -850px;
}

.emojione-1f5d1 {
  background-position-x: -525px;
  background-position-y: -850px;
}

.emojione-1f5d2 {
  background-position-x: -550px;
  background-position-y: -850px;
}

.emojione-1f5d3 {
  background-position-x: -575px;
  background-position-y: -850px;
}

.emojione-1f5dc {
  background-position-x: -600px;
  background-position-y: -850px;
}

.emojione-1f5dd {
  background-position-x: -625px;
  background-position-y: -850px;
}

.emojione-1f5de {
  background-position-x: -650px;
  background-position-y: -850px;
}

.emojione-1f5e1 {
  background-position-x: -675px;
  background-position-y: -850px;
}

.emojione-1f5e3 {
  background-position-x: -700px;
  background-position-y: -850px;
}

.emojione-1f5e8 {
  background-position-x: -725px;
  background-position-y: -850px;
}

.emojione-1f5ef {
  background-position-x: -750px;
  background-position-y: -850px;
}

.emojione-1f5f3 {
  background-position-x: -775px;
  background-position-y: -850px;
}

.emojione-1f5fa {
  background-position-x: -800px;
  background-position-y: -850px;
}

.emojione-1f5fb {
  background-position-x: -825px;
  background-position-y: -850px;
}

.emojione-1f5fc {
  background-position-x: -850px;
  background-position-y: -850px;
}

.emojione-1f5fd {
  background-position-x: -875px;
  background-position-y: 0px;
}

.emojione-1f5fe {
  background-position-x: -875px;
  background-position-y: -25px;
}

.emojione-1f5ff {
  background-position-x: -875px;
  background-position-y: -50px;
}

.emojione-1f600 {
  background-position-x: -875px;
  background-position-y: -75px;
}

.emojione-1f601 {
  background-position-x: -875px;
  background-position-y: -100px;
}

.emojione-1f602 {
  background-position-x: -875px;
  background-position-y: -125px;
}

.emojione-1f603 {
  background-position-x: -875px;
  background-position-y: -150px;
}

.emojione-1f604 {
  background-position-x: -875px;
  background-position-y: -175px;
}

.emojione-1f605 {
  background-position-x: -875px;
  background-position-y: -200px;
}

.emojione-1f606 {
  background-position-x: -875px;
  background-position-y: -225px;
}

.emojione-1f607 {
  background-position-x: -875px;
  background-position-y: -250px;
}

.emojione-1f608 {
  background-position-x: -875px;
  background-position-y: -275px;
}

.emojione-1f609 {
  background-position-x: -875px;
  background-position-y: -300px;
}

.emojione-1f60a {
  background-position-x: -875px;
  background-position-y: -325px;
}

.emojione-1f60b {
  background-position-x: -875px;
  background-position-y: -350px;
}

.emojione-1f60c {
  background-position-x: -875px;
  background-position-y: -375px;
}

.emojione-1f60d {
  background-position-x: -875px;
  background-position-y: -400px;
}

.emojione-1f60e {
  background-position-x: -875px;
  background-position-y: -425px;
}

.emojione-1f60f {
  background-position-x: -875px;
  background-position-y: -450px;
}

.emojione-1f610 {
  background-position-x: -875px;
  background-position-y: -475px;
}

.emojione-1f611 {
  background-position-x: -875px;
  background-position-y: -500px;
}

.emojione-1f612 {
  background-position-x: -875px;
  background-position-y: -525px;
}

.emojione-1f613 {
  background-position-x: -875px;
  background-position-y: -550px;
}

.emojione-1f614 {
  background-position-x: -875px;
  background-position-y: -575px;
}

.emojione-1f615 {
  background-position-x: -875px;
  background-position-y: -600px;
}

.emojione-1f616 {
  background-position-x: -875px;
  background-position-y: -625px;
}

.emojione-1f617 {
  background-position-x: -875px;
  background-position-y: -650px;
}

.emojione-1f618 {
  background-position-x: -875px;
  background-position-y: -675px;
}

.emojione-1f619 {
  background-position-x: -875px;
  background-position-y: -700px;
}

.emojione-1f61a {
  background-position-x: -875px;
  background-position-y: -725px;
}

.emojione-1f61b {
  background-position-x: -875px;
  background-position-y: -750px;
}

.emojione-1f61c {
  background-position-x: -875px;
  background-position-y: -775px;
}

.emojione-1f61d {
  background-position-x: -875px;
  background-position-y: -800px;
}

.emojione-1f61e {
  background-position-x: -875px;
  background-position-y: -825px;
}

.emojione-1f61f {
  background-position-x: -875px;
  background-position-y: -850px;
}

.emojione-1f620 {
  background-position-x: 0px;
  background-position-y: -875px;
}

.emojione-1f621 {
  background-position-x: -25px;
  background-position-y: -875px;
}

.emojione-1f622 {
  background-position-x: -50px;
  background-position-y: -875px;
}

.emojione-1f623 {
  background-position-x: -75px;
  background-position-y: -875px;
}

.emojione-1f624 {
  background-position-x: -100px;
  background-position-y: -875px;
}

.emojione-1f625 {
  background-position-x: -125px;
  background-position-y: -875px;
}

.emojione-1f626 {
  background-position-x: -150px;
  background-position-y: -875px;
}

.emojione-1f627 {
  background-position-x: -175px;
  background-position-y: -875px;
}

.emojione-1f628 {
  background-position-x: -200px;
  background-position-y: -875px;
}

.emojione-1f629 {
  background-position-x: -225px;
  background-position-y: -875px;
}

.emojione-1f62a {
  background-position-x: -250px;
  background-position-y: -875px;
}

.emojione-1f62b {
  background-position-x: -275px;
  background-position-y: -875px;
}

.emojione-1f62c {
  background-position-x: -300px;
  background-position-y: -875px;
}

.emojione-1f62d {
  background-position-x: -325px;
  background-position-y: -875px;
}

.emojione-1f62e {
  background-position-x: -350px;
  background-position-y: -875px;
}

.emojione-1f62f {
  background-position-x: -375px;
  background-position-y: -875px;
}

.emojione-1f630 {
  background-position-x: -400px;
  background-position-y: -875px;
}

.emojione-1f631 {
  background-position-x: -425px;
  background-position-y: -875px;
}

.emojione-1f632 {
  background-position-x: -450px;
  background-position-y: -875px;
}

.emojione-1f633 {
  background-position-x: -475px;
  background-position-y: -875px;
}

.emojione-1f634 {
  background-position-x: -500px;
  background-position-y: -875px;
}

.emojione-1f635 {
  background-position-x: -525px;
  background-position-y: -875px;
}

.emojione-1f636 {
  background-position-x: -550px;
  background-position-y: -875px;
}

.emojione-1f637 {
  background-position-x: -575px;
  background-position-y: -875px;
}

.emojione-1f638 {
  background-position-x: -600px;
  background-position-y: -875px;
}

.emojione-1f639 {
  background-position-x: -625px;
  background-position-y: -875px;
}

.emojione-1f63a {
  background-position-x: -650px;
  background-position-y: -875px;
}

.emojione-1f63b {
  background-position-x: -675px;
  background-position-y: -875px;
}

.emojione-1f63c {
  background-position-x: -700px;
  background-position-y: -875px;
}

.emojione-1f63d {
  background-position-x: -725px;
  background-position-y: -875px;
}

.emojione-1f63e {
  background-position-x: -750px;
  background-position-y: -875px;
}

.emojione-1f63f {
  background-position-x: -775px;
  background-position-y: -875px;
}

.emojione-1f640 {
  background-position-x: -800px;
  background-position-y: -875px;
}

.emojione-1f641 {
  background-position-x: -825px;
  background-position-y: -875px;
}

.emojione-1f642 {
  background-position-x: -850px;
  background-position-y: -875px;
}

.emojione-1f643 {
  background-position-x: -875px;
  background-position-y: -875px;
}

.emojione-1f644 {
  background-position-x: -900px;
  background-position-y: 0px;
}

.emojione-1f645-1f3fb {
  background-position-x: -900px;
  background-position-y: -25px;
}

.emojione-1f645-1f3fc {
  background-position-x: -900px;
  background-position-y: -50px;
}

.emojione-1f645-1f3fd {
  background-position-x: -900px;
  background-position-y: -75px;
}

.emojione-1f645-1f3fe {
  background-position-x: -900px;
  background-position-y: -100px;
}

.emojione-1f645-1f3ff {
  background-position-x: -900px;
  background-position-y: -125px;
}

.emojione-1f645 {
  background-position-x: -900px;
  background-position-y: -150px;
}

.emojione-1f646-1f3fb {
  background-position-x: -900px;
  background-position-y: -175px;
}

.emojione-1f646-1f3fc {
  background-position-x: -900px;
  background-position-y: -200px;
}

.emojione-1f646-1f3fd {
  background-position-x: -900px;
  background-position-y: -225px;
}

.emojione-1f646-1f3fe {
  background-position-x: -900px;
  background-position-y: -250px;
}

.emojione-1f646-1f3ff {
  background-position-x: -900px;
  background-position-y: -275px;
}

.emojione-1f646 {
  background-position-x: -900px;
  background-position-y: -300px;
}

.emojione-1f647-1f3fb {
  background-position-x: -900px;
  background-position-y: -325px;
}

.emojione-1f647-1f3fc {
  background-position-x: -900px;
  background-position-y: -350px;
}

.emojione-1f647-1f3fd {
  background-position-x: -900px;
  background-position-y: -375px;
}

.emojione-1f647-1f3fe {
  background-position-x: -900px;
  background-position-y: -400px;
}

.emojione-1f647-1f3ff {
  background-position-x: -900px;
  background-position-y: -425px;
}

.emojione-1f647 {
  background-position-x: -900px;
  background-position-y: -450px;
}

.emojione-1f648 {
  background-position-x: -900px;
  background-position-y: -475px;
}

.emojione-1f649 {
  background-position-x: -900px;
  background-position-y: -500px;
}

.emojione-1f64a {
  background-position-x: -900px;
  background-position-y: -525px;
}

.emojione-1f64b-1f3fb {
  background-position-x: -900px;
  background-position-y: -550px;
}

.emojione-1f64b-1f3fc {
  background-position-x: -900px;
  background-position-y: -575px;
}

.emojione-1f64b-1f3fd {
  background-position-x: -900px;
  background-position-y: -600px;
}

.emojione-1f64b-1f3fe {
  background-position-x: -900px;
  background-position-y: -625px;
}

.emojione-1f64b-1f3ff {
  background-position-x: -900px;
  background-position-y: -650px;
}

.emojione-1f64b {
  background-position-x: -900px;
  background-position-y: -675px;
}

.emojione-1f64c-1f3fb {
  background-position-x: -900px;
  background-position-y: -700px;
}

.emojione-1f64c-1f3fc {
  background-position-x: -900px;
  background-position-y: -725px;
}

.emojione-1f64c-1f3fd {
  background-position-x: -900px;
  background-position-y: -750px;
}

.emojione-1f64c-1f3fe {
  background-position-x: -900px;
  background-position-y: -775px;
}

.emojione-1f64c-1f3ff {
  background-position-x: -900px;
  background-position-y: -800px;
}

.emojione-1f64c {
  background-position-x: -900px;
  background-position-y: -825px;
}

.emojione-1f64d-1f3fb {
  background-position-x: -900px;
  background-position-y: -850px;
}

.emojione-1f64d-1f3fc {
  background-position-x: -900px;
  background-position-y: -875px;
}

.emojione-1f64d-1f3fd {
  background-position-x: 0px;
  background-position-y: -900px;
}

.emojione-1f64d-1f3fe {
  background-position-x: -25px;
  background-position-y: -900px;
}

.emojione-1f64d-1f3ff {
  background-position-x: -50px;
  background-position-y: -900px;
}

.emojione-1f64d {
  background-position-x: -75px;
  background-position-y: -900px;
}

.emojione-1f64e-1f3fb {
  background-position-x: -100px;
  background-position-y: -900px;
}

.emojione-1f64e-1f3fc {
  background-position-x: -125px;
  background-position-y: -900px;
}

.emojione-1f64e-1f3fd {
  background-position-x: -150px;
  background-position-y: -900px;
}

.emojione-1f64e-1f3fe {
  background-position-x: -175px;
  background-position-y: -900px;
}

.emojione-1f64e-1f3ff {
  background-position-x: -200px;
  background-position-y: -900px;
}

.emojione-1f64e {
  background-position-x: -225px;
  background-position-y: -900px;
}

.emojione-1f64f-1f3fb {
  background-position-x: -250px;
  background-position-y: -900px;
}

.emojione-1f64f-1f3fc {
  background-position-x: -275px;
  background-position-y: -900px;
}

.emojione-1f64f-1f3fd {
  background-position-x: -300px;
  background-position-y: -900px;
}

.emojione-1f64f-1f3fe {
  background-position-x: -325px;
  background-position-y: -900px;
}

.emojione-1f64f-1f3ff {
  background-position-x: -350px;
  background-position-y: -900px;
}

.emojione-1f64f {
  background-position-x: -375px;
  background-position-y: -900px;
}

.emojione-1f680 {
  background-position-x: -400px;
  background-position-y: -900px;
}

.emojione-1f681 {
  background-position-x: -425px;
  background-position-y: -900px;
}

.emojione-1f682 {
  background-position-x: -450px;
  background-position-y: -900px;
}

.emojione-1f683 {
  background-position-x: -475px;
  background-position-y: -900px;
}

.emojione-1f684 {
  background-position-x: -500px;
  background-position-y: -900px;
}

.emojione-1f685 {
  background-position-x: -525px;
  background-position-y: -900px;
}

.emojione-1f686 {
  background-position-x: -550px;
  background-position-y: -900px;
}

.emojione-1f687 {
  background-position-x: -575px;
  background-position-y: -900px;
}

.emojione-1f688 {
  background-position-x: -600px;
  background-position-y: -900px;
}

.emojione-1f689 {
  background-position-x: -625px;
  background-position-y: -900px;
}

.emojione-1f68a {
  background-position-x: -650px;
  background-position-y: -900px;
}

.emojione-1f68b {
  background-position-x: -675px;
  background-position-y: -900px;
}

.emojione-1f68c {
  background-position-x: -700px;
  background-position-y: -900px;
}

.emojione-1f68d {
  background-position-x: -725px;
  background-position-y: -900px;
}

.emojione-1f68e {
  background-position-x: -750px;
  background-position-y: -900px;
}

.emojione-1f68f {
  background-position-x: -775px;
  background-position-y: -900px;
}

.emojione-1f690 {
  background-position-x: -800px;
  background-position-y: -900px;
}

.emojione-1f691 {
  background-position-x: -825px;
  background-position-y: -900px;
}

.emojione-1f692 {
  background-position-x: -850px;
  background-position-y: -900px;
}

.emojione-1f693 {
  background-position-x: -875px;
  background-position-y: -900px;
}

.emojione-1f694 {
  background-position-x: -900px;
  background-position-y: -900px;
}

.emojione-1f695 {
  background-position-x: -925px;
  background-position-y: 0px;
}

.emojione-1f696 {
  background-position-x: -925px;
  background-position-y: -25px;
}

.emojione-1f697 {
  background-position-x: -925px;
  background-position-y: -50px;
}

.emojione-1f698 {
  background-position-x: -925px;
  background-position-y: -75px;
}

.emojione-1f699 {
  background-position-x: -925px;
  background-position-y: -100px;
}

.emojione-1f69a {
  background-position-x: -925px;
  background-position-y: -125px;
}

.emojione-1f69b {
  background-position-x: -925px;
  background-position-y: -150px;
}

.emojione-1f69c {
  background-position-x: -925px;
  background-position-y: -175px;
}

.emojione-1f69d {
  background-position-x: -925px;
  background-position-y: -200px;
}

.emojione-1f69e {
  background-position-x: -925px;
  background-position-y: -225px;
}

.emojione-1f69f {
  background-position-x: -925px;
  background-position-y: -250px;
}

.emojione-1f6a0 {
  background-position-x: -925px;
  background-position-y: -275px;
}

.emojione-1f6a1 {
  background-position-x: -925px;
  background-position-y: -300px;
}

.emojione-1f6a2 {
  background-position-x: -925px;
  background-position-y: -325px;
}

.emojione-1f6a3-1f3fb {
  background-position-x: -925px;
  background-position-y: -350px;
}

.emojione-1f6a3-1f3fc {
  background-position-x: -925px;
  background-position-y: -375px;
}

.emojione-1f6a3-1f3fd {
  background-position-x: -925px;
  background-position-y: -400px;
}

.emojione-1f6a3-1f3fe {
  background-position-x: -925px;
  background-position-y: -425px;
}

.emojione-1f6a3-1f3ff {
  background-position-x: -925px;
  background-position-y: -450px;
}

.emojione-1f6a3 {
  background-position-x: -925px;
  background-position-y: -475px;
}

.emojione-1f6a4 {
  background-position-x: -925px;
  background-position-y: -500px;
}

.emojione-1f6a5 {
  background-position-x: -925px;
  background-position-y: -525px;
}

.emojione-1f6a6 {
  background-position-x: -925px;
  background-position-y: -550px;
}

.emojione-1f6a7 {
  background-position-x: -925px;
  background-position-y: -575px;
}

.emojione-1f6a8 {
  background-position-x: -925px;
  background-position-y: -600px;
}

.emojione-1f6a9 {
  background-position-x: -925px;
  background-position-y: -625px;
}

.emojione-1f6aa {
  background-position-x: -925px;
  background-position-y: -650px;
}

.emojione-1f6ab {
  background-position-x: -925px;
  background-position-y: -675px;
}

.emojione-1f6ac {
  background-position-x: -925px;
  background-position-y: -700px;
}

.emojione-1f6ad {
  background-position-x: -925px;
  background-position-y: -725px;
}

.emojione-1f6ae {
  background-position-x: -925px;
  background-position-y: -750px;
}

.emojione-1f6af {
  background-position-x: -925px;
  background-position-y: -775px;
}

.emojione-1f6b0 {
  background-position-x: -925px;
  background-position-y: -800px;
}

.emojione-1f6b1 {
  background-position-x: -925px;
  background-position-y: -825px;
}

.emojione-1f6b2 {
  background-position-x: -925px;
  background-position-y: -850px;
}

.emojione-1f6b3 {
  background-position-x: -925px;
  background-position-y: -875px;
}

.emojione-1f6b4-1f3fb {
  background-position-x: -925px;
  background-position-y: -900px;
}

.emojione-1f6b4-1f3fc {
  background-position-x: 0px;
  background-position-y: -925px;
}

.emojione-1f6b4-1f3fd {
  background-position-x: -25px;
  background-position-y: -925px;
}

.emojione-1f6b4-1f3fe {
  background-position-x: -50px;
  background-position-y: -925px;
}

.emojione-1f6b4-1f3ff {
  background-position-x: -75px;
  background-position-y: -925px;
}

.emojione-1f6b4 {
  background-position-x: -100px;
  background-position-y: -925px;
}

.emojione-1f6b5-1f3fb {
  background-position-x: -125px;
  background-position-y: -925px;
}

.emojione-1f6b5-1f3fc {
  background-position-x: -150px;
  background-position-y: -925px;
}

.emojione-1f6b5-1f3fd {
  background-position-x: -175px;
  background-position-y: -925px;
}

.emojione-1f6b5-1f3fe {
  background-position-x: -200px;
  background-position-y: -925px;
}

.emojione-1f6b5-1f3ff {
  background-position-x: -225px;
  background-position-y: -925px;
}

.emojione-1f6b5 {
  background-position-x: -250px;
  background-position-y: -925px;
}

.emojione-1f6b6-1f3fb {
  background-position-x: -275px;
  background-position-y: -925px;
}

.emojione-1f6b6-1f3fc {
  background-position-x: -300px;
  background-position-y: -925px;
}

.emojione-1f6b6-1f3fd {
  background-position-x: -325px;
  background-position-y: -925px;
}

.emojione-1f6b6-1f3fe {
  background-position-x: -350px;
  background-position-y: -925px;
}

.emojione-1f6b6-1f3ff {
  background-position-x: -375px;
  background-position-y: -925px;
}

.emojione-1f6b6 {
  background-position-x: -400px;
  background-position-y: -925px;
}

.emojione-1f6b7 {
  background-position-x: -425px;
  background-position-y: -925px;
}

.emojione-1f6b8 {
  background-position-x: -450px;
  background-position-y: -925px;
}

.emojione-1f6b9 {
  background-position-x: -475px;
  background-position-y: -925px;
}

.emojione-1f6ba {
  background-position-x: -500px;
  background-position-y: -925px;
}

.emojione-1f6bb {
  background-position-x: -525px;
  background-position-y: -925px;
}

.emojione-1f6bc {
  background-position-x: -550px;
  background-position-y: -925px;
}

.emojione-1f6bd {
  background-position-x: -575px;
  background-position-y: -925px;
}

.emojione-1f6be {
  background-position-x: -600px;
  background-position-y: -925px;
}

.emojione-1f6bf {
  background-position-x: -625px;
  background-position-y: -925px;
}

.emojione-1f6c0-1f3fb {
  background-position-x: -650px;
  background-position-y: -925px;
}

.emojione-1f6c0-1f3fc {
  background-position-x: -675px;
  background-position-y: -925px;
}

.emojione-1f6c0-1f3fd {
  background-position-x: -700px;
  background-position-y: -925px;
}

.emojione-1f6c0-1f3fe {
  background-position-x: -725px;
  background-position-y: -925px;
}

.emojione-1f6c0-1f3ff {
  background-position-x: -750px;
  background-position-y: -925px;
}

.emojione-1f6c0 {
  background-position-x: -775px;
  background-position-y: -925px;
}

.emojione-1f6c1 {
  background-position-x: -800px;
  background-position-y: -925px;
}

.emojione-1f6c2 {
  background-position-x: -825px;
  background-position-y: -925px;
}

.emojione-1f6c3 {
  background-position-x: -850px;
  background-position-y: -925px;
}

.emojione-1f6c4 {
  background-position-x: -875px;
  background-position-y: -925px;
}

.emojione-1f6c5 {
  background-position-x: -900px;
  background-position-y: -925px;
}

.emojione-1f6cb {
  background-position-x: -925px;
  background-position-y: -925px;
}

.emojione-1f6cc {
  background-position-x: -950px;
  background-position-y: 0px;
}

.emojione-1f6cd {
  background-position-x: -950px;
  background-position-y: -25px;
}

.emojione-1f6ce {
  background-position-x: -950px;
  background-position-y: -50px;
}

.emojione-1f6cf {
  background-position-x: -950px;
  background-position-y: -75px;
}

.emojione-1f6d0 {
  background-position-x: -950px;
  background-position-y: -100px;
}

.emojione-1f6d1 {
  background-position-x: -950px;
  background-position-y: -125px;
}

.emojione-1f6d2 {
  background-position-x: -950px;
  background-position-y: -150px;
}

.emojione-1f6e0 {
  background-position-x: -950px;
  background-position-y: -175px;
}

.emojione-1f6e1 {
  background-position-x: -950px;
  background-position-y: -200px;
}

.emojione-1f6e2 {
  background-position-x: -950px;
  background-position-y: -225px;
}

.emojione-1f6e3 {
  background-position-x: -950px;
  background-position-y: -250px;
}

.emojione-1f6e4 {
  background-position-x: -950px;
  background-position-y: -275px;
}

.emojione-1f6e5 {
  background-position-x: -950px;
  background-position-y: -300px;
}

.emojione-1f6e9 {
  background-position-x: -950px;
  background-position-y: -325px;
}

.emojione-1f6eb {
  background-position-x: -950px;
  background-position-y: -350px;
}

.emojione-1f6ec {
  background-position-x: -950px;
  background-position-y: -375px;
}

.emojione-1f6f0 {
  background-position-x: -950px;
  background-position-y: -400px;
}

.emojione-1f6f3 {
  background-position-x: -950px;
  background-position-y: -425px;
}

.emojione-1f6f4 {
  background-position-x: -950px;
  background-position-y: -450px;
}

.emojione-1f6f5 {
  background-position-x: -950px;
  background-position-y: -475px;
}

.emojione-1f6f6 {
  background-position-x: -950px;
  background-position-y: -500px;
}

.emojione-1f910 {
  background-position-x: -950px;
  background-position-y: -525px;
}

.emojione-1f911 {
  background-position-x: -950px;
  background-position-y: -550px;
}

.emojione-1f912 {
  background-position-x: -950px;
  background-position-y: -575px;
}

.emojione-1f913 {
  background-position-x: -950px;
  background-position-y: -600px;
}

.emojione-1f914 {
  background-position-x: -950px;
  background-position-y: -625px;
}

.emojione-1f915 {
  background-position-x: -950px;
  background-position-y: -650px;
}

.emojione-1f916 {
  background-position-x: -950px;
  background-position-y: -675px;
}

.emojione-1f917 {
  background-position-x: -950px;
  background-position-y: -700px;
}

.emojione-1f918-1f3fb {
  background-position-x: -950px;
  background-position-y: -725px;
}

.emojione-1f918-1f3fc {
  background-position-x: -950px;
  background-position-y: -750px;
}

.emojione-1f918-1f3fd {
  background-position-x: -950px;
  background-position-y: -775px;
}

.emojione-1f918-1f3fe {
  background-position-x: -950px;
  background-position-y: -800px;
}

.emojione-1f918-1f3ff {
  background-position-x: -950px;
  background-position-y: -825px;
}

.emojione-1f918 {
  background-position-x: -950px;
  background-position-y: -850px;
}

.emojione-1f919-1f3fb {
  background-position-x: -950px;
  background-position-y: -875px;
}

.emojione-1f919-1f3fc {
  background-position-x: -950px;
  background-position-y: -900px;
}

.emojione-1f919-1f3fd {
  background-position-x: -950px;
  background-position-y: -925px;
}

.emojione-1f919-1f3fe {
  background-position-x: 0px;
  background-position-y: -950px;
}

.emojione-1f919-1f3ff {
  background-position-x: -25px;
  background-position-y: -950px;
}

.emojione-1f919 {
  background-position-x: -50px;
  background-position-y: -950px;
}

.emojione-1f91a-1f3fb {
  background-position-x: -75px;
  background-position-y: -950px;
}

.emojione-1f91a-1f3fc {
  background-position-x: -100px;
  background-position-y: -950px;
}

.emojione-1f91a-1f3fd {
  background-position-x: -125px;
  background-position-y: -950px;
}

.emojione-1f91a-1f3fe {
  background-position-x: -150px;
  background-position-y: -950px;
}

.emojione-1f91a-1f3ff {
  background-position-x: -175px;
  background-position-y: -950px;
}

.emojione-1f91a {
  background-position-x: -200px;
  background-position-y: -950px;
}

.emojione-1f91b-1f3fb {
  background-position-x: -225px;
  background-position-y: -950px;
}

.emojione-1f91b-1f3fc {
  background-position-x: -250px;
  background-position-y: -950px;
}

.emojione-1f91b-1f3fd {
  background-position-x: -275px;
  background-position-y: -950px;
}

.emojione-1f91b-1f3fe {
  background-position-x: -300px;
  background-position-y: -950px;
}

.emojione-1f91b-1f3ff {
  background-position-x: -325px;
  background-position-y: -950px;
}

.emojione-1f91b {
  background-position-x: -350px;
  background-position-y: -950px;
}

.emojione-1f91c-1f3fb {
  background-position-x: -375px;
  background-position-y: -950px;
}

.emojione-1f91c-1f3fc {
  background-position-x: -400px;
  background-position-y: -950px;
}

.emojione-1f91c-1f3fd {
  background-position-x: -425px;
  background-position-y: -950px;
}

.emojione-1f91c-1f3fe {
  background-position-x: -450px;
  background-position-y: -950px;
}

.emojione-1f91c-1f3ff {
  background-position-x: -475px;
  background-position-y: -950px;
}

.emojione-1f91c {
  background-position-x: -500px;
  background-position-y: -950px;
}

.emojione-1f91d-1f3fb {
  background-position-x: -525px;
  background-position-y: -950px;
}

.emojione-1f91d-1f3fc {
  background-position-x: -550px;
  background-position-y: -950px;
}

.emojione-1f91d-1f3fd {
  background-position-x: -575px;
  background-position-y: -950px;
}

.emojione-1f91d-1f3fe {
  background-position-x: -600px;
  background-position-y: -950px;
}

.emojione-1f91d-1f3ff {
  background-position-x: -625px;
  background-position-y: -950px;
}

.emojione-1f91d {
  background-position-x: -650px;
  background-position-y: -950px;
}

.emojione-1f91e-1f3fb {
  background-position-x: -675px;
  background-position-y: -950px;
}

.emojione-1f91e-1f3fc {
  background-position-x: -700px;
  background-position-y: -950px;
}

.emojione-1f91e-1f3fd {
  background-position-x: -725px;
  background-position-y: -950px;
}

.emojione-1f91e-1f3fe {
  background-position-x: -750px;
  background-position-y: -950px;
}

.emojione-1f91e-1f3ff {
  background-position-x: -775px;
  background-position-y: -950px;
}

.emojione-1f91e {
  background-position-x: -800px;
  background-position-y: -950px;
}

.emojione-1f920 {
  background-position-x: -825px;
  background-position-y: -950px;
}

.emojione-1f921 {
  background-position-x: -850px;
  background-position-y: -950px;
}

.emojione-1f922 {
  background-position-x: -875px;
  background-position-y: -950px;
}

.emojione-1f923 {
  background-position-x: -900px;
  background-position-y: -950px;
}

.emojione-1f924 {
  background-position-x: -925px;
  background-position-y: -950px;
}

.emojione-1f925 {
  background-position-x: -950px;
  background-position-y: -950px;
}

.emojione-1f926-1f3fb {
  background-position-x: -975px;
  background-position-y: 0px;
}

.emojione-1f926-1f3fc {
  background-position-x: -975px;
  background-position-y: -25px;
}

.emojione-1f926-1f3fd {
  background-position-x: -975px;
  background-position-y: -50px;
}

.emojione-1f926-1f3fe {
  background-position-x: -975px;
  background-position-y: -75px;
}

.emojione-1f926-1f3ff {
  background-position-x: -975px;
  background-position-y: -100px;
}

.emojione-1f926 {
  background-position-x: -975px;
  background-position-y: -125px;
}

.emojione-1f927 {
  background-position-x: -975px;
  background-position-y: -150px;
}

.emojione-1f930-1f3fb {
  background-position-x: -975px;
  background-position-y: -175px;
}

.emojione-1f930-1f3fc {
  background-position-x: -975px;
  background-position-y: -200px;
}

.emojione-1f930-1f3fd {
  background-position-x: -975px;
  background-position-y: -225px;
}

.emojione-1f930-1f3fe {
  background-position-x: -975px;
  background-position-y: -250px;
}

.emojione-1f930-1f3ff {
  background-position-x: -975px;
  background-position-y: -275px;
}

.emojione-1f930 {
  background-position-x: -975px;
  background-position-y: -300px;
}

.emojione-1f933-1f3fb {
  background-position-x: -975px;
  background-position-y: -325px;
}

.emojione-1f933-1f3fc {
  background-position-x: -975px;
  background-position-y: -350px;
}

.emojione-1f933-1f3fd {
  background-position-x: -975px;
  background-position-y: -375px;
}

.emojione-1f933-1f3fe {
  background-position-x: -975px;
  background-position-y: -400px;
}

.emojione-1f933-1f3ff {
  background-position-x: -975px;
  background-position-y: -425px;
}

.emojione-1f933 {
  background-position-x: -975px;
  background-position-y: -450px;
}

.emojione-1f934-1f3fb {
  background-position-x: -975px;
  background-position-y: -475px;
}

.emojione-1f934-1f3fc {
  background-position-x: -975px;
  background-position-y: -500px;
}

.emojione-1f934-1f3fd {
  background-position-x: -975px;
  background-position-y: -525px;
}

.emojione-1f934-1f3fe {
  background-position-x: -975px;
  background-position-y: -550px;
}

.emojione-1f934-1f3ff {
  background-position-x: -975px;
  background-position-y: -575px;
}

.emojione-1f934 {
  background-position-x: -975px;
  background-position-y: -600px;
}

.emojione-1f935-1f3fb {
  background-position-x: -975px;
  background-position-y: -625px;
}

.emojione-1f935-1f3fc {
  background-position-x: -975px;
  background-position-y: -650px;
}

.emojione-1f935-1f3fd {
  background-position-x: -975px;
  background-position-y: -675px;
}

.emojione-1f935-1f3fe {
  background-position-x: -975px;
  background-position-y: -700px;
}

.emojione-1f935-1f3ff {
  background-position-x: -975px;
  background-position-y: -725px;
}

.emojione-1f935 {
  background-position-x: -975px;
  background-position-y: -750px;
}

.emojione-1f936-1f3fb {
  background-position-x: -975px;
  background-position-y: -775px;
}

.emojione-1f936-1f3fc {
  background-position-x: -975px;
  background-position-y: -800px;
}

.emojione-1f936-1f3fd {
  background-position-x: -975px;
  background-position-y: -825px;
}

.emojione-1f936-1f3fe {
  background-position-x: -975px;
  background-position-y: -850px;
}

.emojione-1f936-1f3ff {
  background-position-x: -975px;
  background-position-y: -875px;
}

.emojione-1f936 {
  background-position-x: -975px;
  background-position-y: -900px;
}

.emojione-1f937-1f3fb {
  background-position-x: -975px;
  background-position-y: -925px;
}

.emojione-1f937-1f3fc {
  background-position-x: -975px;
  background-position-y: -950px;
}

.emojione-1f937-1f3fd {
  background-position-x: 0px;
  background-position-y: -975px;
}

.emojione-1f937-1f3fe {
  background-position-x: -25px;
  background-position-y: -975px;
}

.emojione-1f937-1f3ff {
  background-position-x: -50px;
  background-position-y: -975px;
}

.emojione-1f937 {
  background-position-x: -75px;
  background-position-y: -975px;
}

.emojione-1f938-1f3fb {
  background-position-x: -100px;
  background-position-y: -975px;
}

.emojione-1f938-1f3fc {
  background-position-x: -125px;
  background-position-y: -975px;
}

.emojione-1f938-1f3fd {
  background-position-x: -150px;
  background-position-y: -975px;
}

.emojione-1f938-1f3fe {
  background-position-x: -175px;
  background-position-y: -975px;
}

.emojione-1f938-1f3ff {
  background-position-x: -200px;
  background-position-y: -975px;
}

.emojione-1f938 {
  background-position-x: -225px;
  background-position-y: -975px;
}

.emojione-1f939-1f3fb {
  background-position-x: -250px;
  background-position-y: -975px;
}

.emojione-1f939-1f3fc {
  background-position-x: -275px;
  background-position-y: -975px;
}

.emojione-1f939-1f3fd {
  background-position-x: -300px;
  background-position-y: -975px;
}

.emojione-1f939-1f3fe {
  background-position-x: -325px;
  background-position-y: -975px;
}

.emojione-1f939-1f3ff {
  background-position-x: -350px;
  background-position-y: -975px;
}

.emojione-1f939 {
  background-position-x: -375px;
  background-position-y: -975px;
}

.emojione-1f93a {
  background-position-x: -400px;
  background-position-y: -975px;
}

.emojione-1f93c-1f3fb {
  background-position-x: -425px;
  background-position-y: -975px;
}

.emojione-1f93c-1f3fc {
  background-position-x: -450px;
  background-position-y: -975px;
}

.emojione-1f93c-1f3fd {
  background-position-x: -475px;
  background-position-y: -975px;
}

.emojione-1f93c-1f3fe {
  background-position-x: -500px;
  background-position-y: -975px;
}

.emojione-1f93c-1f3ff {
  background-position-x: -525px;
  background-position-y: -975px;
}

.emojione-1f93c {
  background-position-x: -550px;
  background-position-y: -975px;
}

.emojione-1f93d-1f3fb {
  background-position-x: -575px;
  background-position-y: -975px;
}

.emojione-1f93d-1f3fc {
  background-position-x: -600px;
  background-position-y: -975px;
}

.emojione-1f93d-1f3fd {
  background-position-x: -625px;
  background-position-y: -975px;
}

.emojione-1f93d-1f3fe {
  background-position-x: -650px;
  background-position-y: -975px;
}

.emojione-1f93d-1f3ff {
  background-position-x: -675px;
  background-position-y: -975px;
}

.emojione-1f93d {
  background-position-x: -700px;
  background-position-y: -975px;
}

.emojione-1f93e-1f3fb {
  background-position-x: -725px;
  background-position-y: -975px;
}

.emojione-1f93e-1f3fc {
  background-position-x: -750px;
  background-position-y: -975px;
}

.emojione-1f93e-1f3fd {
  background-position-x: -775px;
  background-position-y: -975px;
}

.emojione-1f93e-1f3fe {
  background-position-x: -800px;
  background-position-y: -975px;
}

.emojione-1f93e-1f3ff {
  background-position-x: -825px;
  background-position-y: -975px;
}

.emojione-1f93e {
  background-position-x: -850px;
  background-position-y: -975px;
}

.emojione-1f940 {
  background-position-x: -875px;
  background-position-y: -975px;
}

.emojione-1f941 {
  background-position-x: -900px;
  background-position-y: -975px;
}

.emojione-1f942 {
  background-position-x: -925px;
  background-position-y: -975px;
}

.emojione-1f943 {
  background-position-x: -950px;
  background-position-y: -975px;
}

.emojione-1f944 {
  background-position-x: -975px;
  background-position-y: -975px;
}

.emojione-1f945 {
  background-position-x: -1000px;
  background-position-y: 0px;
}

.emojione-1f947 {
  background-position-x: -1000px;
  background-position-y: -25px;
}

.emojione-1f948 {
  background-position-x: -1000px;
  background-position-y: -50px;
}

.emojione-1f949 {
  background-position-x: -1000px;
  background-position-y: -75px;
}

.emojione-1f94a {
  background-position-x: -1000px;
  background-position-y: -100px;
}

.emojione-1f94b {
  background-position-x: -1000px;
  background-position-y: -125px;
}

.emojione-1f950 {
  background-position-x: -1000px;
  background-position-y: -150px;
}

.emojione-1f951 {
  background-position-x: -1000px;
  background-position-y: -175px;
}

.emojione-1f952 {
  background-position-x: -1000px;
  background-position-y: -200px;
}

.emojione-1f953 {
  background-position-x: -1000px;
  background-position-y: -225px;
}

.emojione-1f954 {
  background-position-x: -1000px;
  background-position-y: -250px;
}

.emojione-1f955 {
  background-position-x: -1000px;
  background-position-y: -275px;
}

.emojione-1f956 {
  background-position-x: -1000px;
  background-position-y: -300px;
}

.emojione-1f957 {
  background-position-x: -1000px;
  background-position-y: -325px;
}

.emojione-1f958 {
  background-position-x: -1000px;
  background-position-y: -350px;
}

.emojione-1f959 {
  background-position-x: -1000px;
  background-position-y: -375px;
}

.emojione-1f95a {
  background-position-x: -1000px;
  background-position-y: -400px;
}

.emojione-1f95b {
  background-position-x: -1000px;
  background-position-y: -425px;
}

.emojione-1f95c {
  background-position-x: -1000px;
  background-position-y: -450px;
}

.emojione-1f95d {
  background-position-x: -1000px;
  background-position-y: -475px;
}

.emojione-1f95e {
  background-position-x: -1000px;
  background-position-y: -500px;
}

.emojione-1f980 {
  background-position-x: -1000px;
  background-position-y: -525px;
}

.emojione-1f981 {
  background-position-x: -1000px;
  background-position-y: -550px;
}

.emojione-1f982 {
  background-position-x: -1000px;
  background-position-y: -575px;
}

.emojione-1f983 {
  background-position-x: -1000px;
  background-position-y: -600px;
}

.emojione-1f984 {
  background-position-x: -1000px;
  background-position-y: -625px;
}

.emojione-1f985 {
  background-position-x: -1000px;
  background-position-y: -650px;
}

.emojione-1f986 {
  background-position-x: -1000px;
  background-position-y: -675px;
}

.emojione-1f987 {
  background-position-x: -1000px;
  background-position-y: -700px;
}

.emojione-1f988 {
  background-position-x: -1000px;
  background-position-y: -725px;
}

.emojione-1f989 {
  background-position-x: -1000px;
  background-position-y: -750px;
}

.emojione-1f98a {
  background-position-x: -1000px;
  background-position-y: -775px;
}

.emojione-1f98b {
  background-position-x: -1000px;
  background-position-y: -800px;
}

.emojione-1f98c {
  background-position-x: -1000px;
  background-position-y: -825px;
}

.emojione-1f98d {
  background-position-x: -1000px;
  background-position-y: -850px;
}

.emojione-1f98e {
  background-position-x: -1000px;
  background-position-y: -875px;
}

.emojione-1f98f {
  background-position-x: -1000px;
  background-position-y: -900px;
}

.emojione-1f990 {
  background-position-x: -1000px;
  background-position-y: -925px;
}

.emojione-1f991 {
  background-position-x: -1000px;
  background-position-y: -950px;
}

.emojione-1f9c0 {
  background-position-x: -1000px;
  background-position-y: -975px;
}

.emojione-203c {
  background-position-x: 0px;
  background-position-y: -1000px;
}

.emojione-2049 {
  background-position-x: -25px;
  background-position-y: -1000px;
}

.emojione-2122 {
  background-position-x: -50px;
  background-position-y: -1000px;
}

.emojione-2139 {
  background-position-x: -75px;
  background-position-y: -1000px;
}

.emojione-2194 {
  background-position-x: -100px;
  background-position-y: -1000px;
}

.emojione-2195 {
  background-position-x: -125px;
  background-position-y: -1000px;
}

.emojione-2196 {
  background-position-x: -150px;
  background-position-y: -1000px;
}

.emojione-2197 {
  background-position-x: -175px;
  background-position-y: -1000px;
}

.emojione-2198 {
  background-position-x: -200px;
  background-position-y: -1000px;
}

.emojione-2199 {
  background-position-x: -225px;
  background-position-y: -1000px;
}

.emojione-21a9 {
  background-position-x: -250px;
  background-position-y: -1000px;
}

.emojione-21aa {
  background-position-x: -275px;
  background-position-y: -1000px;
}

.emojione-231a {
  background-position-x: -300px;
  background-position-y: -1000px;
}

.emojione-231b {
  background-position-x: -325px;
  background-position-y: -1000px;
}

.emojione-2328 {
  background-position-x: -350px;
  background-position-y: -1000px;
}

.emojione-23cf {
  background-position-x: -375px;
  background-position-y: -1000px;
}

.emojione-23e9 {
  background-position-x: -400px;
  background-position-y: -1000px;
}

.emojione-23ea {
  background-position-x: -425px;
  background-position-y: -1000px;
}

.emojione-23eb {
  background-position-x: -450px;
  background-position-y: -1000px;
}

.emojione-23ec {
  background-position-x: -475px;
  background-position-y: -1000px;
}

.emojione-23ed {
  background-position-x: -500px;
  background-position-y: -1000px;
}

.emojione-23ee {
  background-position-x: -525px;
  background-position-y: -1000px;
}

.emojione-23ef {
  background-position-x: -550px;
  background-position-y: -1000px;
}

.emojione-23f0 {
  background-position-x: -575px;
  background-position-y: -1000px;
}

.emojione-23f1 {
  background-position-x: -600px;
  background-position-y: -1000px;
}

.emojione-23f2 {
  background-position-x: -625px;
  background-position-y: -1000px;
}

.emojione-23f3 {
  background-position-x: -650px;
  background-position-y: -1000px;
}

.emojione-23f8 {
  background-position-x: -675px;
  background-position-y: -1000px;
}

.emojione-23f9 {
  background-position-x: -700px;
  background-position-y: -1000px;
}

.emojione-23fa {
  background-position-x: -725px;
  background-position-y: -1000px;
}

.emojione-24c2 {
  background-position-x: -750px;
  background-position-y: -1000px;
}

.emojione-25aa {
  background-position-x: -775px;
  background-position-y: -1000px;
}

.emojione-25ab {
  background-position-x: -800px;
  background-position-y: -1000px;
}

.emojione-25b6 {
  background-position-x: -825px;
  background-position-y: -1000px;
}

.emojione-25c0 {
  background-position-x: -850px;
  background-position-y: -1000px;
}

.emojione-25fb {
  background-position-x: -875px;
  background-position-y: -1000px;
}

.emojione-25fc {
  background-position-x: -900px;
  background-position-y: -1000px;
}

.emojione-25fd {
  background-position-x: -925px;
  background-position-y: -1000px;
}

.emojione-25fe {
  background-position-x: -950px;
  background-position-y: -1000px;
}

.emojione-2600 {
  background-position-x: -975px;
  background-position-y: -1000px;
}

.emojione-2601 {
  background-position-x: -1000px;
  background-position-y: -1000px;
}

.emojione-2602 {
  background-position-x: -1025px;
  background-position-y: 0px;
}

.emojione-2603 {
  background-position-x: -1025px;
  background-position-y: -25px;
}

.emojione-2604 {
  background-position-x: -1025px;
  background-position-y: -50px;
}

.emojione-260e {
  background-position-x: -1025px;
  background-position-y: -75px;
}

.emojione-2611 {
  background-position-x: -1025px;
  background-position-y: -100px;
}

.emojione-2614 {
  background-position-x: -1025px;
  background-position-y: -125px;
}

.emojione-2615 {
  background-position-x: -1025px;
  background-position-y: -150px;
}

.emojione-2618 {
  background-position-x: -1025px;
  background-position-y: -175px;
}

.emojione-261d-1f3fb {
  background-position-x: -1025px;
  background-position-y: -200px;
}

.emojione-261d-1f3fc {
  background-position-x: -1025px;
  background-position-y: -225px;
}

.emojione-261d-1f3fd {
  background-position-x: -1025px;
  background-position-y: -250px;
}

.emojione-261d-1f3fe {
  background-position-x: -1025px;
  background-position-y: -275px;
}

.emojione-261d-1f3ff {
  background-position-x: -1025px;
  background-position-y: -300px;
}

.emojione-261d {
  background-position-x: -1025px;
  background-position-y: -325px;
}

.emojione-2620 {
  background-position-x: -1025px;
  background-position-y: -350px;
}

.emojione-2622 {
  background-position-x: -1025px;
  background-position-y: -375px;
}

.emojione-2623 {
  background-position-x: -1025px;
  background-position-y: -400px;
}

.emojione-2626 {
  background-position-x: -1025px;
  background-position-y: -425px;
}

.emojione-262a {
  background-position-x: -1025px;
  background-position-y: -450px;
}

.emojione-262e {
  background-position-x: -1025px;
  background-position-y: -475px;
}

.emojione-262f {
  background-position-x: -1025px;
  background-position-y: -500px;
}

.emojione-2638 {
  background-position-x: -1025px;
  background-position-y: -525px;
}

.emojione-2639 {
  background-position-x: -1025px;
  background-position-y: -550px;
}

.emojione-263a {
  background-position-x: -1025px;
  background-position-y: -575px;
}

.emojione-2648 {
  background-position-x: -1025px;
  background-position-y: -600px;
}

.emojione-2649 {
  background-position-x: -1025px;
  background-position-y: -625px;
}

.emojione-264a {
  background-position-x: -1025px;
  background-position-y: -650px;
}

.emojione-264b {
  background-position-x: -1025px;
  background-position-y: -675px;
}

.emojione-264c {
  background-position-x: -1025px;
  background-position-y: -700px;
}

.emojione-264d {
  background-position-x: -1025px;
  background-position-y: -725px;
}

.emojione-264e {
  background-position-x: -1025px;
  background-position-y: -750px;
}

.emojione-264f {
  background-position-x: -1025px;
  background-position-y: -775px;
}

.emojione-2650 {
  background-position-x: -1025px;
  background-position-y: -800px;
}

.emojione-2651 {
  background-position-x: -1025px;
  background-position-y: -825px;
}

.emojione-2652 {
  background-position-x: -1025px;
  background-position-y: -850px;
}

.emojione-2653 {
  background-position-x: -1025px;
  background-position-y: -875px;
}

.emojione-2660 {
  background-position-x: -1025px;
  background-position-y: -900px;
}

.emojione-2663 {
  background-position-x: -1025px;
  background-position-y: -925px;
}

.emojione-2665 {
  background-position-x: -1025px;
  background-position-y: -950px;
}

.emojione-2666 {
  background-position-x: -1025px;
  background-position-y: -975px;
}

.emojione-2668 {
  background-position-x: -1025px;
  background-position-y: -1000px;
}

.emojione-267b {
  background-position-x: 0px;
  background-position-y: -1025px;
}

.emojione-267f {
  background-position-x: -25px;
  background-position-y: -1025px;
}

.emojione-2692 {
  background-position-x: -50px;
  background-position-y: -1025px;
}

.emojione-2693 {
  background-position-x: -75px;
  background-position-y: -1025px;
}

.emojione-2694 {
  background-position-x: -100px;
  background-position-y: -1025px;
}

.emojione-2696 {
  background-position-x: -125px;
  background-position-y: -1025px;
}

.emojione-2697 {
  background-position-x: -150px;
  background-position-y: -1025px;
}

.emojione-2699 {
  background-position-x: -175px;
  background-position-y: -1025px;
}

.emojione-269b {
  background-position-x: -200px;
  background-position-y: -1025px;
}

.emojione-269c {
  background-position-x: -225px;
  background-position-y: -1025px;
}

.emojione-26a0 {
  background-position-x: -250px;
  background-position-y: -1025px;
}

.emojione-26a1 {
  background-position-x: -275px;
  background-position-y: -1025px;
}

.emojione-26aa {
  background-position-x: -300px;
  background-position-y: -1025px;
}

.emojione-26ab {
  background-position-x: -325px;
  background-position-y: -1025px;
}

.emojione-26b0 {
  background-position-x: -350px;
  background-position-y: -1025px;
}

.emojione-26b1 {
  background-position-x: -375px;
  background-position-y: -1025px;
}

.emojione-26bd {
  background-position-x: -400px;
  background-position-y: -1025px;
}

.emojione-26be {
  background-position-x: -425px;
  background-position-y: -1025px;
}

.emojione-26c4 {
  background-position-x: -450px;
  background-position-y: -1025px;
}

.emojione-26c5 {
  background-position-x: -475px;
  background-position-y: -1025px;
}

.emojione-26c8 {
  background-position-x: -500px;
  background-position-y: -1025px;
}

.emojione-26ce {
  background-position-x: -525px;
  background-position-y: -1025px;
}

.emojione-26cf {
  background-position-x: -550px;
  background-position-y: -1025px;
}

.emojione-26d1 {
  background-position-x: -575px;
  background-position-y: -1025px;
}

.emojione-26d3 {
  background-position-x: -600px;
  background-position-y: -1025px;
}

.emojione-26d4 {
  background-position-x: -625px;
  background-position-y: -1025px;
}

.emojione-26e9 {
  background-position-x: -650px;
  background-position-y: -1025px;
}

.emojione-26ea {
  background-position-x: -675px;
  background-position-y: -1025px;
}

.emojione-26f0 {
  background-position-x: -700px;
  background-position-y: -1025px;
}

.emojione-26f1 {
  background-position-x: -725px;
  background-position-y: -1025px;
}

.emojione-26f2 {
  background-position-x: -750px;
  background-position-y: -1025px;
}

.emojione-26f3 {
  background-position-x: -775px;
  background-position-y: -1025px;
}

.emojione-26f4 {
  background-position-x: -800px;
  background-position-y: -1025px;
}

.emojione-26f5 {
  background-position-x: -825px;
  background-position-y: -1025px;
}

.emojione-26f7 {
  background-position-x: -850px;
  background-position-y: -1025px;
}

.emojione-26f8 {
  background-position-x: -875px;
  background-position-y: -1025px;
}

.emojione-26f9-1f3fb {
  background-position-x: -900px;
  background-position-y: -1025px;
}

.emojione-26f9-1f3fc {
  background-position-x: -925px;
  background-position-y: -1025px;
}

.emojione-26f9-1f3fd {
  background-position-x: -950px;
  background-position-y: -1025px;
}

.emojione-26f9-1f3fe {
  background-position-x: -975px;
  background-position-y: -1025px;
}

.emojione-26f9-1f3ff {
  background-position-x: -1000px;
  background-position-y: -1025px;
}

.emojione-26f9 {
  background-position-x: -1025px;
  background-position-y: -1025px;
}

.emojione-26fa {
  background-position-x: -1050px;
  background-position-y: 0px;
}

.emojione-26fd {
  background-position-x: -1050px;
  background-position-y: -25px;
}

.emojione-2702 {
  background-position-x: -1050px;
  background-position-y: -50px;
}

.emojione-2705 {
  background-position-x: -1050px;
  background-position-y: -75px;
}

.emojione-2708 {
  background-position-x: -1050px;
  background-position-y: -100px;
}

.emojione-2709 {
  background-position-x: -1050px;
  background-position-y: -125px;
}

.emojione-270a-1f3fb {
  background-position-x: -1050px;
  background-position-y: -150px;
}

.emojione-270a-1f3fc {
  background-position-x: -1050px;
  background-position-y: -175px;
}

.emojione-270a-1f3fd {
  background-position-x: -1050px;
  background-position-y: -200px;
}

.emojione-270a-1f3fe {
  background-position-x: -1050px;
  background-position-y: -225px;
}

.emojione-270a-1f3ff {
  background-position-x: -1050px;
  background-position-y: -250px;
}

.emojione-270a {
  background-position-x: -1050px;
  background-position-y: -275px;
}

.emojione-270b-1f3fb {
  background-position-x: -1050px;
  background-position-y: -300px;
}

.emojione-270b-1f3fc {
  background-position-x: -1050px;
  background-position-y: -325px;
}

.emojione-270b-1f3fd {
  background-position-x: -1050px;
  background-position-y: -350px;
}

.emojione-270b-1f3fe {
  background-position-x: -1050px;
  background-position-y: -375px;
}

.emojione-270b-1f3ff {
  background-position-x: -1050px;
  background-position-y: -400px;
}

.emojione-270b {
  background-position-x: -1050px;
  background-position-y: -425px;
}

.emojione-270c-1f3fb {
  background-position-x: -1050px;
  background-position-y: -450px;
}

.emojione-270c-1f3fc {
  background-position-x: -1050px;
  background-position-y: -475px;
}

.emojione-270c-1f3fd {
  background-position-x: -1050px;
  background-position-y: -500px;
}

.emojione-270c-1f3fe {
  background-position-x: -1050px;
  background-position-y: -525px;
}

.emojione-270c-1f3ff {
  background-position-x: -1050px;
  background-position-y: -550px;
}

.emojione-270c {
  background-position-x: -1050px;
  background-position-y: -575px;
}

.emojione-270d-1f3fb {
  background-position-x: -1050px;
  background-position-y: -600px;
}

.emojione-270d-1f3fc {
  background-position-x: -1050px;
  background-position-y: -625px;
}

.emojione-270d-1f3fd {
  background-position-x: -1050px;
  background-position-y: -650px;
}

.emojione-270d-1f3fe {
  background-position-x: -1050px;
  background-position-y: -675px;
}

.emojione-270d-1f3ff {
  background-position-x: -1050px;
  background-position-y: -700px;
}

.emojione-270d {
  background-position-x: -1050px;
  background-position-y: -725px;
}

.emojione-270f {
  background-position-x: -1050px;
  background-position-y: -750px;
}

.emojione-2712 {
  background-position-x: -1050px;
  background-position-y: -775px;
}

.emojione-2714 {
  background-position-x: -1050px;
  background-position-y: -800px;
}

.emojione-2716 {
  background-position-x: -1050px;
  background-position-y: -825px;
}

.emojione-271d {
  background-position-x: -1050px;
  background-position-y: -850px;
}

.emojione-2721 {
  background-position-x: -1050px;
  background-position-y: -875px;
}

.emojione-2728 {
  background-position-x: -1050px;
  background-position-y: -900px;
}

.emojione-2733 {
  background-position-x: -1050px;
  background-position-y: -925px;
}

.emojione-2734 {
  background-position-x: -1050px;
  background-position-y: -950px;
}

.emojione-2744 {
  background-position-x: -1050px;
  background-position-y: -975px;
}

.emojione-2747 {
  background-position-x: -1050px;
  background-position-y: -1000px;
}

.emojione-274c {
  background-position-x: -1050px;
  background-position-y: -1025px;
}

.emojione-274e {
  background-position-x: 0px;
  background-position-y: -1050px;
}

.emojione-2753 {
  background-position-x: -25px;
  background-position-y: -1050px;
}

.emojione-2754 {
  background-position-x: -50px;
  background-position-y: -1050px;
}

.emojione-2755 {
  background-position-x: -75px;
  background-position-y: -1050px;
}

.emojione-2757 {
  background-position-x: -100px;
  background-position-y: -1050px;
}

.emojione-2763 {
  background-position-x: -125px;
  background-position-y: -1050px;
}

.emojione-2764 {
  background-position-x: -150px;
  background-position-y: -1050px;
}

.emojione-2795 {
  background-position-x: -175px;
  background-position-y: -1050px;
}

.emojione-2796 {
  background-position-x: -200px;
  background-position-y: -1050px;
}

.emojione-2797 {
  background-position-x: -225px;
  background-position-y: -1050px;
}

.emojione-27a1 {
  background-position-x: -250px;
  background-position-y: -1050px;
}

.emojione-27b0 {
  background-position-x: -275px;
  background-position-y: -1050px;
}

.emojione-27bf {
  background-position-x: -300px;
  background-position-y: -1050px;
}

.emojione-2934 {
  background-position-x: -325px;
  background-position-y: -1050px;
}

.emojione-2935 {
  background-position-x: -350px;
  background-position-y: -1050px;
}

.emojione-2b05 {
  background-position-x: -375px;
  background-position-y: -1050px;
}

.emojione-2b06 {
  background-position-x: -400px;
  background-position-y: -1050px;
}

.emojione-2b07 {
  background-position-x: -425px;
  background-position-y: -1050px;
}

.emojione-2b1b {
  background-position-x: -450px;
  background-position-y: -1050px;
}

.emojione-2b1c {
  background-position-x: -475px;
  background-position-y: -1050px;
}

.emojione-2b50 {
  background-position-x: -500px;
  background-position-y: -1050px;
}

.emojione-2b55 {
  background-position-x: -525px;
  background-position-y: -1050px;
}

.emojione-3030 {
  background-position-x: -550px;
  background-position-y: -1050px;
}

.emojione-303d {
  background-position-x: -575px;
  background-position-y: -1050px;
}

.emojione-3297 {
  background-position-x: -600px;
  background-position-y: -1050px;
}

.emojione-3299 {
  background-position-x: -625px;
  background-position-y: -1050px;
}

/* required styles */
.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
}

.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}

/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}

.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}

/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}

.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}

.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-zoom-box {
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
}

/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-overlay-pane {
  z-index: 400;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}

.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */
.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}

.leaflet-top {
  top: 0;
}

.leaflet-right {
  right: 0;
}

.leaflet-bottom {
  bottom: 0;
}

.leaflet-left {
  left: 0;
}

.leaflet-control {
  float: left;
  clear: both;
}

.leaflet-right .leaflet-control {
  float: right;
}

.leaflet-top .leaflet-control {
  margin-top: 10px;
}

.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}

.leaflet-left .leaflet-control {
  margin-left: 10px;
}

.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */
.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}

.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}

.leaflet-zoom-anim .leaflet-zoom-animated {
  -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  -o-transition: -o-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}

.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */
.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}

.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}

.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive {
  pointer-events: visiblePainted;
  /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */
.leaflet-container {
  background: #ddd;
  outline: 0;
}

.leaflet-container a {
  color: #0078A8;
}

.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}

.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */
.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}

.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}

.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}

.leaflet-bar a:hover {
  background-color: #f4f4f4;
}

.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}

.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */
.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in, .leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */
.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}

.leaflet-control-layers-toggle {
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(images/layers-2x.png);
  background-size: 26px 26px;
}

.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}

.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}

.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}

.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers label {
  display: block;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

/* attribution and scale controls */
.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}

.leaflet-control-attribution a {
  text-decoration: none;
}

.leaflet-control-attribution a:hover {
  text-decoration: underline;
}

.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}

.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}

.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}

.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}

.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */
.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}

.leaflet-popup-content {
  margin: 13px 19px;
  line-height: 1.4;
}

.leaflet-popup-content p {
  margin: 18px 0;
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}

.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}

.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;
  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}

.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */
.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */
.leaflet-tooltip-bottom {
  margin-top: 6px;
}

.leaflet-tooltip-top {
  margin-top: -6px;
}

.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}

.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}

.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}

.leaflet-tooltip-left {
  margin-left: -6px;
}

.leaflet-tooltip-right {
  margin-left: 6px;
}

.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}

.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}

.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/** Trumbowyg v2.9.4 - A lightweight WYSIWYG editor - alex-d.github.io/Trumbowyg - License MIT - Author : Alexandre Demode (Alex-D) / alex-d.fr */
#trumbowyg-icons, #trumbowyg-icons svg {
  height: 0;
  width: 0;
}

#trumbowyg-icons {
  overflow: hidden;
  visibility: hidden;
}

.trumbowyg-box *, .trumbowyg-box ::after, .trumbowyg-box ::before {
  box-sizing: border-box;
}

.trumbowyg-box svg {
  width: 17px;
  height: 100%;
  fill: #222;
}

.trumbowyg-box, .trumbowyg-editor {
  display: block;
  position: relative;
  border: 1px solid #DDD;
  width: 100%;
  min-height: 300px;
  margin: 17px auto;
}

.trumbowyg-box .trumbowyg-editor {
  margin: 0 auto;
}

.trumbowyg-box.trumbowyg-fullscreen {
  background: #FEFEFE;
  border: none !important;
}

.trumbowyg-editor, .trumbowyg-textarea {
  position: relative;
  box-sizing: border-box;
  padding: 20px;
  min-height: 300px;
  width: 100%;
  border-style: none;
  resize: none;
  outline: 0;
  overflow: auto;
}

.trumbowyg-editor.trumbowyg-autogrow-on-enter, .trumbowyg-textarea.trumbowyg-autogrow-on-enter {
  transition: height 0.3s ease-out;
}

.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
  color: transparent !important;
  text-shadow: 0 0 7px #333;
}

@media screen and (min-width: 0 \0 ) {
  .trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(200, 200, 200, 0.6) !important;
  }
}
@supports (-ms-accelerator: true) {
  .trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(200, 200, 200, 0.6) !important;
  }
}
.trumbowyg-box-blur .trumbowyg-editor hr, .trumbowyg-box-blur .trumbowyg-editor img {
  opacity: 0.2;
}

.trumbowyg-textarea {
  position: relative;
  display: block;
  overflow: auto;
  border: none;
  font-size: 14px;
  font-family: Inconsolata, Consolas, Courier, "Courier New", sans-serif;
  line-height: 18px;
}

.trumbowyg-box.trumbowyg-editor-visible .trumbowyg-textarea {
  height: 1px !important;
  width: 25%;
  min-height: 0 !important;
  padding: 0 !important;
  background: 0 0;
  opacity: 0 !important;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-textarea {
  display: block;
}

.trumbowyg-box.trumbowyg-editor-hidden .trumbowyg-editor {
  display: none;
}

.trumbowyg-box.trumbowyg-disabled .trumbowyg-textarea {
  opacity: 0.8;
  background: 0 0;
}

.trumbowyg-editor[contenteditable=true]:empty:not(:focus)::before {
  content: attr(placeholder);
  color: #999;
  pointer-events: none;
}

.trumbowyg-button-pane {
  width: 100%;
  min-height: 36px;
  background: #ecf0f1;
  border-bottom: 1px solid #d7e0e2;
  margin: 0;
  padding: 0 5px;
  position: relative;
  list-style-type: none;
  line-height: 10px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 11;
}

.trumbowyg-button-pane::after {
  content: " ";
  display: block;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;
  width: 100%;
  height: 1px;
  background: #d7e0e2;
}

.trumbowyg-button-pane .trumbowyg-button-group {
  display: inline-block;
}

.trumbowyg-button-pane .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: transparent;
}

.trumbowyg-button-pane .trumbowyg-button-group + .trumbowyg-button-group::before {
  content: " ";
  display: inline-block;
  width: 1px;
  background: #d7e0e2;
  margin: 0 5px;
  height: 35px;
  vertical-align: top;
}

.trumbowyg-button-pane button {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
  padding: 1px 6px !important;
  margin-bottom: 1px;
  overflow: hidden;
  border: none;
  cursor: pointer;
  background: 0 0;
  vertical-align: middle;
  transition: background-color 150ms, opacity 150ms;
}

.trumbowyg-button-pane button.trumbowyg-textual-button {
  width: auto;
  line-height: 35px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.trumbowyg-button-pane.trumbowyg-disable button:not(.trumbowyg-not-disable):not(.trumbowyg-active), .trumbowyg-disabled .trumbowyg-button-pane button:not(.trumbowyg-not-disable):not(.trumbowyg-viewHTML-button) {
  opacity: 0.2;
  cursor: default;
}

.trumbowyg-button-pane.trumbowyg-disable .trumbowyg-button-group::before, .trumbowyg-disabled .trumbowyg-button-pane .trumbowyg-button-group::before {
  background: #e3e9eb;
}

.trumbowyg-button-pane button.trumbowyg-active, .trumbowyg-button-pane button:not(.trumbowyg-disable):focus, .trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background-color: #FFF;
  outline: 0;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown::after {
  display: block;
  content: " ";
  position: absolute;
  top: 25px;
  right: 3px;
  height: 0;
  width: 0;
  border: 3px solid transparent;
  border-top-color: #555;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown.trumbowyg-textual-button {
  padding-left: 10px !important;
  padding-right: 18px !important;
}

.trumbowyg-button-pane .trumbowyg-open-dropdown.trumbowyg-textual-button::after {
  top: 17px;
  right: 7px;
}

.trumbowyg-button-pane .trumbowyg-right {
  float: right;
}

.trumbowyg-button-pane .trumbowyg-right::before {
  display: none !important;
}

.trumbowyg-dropdown {
  width: 200px;
  border: 1px solid #ecf0f1;
  padding: 5px 0;
  border-top: none;
  background: #FFF;
  margin-left: -1px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 3px;
  z-index: 12;
}

.trumbowyg-dropdown button {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  text-decoration: none;
  background: #FFF;
  padding: 0 10px;
  color: #333 !important;
  border: none;
  cursor: pointer;
  text-align: left;
  font-size: 15px;
  transition: all 150ms;
}

.trumbowyg-dropdown button:focus, .trumbowyg-dropdown button:hover {
  background: #ecf0f1;
}

.trumbowyg-dropdown button svg {
  float: left;
  margin-right: 14px;
}

.trumbowyg-modal {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 520px;
  width: 100%;
  height: 350px;
  z-index: 12;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.trumbowyg-modal-box {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  max-width: 500px;
  width: calc(100% - 20px);
  padding-bottom: 45px;
  z-index: 1;
  background-color: #FFF;
  text-align: center;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.2) 0 2px 3px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.trumbowyg-modal-box .trumbowyg-modal-title {
  font-size: 24px;
  font-weight: 700;
  margin: 0 0 20px;
  padding: 15px 0 13px;
  display: block;
  border-bottom: 1px solid #EEE;
  color: #333;
  background: #fbfcfc;
}

.trumbowyg-modal-box .trumbowyg-progress {
  width: 100%;
  height: 3px;
  position: absolute;
  top: 58px;
}

.trumbowyg-modal-box .trumbowyg-progress .trumbowyg-progress-bar {
  background: #2BC06A;
  width: 0;
  height: 100%;
  transition: width 150ms linear;
}

.trumbowyg-modal-box label {
  display: block;
  position: relative;
  margin: 15px 12px;
  height: 29px;
  line-height: 29px;
  overflow: hidden;
}

.trumbowyg-modal-box label .trumbowyg-input-infos {
  display: block;
  text-align: left;
  height: 25px;
  line-height: 25px;
  transition: all 150ms;
}

.trumbowyg-modal-box label .trumbowyg-input-infos span {
  display: block;
  color: #69878f;
  background-color: #fbfcfc;
  border: 1px solid #DEDEDE;
  padding: 0 7px;
  width: 150px;
}

.trumbowyg-modal-box label .trumbowyg-input-infos span.trumbowyg-msg-error {
  color: #e74c3c;
}

.trumbowyg-modal-box label.trumbowyg-input-error input, .trumbowyg-modal-box label.trumbowyg-input-error textarea {
  border: 1px solid #e74c3c;
}

.trumbowyg-modal-box label.trumbowyg-input-error .trumbowyg-input-infos {
  margin-top: -27px;
}

.trumbowyg-modal-box label input {
  position: absolute;
  top: 0;
  right: 0;
  height: 27px;
  line-height: 27px;
  border: 1px solid #DEDEDE;
  background: #fff;
  font-size: 14px;
  max-width: 330px;
  width: 70%;
  padding: 0 7px;
  transition: all 150ms;
}

.trumbowyg-modal-box label input:focus, .trumbowyg-modal-box label input:hover {
  outline: 0;
  border: 1px solid #95a5a6;
}

.trumbowyg-modal-box label input:focus {
  background: #fbfcfc;
}

.trumbowyg-modal-box .error {
  margin-top: 25px;
  display: block;
  color: red;
}

.trumbowyg-modal-box .trumbowyg-modal-button {
  position: absolute;
  bottom: 10px;
  right: 0;
  text-decoration: none;
  color: #FFF;
  display: block;
  width: 100px;
  height: 35px;
  line-height: 33px;
  margin: 0 10px;
  background-color: #333;
  border: none;
  cursor: pointer;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif;
  font-size: 16px;
  transition: all 150ms;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit {
  right: 110px;
  background: #2bc06a;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:focus, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:hover {
  background: #40d47e;
  outline: 0;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:active {
  background: #25a25a;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset {
  color: #555;
  background: #e6e6e6;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:focus, .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:hover {
  background: #fbfbfb;
  outline: 0;
}

.trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:active {
  background: #d5d5d5;
}

.trumbowyg-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  left: 0;
  display: none;
  top: 0;
  z-index: 10;
}

body.trumbowyg-body-fullscreen {
  overflow: hidden;
}

.trumbowyg-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 99999;
}

.trumbowyg-fullscreen .trumbowyg-editor, .trumbowyg-fullscreen.trumbowyg-box {
  border: none;
}

.trumbowyg-fullscreen .trumbowyg-editor, .trumbowyg-fullscreen .trumbowyg-textarea {
  height: calc(100% - 37px) !important;
  overflow: auto;
}

.trumbowyg-fullscreen .trumbowyg-overlay {
  height: 100% !important;
}

.trumbowyg-fullscreen .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: #222;
  fill: transparent;
}

.trumbowyg-editor embed, .trumbowyg-editor img, .trumbowyg-editor object, .trumbowyg-editor video {
  max-width: 100%;
}

.trumbowyg-editor img, .trumbowyg-editor video {
  height: auto;
}

.trumbowyg-editor img {
  cursor: move;
}

.trumbowyg-editor.trumbowyg-reset-css {
  background: #FEFEFE !important;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
  font-size: 14px !important;
  line-height: 1.45em !important;
  color: #333;
}

.trumbowyg-editor.trumbowyg-reset-css a {
  color: #15c !important;
  text-decoration: underline !important;
}

.trumbowyg-editor.trumbowyg-reset-css blockquote, .trumbowyg-editor.trumbowyg-reset-css div, .trumbowyg-editor.trumbowyg-reset-css ol, .trumbowyg-editor.trumbowyg-reset-css p, .trumbowyg-editor.trumbowyg-reset-css ul {
  box-shadow: none !important;
  background: 0 0 !important;
  margin: 0 0 15px !important;
  line-height: 1.4em !important;
  font-family: "Trebuchet MS", Helvetica, Verdana, sans-serif !important;
  font-size: 14px !important;
  border: none;
}

.trumbowyg-editor.trumbowyg-reset-css hr, .trumbowyg-editor.trumbowyg-reset-css iframe, .trumbowyg-editor.trumbowyg-reset-css object {
  margin-bottom: 15px !important;
}

.trumbowyg-editor.trumbowyg-reset-css blockquote {
  margin-left: 32px !important;
  font-style: italic !important;
  color: #555;
}

.trumbowyg-editor.trumbowyg-reset-css ol, .trumbowyg-editor.trumbowyg-reset-css ul {
  padding-left: 20px !important;
}

.trumbowyg-editor.trumbowyg-reset-css ol ol, .trumbowyg-editor.trumbowyg-reset-css ol ul, .trumbowyg-editor.trumbowyg-reset-css ul ol, .trumbowyg-editor.trumbowyg-reset-css ul ul {
  border: none;
  margin: 2px !important;
  padding: 0 0 0 24px !important;
}

.trumbowyg-editor.trumbowyg-reset-css hr {
  display: block;
  height: 1px;
  border: none;
  border-top: 1px solid #CCC;
}

.trumbowyg-editor.trumbowyg-reset-css h1, .trumbowyg-editor.trumbowyg-reset-css h2, .trumbowyg-editor.trumbowyg-reset-css h3, .trumbowyg-editor.trumbowyg-reset-css h4 {
  color: #111;
  background: 0 0;
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 700;
}

.trumbowyg-editor.trumbowyg-reset-css h1 {
  font-size: 32px !important;
  line-height: 38px !important;
  margin-bottom: 20px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h2 {
  font-size: 26px !important;
  line-height: 34px !important;
  margin-bottom: 15px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h3 {
  font-size: 22px !important;
  line-height: 28px !important;
  margin-bottom: 7px !important;
}

.trumbowyg-editor.trumbowyg-reset-css h4 {
  font-size: 16px !important;
  line-height: 22px !important;
  margin-bottom: 7px !important;
}

.trumbowyg-dark .trumbowyg-textarea {
  background: #111;
  color: #ddd;
}

.trumbowyg-dark .trumbowyg-box {
  border: 1px solid #343434;
}

.trumbowyg-dark .trumbowyg-box.trumbowyg-fullscreen {
  background: #111;
}

.trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
  text-shadow: 0 0 7px #ccc;
}

@media screen and (min-width: 0 \0 ) {
  .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(20, 20, 20, 0.6) !important;
  }
}
@supports (-ms-accelerator: true) {
  .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor *, .trumbowyg-dark .trumbowyg-box.trumbowyg-box-blur .trumbowyg-editor::before {
    color: rgba(20, 20, 20, 0.6) !important;
  }
}
.trumbowyg-dark .trumbowyg-box svg {
  fill: #ecf0f1;
  color: #ecf0f1;
}

.trumbowyg-dark .trumbowyg-button-pane {
  background-color: #222;
  border-bottom-color: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane::after {
  background: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-button-group:not(:empty)::before {
  background-color: #343434;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-button-group:not(:empty) .trumbowyg-fullscreen-button svg {
  color: transparent;
}

.trumbowyg-dark .trumbowyg-button-pane.trumbowyg-disable .trumbowyg-button-group::before {
  background-color: #2a2a2a;
}

.trumbowyg-dark .trumbowyg-button-pane button.trumbowyg-active, .trumbowyg-dark .trumbowyg-button-pane button:not(.trumbowyg-disable):focus, .trumbowyg-dark .trumbowyg-button-pane button:not(.trumbowyg-disable):hover {
  background-color: #333;
}

.trumbowyg-dark .trumbowyg-button-pane .trumbowyg-open-dropdown::after {
  border-top-color: #fff;
}

.trumbowyg-dark .trumbowyg-fullscreen .trumbowyg-button-group .trumbowyg-fullscreen-button svg {
  color: #ecf0f1;
  fill: transparent;
}

.trumbowyg-dark .trumbowyg-dropdown {
  border-color: #222;
  background: #333;
  box-shadow: rgba(0, 0, 0, 0.3) 0 2px 3px;
}

.trumbowyg-dark .trumbowyg-dropdown button {
  background: #333;
  color: #fff !important;
}

.trumbowyg-dark .trumbowyg-dropdown button:focus, .trumbowyg-dark .trumbowyg-dropdown button:hover {
  background: #222;
}

.trumbowyg-dark .trumbowyg-modal-box {
  background-color: #222;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-title {
  border-bottom: 1px solid #555;
  color: #fff;
  background: #3c3c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label {
  display: block;
  position: relative;
  margin: 15px 12px;
  height: 27px;
  line-height: 27px;
  overflow: hidden;
}

.trumbowyg-dark .trumbowyg-modal-box label .trumbowyg-input-infos span {
  color: #eee;
  background-color: #2f2f2f;
  border-color: #222;
}

.trumbowyg-dark .trumbowyg-modal-box label .trumbowyg-input-infos span.trumbowyg-msg-error {
  color: #e74c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label.trumbowyg-input-error input, .trumbowyg-dark .trumbowyg-modal-box label.trumbowyg-input-error textarea {
  border-color: #e74c3c;
}

.trumbowyg-dark .trumbowyg-modal-box label input {
  border-color: #222;
  color: #eee;
  background: #333;
}

.trumbowyg-dark .trumbowyg-modal-box label input:focus, .trumbowyg-dark .trumbowyg-modal-box label input:hover {
  border-color: #626262;
}

.trumbowyg-dark .trumbowyg-modal-box label input:focus {
  background-color: #2f2f2f;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit {
  background: #1b7943;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:focus, .trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:hover {
  background: #25a25a;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-submit:active {
  background: #176437;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset {
  background: #333;
  color: #ccc;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:focus, .trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:hover {
  background: #444;
}

.trumbowyg-dark .trumbowyg-modal-box .trumbowyg-modal-button.trumbowyg-modal-reset:active {
  background: #111;
}

.trumbowyg-dark .trumbowyg-overlay {
  background-color: rgba(15, 15, 15, 0.6);
}

.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}

.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-key:before {
  content: "\f084";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-nintendo-switch:before {
  content: "\f418";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-parking:before {
  content: "\f540";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-php:before {
  content: "\f457";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-print:before {
  content: "\f02f";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-trello:before {
  content: "\f181";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-water:before {
  content: "\f773";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*
 * This combined file was created by the DataTables downloader builder:
 *   https://datatables.net/download
 *
 * To rebuild or modify this file with the latest versions of the included
 * software please visit:
 *   https://datatables.net/download/#dt/dt-1.10.18/r-2.2.2
 *
 * Included libraries:
 *   DataTables 1.10.18, Responsive 2.2.2
 */
/*
 * Table styles
 */
table.dataTable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  /*
   * Header and footer styles
   */
  /*
   * Body styles
   */
}

table.dataTable thead th,
table.dataTable tfoot th {
  font-weight: bold;
}

table.dataTable thead th,
table.dataTable thead td {
  padding: 10px 18px;
  border-bottom: 1px solid #111;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
  outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding: 10px 18px 6px 18px;
  border-top: 1px solid #111;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  *cursor: hand;
  background-repeat: no-repeat;
  background-position: center right;
}

table.dataTable thead .sorting {
  background-image: url("DataTables-1.10.18/images/sort_both.png");
}

table.dataTable thead .sorting_asc {
  background-image: url("DataTables-1.10.18/images/sort_asc.png");
}

table.dataTable thead .sorting_desc {
  background-image: url("DataTables-1.10.18/images/sort_desc.png");
}

table.dataTable thead .sorting_asc_disabled {
  background-image: url("DataTables-1.10.18/images/sort_asc_disabled.png");
}

table.dataTable thead .sorting_desc_disabled {
  background-image: url("DataTables-1.10.18/images/sort_desc_disabled.png");
}

table.dataTable tbody tr {
  background-color: #ffffff;
}

table.dataTable tbody tr.selected {
  background-color: #B0BED9;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 8px 10px;
}

table.dataTable.row-border tbody th, table.dataTable.row-border tbody td, table.dataTable.display tbody th, table.dataTable.display tbody td {
  border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td, table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
  border-top: none;
}

table.dataTable.cell-border tbody th, table.dataTable.cell-border tbody td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
  border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
  border-top: none;
}

table.dataTable.stripe tbody tr.odd, table.dataTable.display tbody tr.odd {
  background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected, table.dataTable.display tbody tr.odd.selected {
  background-color: #acbad4;
}

table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover {
  background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected, table.dataTable.display tbody tr:hover.selected {
  background-color: #aab7d1;
}

table.dataTable.order-column tbody tr > .sorting_1,
table.dataTable.order-column tbody tr > .sorting_2,
table.dataTable.order-column tbody tr > .sorting_3, table.dataTable.display tbody tr > .sorting_1,
table.dataTable.display tbody tr > .sorting_2,
table.dataTable.display tbody tr > .sorting_3 {
  background-color: #fafafa;
}

table.dataTable.order-column tbody tr.selected > .sorting_1,
table.dataTable.order-column tbody tr.selected > .sorting_2,
table.dataTable.order-column tbody tr.selected > .sorting_3, table.dataTable.display tbody tr.selected > .sorting_1,
table.dataTable.display tbody tr.selected > .sorting_2,
table.dataTable.display tbody tr.selected > .sorting_3 {
  background-color: #acbad5;
}

table.dataTable.display tbody tr.odd > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd > .sorting_1 {
  background-color: #f1f1f1;
}

table.dataTable.display tbody tr.odd > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd > .sorting_2 {
  background-color: #f3f3f3;
}

table.dataTable.display tbody tr.odd > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd > .sorting_3 {
  background-color: whitesmoke;
}

table.dataTable.display tbody tr.odd.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_1 {
  background-color: #a6b4cd;
}

table.dataTable.display tbody tr.odd.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_2 {
  background-color: #a8b5cf;
}

table.dataTable.display tbody tr.odd.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.odd.selected > .sorting_3 {
  background-color: #a9b7d1;
}

table.dataTable.display tbody tr.even > .sorting_1, table.dataTable.order-column.stripe tbody tr.even > .sorting_1 {
  background-color: #fafafa;
}

table.dataTable.display tbody tr.even > .sorting_2, table.dataTable.order-column.stripe tbody tr.even > .sorting_2 {
  background-color: #fcfcfc;
}

table.dataTable.display tbody tr.even > .sorting_3, table.dataTable.order-column.stripe tbody tr.even > .sorting_3 {
  background-color: #fefefe;
}

table.dataTable.display tbody tr.even.selected > .sorting_1, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_1 {
  background-color: #acbad5;
}

table.dataTable.display tbody tr.even.selected > .sorting_2, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_2 {
  background-color: #aebcd6;
}

table.dataTable.display tbody tr.even.selected > .sorting_3, table.dataTable.order-column.stripe tbody tr.even.selected > .sorting_3 {
  background-color: #afbdd8;
}

table.dataTable.display tbody tr:hover > .sorting_1, table.dataTable.order-column.hover tbody tr:hover > .sorting_1 {
  background-color: #eaeaea;
}

table.dataTable.display tbody tr:hover > .sorting_2, table.dataTable.order-column.hover tbody tr:hover > .sorting_2 {
  background-color: #ececec;
}

table.dataTable.display tbody tr:hover > .sorting_3, table.dataTable.order-column.hover tbody tr:hover > .sorting_3 {
  background-color: #efefef;
}

table.dataTable.display tbody tr:hover.selected > .sorting_1, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_1 {
  background-color: #a2aec7;
}

table.dataTable.display tbody tr:hover.selected > .sorting_2, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_2 {
  background-color: #a3b0c9;
}

table.dataTable.display tbody tr:hover.selected > .sorting_3, table.dataTable.order-column.hover tbody tr:hover.selected > .sorting_3 {
  background-color: #a5b2cb;
}

table.dataTable.no-footer {
  border-bottom: 1px solid #111;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
  padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
  padding: 4px;
}

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
  padding: 4px;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
  text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
  text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
  text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
  text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
  white-space: nowrap;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
  text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
  text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
  text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
  text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
  white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
  text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
  text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
  text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
  text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
  white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
  box-sizing: content-box;
}

/*
 * Control feature layout
 */
.dataTables_wrapper {
  position: relative;
  clear: both;
  *zoom: 1;
  zoom: 1;
}

.dataTables_wrapper .dataTables_length {
  float: left;
}

.dataTables_wrapper .dataTables_filter {
  float: right;
  text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
  margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
  clear: both;
  float: left;
  padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
  float: right;
  text-align: right;
  padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  margin-left: 2px;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  *cursor: hand;
  color: #333 !important;
  border: 1px solid transparent;
  border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: #333 !important;
  border: 1px solid #979797;
  background-color: white;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, white), color-stop(100%, #dcdcdc));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, white 0%, #dcdcdc 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, white 0%, #dcdcdc 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  cursor: default;
  color: #666 !important;
  border: 1px solid transparent;
  background: transparent;
  box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: white !important;
  border: 1px solid #111;
  background-color: #585858;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #585858 0%, #111 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #585858 0%, #111 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #585858 0%, #111 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #585858 0%, #111 100%);
  /* W3C */
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
  outline: none;
  background-color: #2b2b2b;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Chrome10+,Safari5.1+ */
  background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* FF3.6+ */
  background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* IE10+ */
  background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
  /* Opera 11.10+ */
  background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
  /* W3C */
  box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
  padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 40px;
  margin-left: -50%;
  margin-top: -25px;
  padding-top: 20px;
  text-align: center;
  font-size: 1.2em;
  background-color: white;
  background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(25%, rgba(255, 255, 255, 0.9)), color-stop(75%, rgba(255, 255, 255, 0.9)), color-stop(100%, rgba(255, 255, 255, 0)));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.9) 25%, rgba(255, 255, 255, 0.9) 75%, rgba(255, 255, 255, 0) 100%);
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #333;
}

.dataTables_wrapper .dataTables_scroll {
  clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
  vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing, .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
  height: 0;
  overflow: hidden;
  margin: 0 !important;
  padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
  border-bottom: none;
}

.dataTables_wrapper:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

@media screen and (max-width: 767px) {
  .dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_paginate {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_paginate {
    margin-top: 0.5em;
  }
}
@media screen and (max-width: 640px) {
  .dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter {
    float: none;
    text-align: center;
  }

  .dataTables_wrapper .dataTables_filter {
    margin-top: 0.5em;
  }
}
table.dataTable.dtr-inline.collapsed > tbody > tr > td.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty {
  cursor: default !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th.child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td.dataTables_empty:before {
  display: none !important;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role=row] > th:first-child:before {
  top: 9px;
  left: 4px;
  height: 14px;
  width: 14px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 14px;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child {
  padding-left: 27px;
}

table.dataTable.dtr-inline.collapsed.compact > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed.compact > tbody > tr > th:first-child:before {
  top: 5px;
  left: 4px;
  height: 14px;
  width: 14px;
  border-radius: 14px;
  line-height: 14px;
  text-indent: 3px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
  position: relative;
  cursor: pointer;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  margin-top: -10px;
  margin-left: -10px;
  display: block;
  position: absolute;
  color: white;
  border: 2px solid white;
  border-radius: 14px;
  box-shadow: 0 0 3px #444;
  box-sizing: content-box;
  text-align: center;
  text-indent: 0 !important;
  font-family: "Courier New", Courier, monospace;
  line-height: 14px;
  content: "+";
  background-color: #31b131;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
  content: "-";
  background-color: #d33333;
}

table.dataTable > tbody > tr.child {
  padding: 0.5em 1em;
}

table.dataTable > tbody > tr.child:hover {
  background: transparent !important;
}

table.dataTable > tbody > tr.child ul.dtr-details {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom: 1px solid #efefef;
  padding: 0.5em 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:first-child {
  padding-top: 0;
}

table.dataTable > tbody > tr.child ul.dtr-details > li:last-child {
  border-bottom: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 75px;
  font-weight: bold;
}

div.dtr-modal {
  position: fixed;
  box-sizing: border-box;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  padding: 10em 1em;
}

div.dtr-modal div.dtr-modal-display {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 50%;
  overflow: auto;
  margin: auto;
  z-index: 102;
  overflow: auto;
  background-color: #f5f5f7;
  border: 1px solid black;
  border-radius: 0.5em;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.6);
}

div.dtr-modal div.dtr-modal-content {
  position: relative;
  padding: 1em;
}

div.dtr-modal div.dtr-modal-close {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 22px;
  height: 22px;
  border: 1px solid #eaeaea;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  z-index: 12;
}

div.dtr-modal div.dtr-modal-close:hover {
  background-color: #eaeaea;
}

div.dtr-modal div.dtr-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.6);
}

@media screen and (max-width: 767px) {
  div.dtr-modal div.dtr-modal-display {
    width: 95%;
  }
}
.dv-lazyload {
  opacity: 0.3;
}

#dv_tooltip {
  display: none;
  position: absolute;
  z-index: 1500;
}

#dv_tooltip div {
  padding: 8px 15px;
  background: #666;
  box-shadow: 0 5px 5px #ccc;
  -moz-box-shadow: 0 5px 5px #ccc;
  -webkit-box-shadow: 0 5px 5px #ccc;
  border-radius: 4px;
  color: #fff;
  overflow: hidden;
  /*text-align: center;*/
}

#dv_tooltip p, .dvForm_imagecheck p {
  display: block;
  max-width: 300px;
}

#dv_tooltip div label, .dvForm_imagecheck label {
  width: 120px;
  display: block;
  float: left;
}

header {
  margin: 0;
  height: 0.01px;
}
header .nav-container {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  background: #88bd24;
  height: 100%;
  overflow-y: auto;
  width: 13%;
}
header .nav-container .leftcol {
  padding: 0 30px;
  width: 100%;
}
header .nav-container .leftcol .btn {
  display: block;
  background: #88bd24;
  color: #fff;
  text-align: center;
  padding: 5px 20px;
  margin: 0 0 10px 0;
}
header #logo {
  background: #fff;
  display: block;
  padding: 40px;
  padding-bottom: 50px;
  width: 100%;
  text-align: center;
}
header #logo img {
  width: 150px;
  height: 80px;
}
header .userinfo {
  background: #b7e265;
  padding: 10px 20px;
  margin: 20px;
}
header .userinfo h2 {
  font-size: 18px;
  color: #000;
  margin: 0 0 10px 0;
}
header .userinfo .infotable {
  margin: 10px 0;
}
header .userinfo .infotable td {
  font-size: 14px;
  font-family: "UniversLTPro-65Bold", sans-serif;
}
header .userinfo .infotable td .ove {
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
header .userinfo .infotable td.label {
  font-family: "UniversLTPro-45Light", sans-serif;
  font-size: 12px;
  padding-right: 10px;
}
header nav {
  font-size: 16px;
}
header nav.main-nav ul {
  list-style: none;
}
header nav.main-nav ul li {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}
header nav.main-nav ul li a, header nav.main-nav ul li :link, header nav.main-nav ul li :active, header nav.main-nav ul li :visited {
  padding: 20px;
  display: block;
  color: #fff;
  position: relative;
}
header nav.main-nav ul li a:hover::after, header nav.main-nav ul li a.selected::after, header nav.main-nav ul li :link:hover::after, header nav.main-nav ul li :link.selected::after, header nav.main-nav ul li :active:hover::after, header nav.main-nav ul li :active.selected::after, header nav.main-nav ul li :visited:hover::after, header nav.main-nav ul li :visited.selected::after {
  transform: scaleX(1);
}
header nav.main-nav ul li a:after, header nav.main-nav ul li :link:after, header nav.main-nav ul li :active:after, header nav.main-nav ul li :visited:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  background-color: rgba(255, 255, 255, 0.15);
  z-index: -1;
  transition: 400ms linear all;
}
header nav.main-nav.mt {
  margin-top: 50px;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
}
header nav.mobile-meta {
  display: none;
  background-color: #69921c;
}
header nav.mobile-meta ul {
  display: flex;
}
header nav.mobile-meta ul li {
  width: auto;
  flex-grow: 1;
  border-bottom: 0;
}
header nav.mobile-meta ul li a {
  text-align: center;
}
header nav.meta-nav {
  position: absolute;
  top: 0;
  right: 0;
  border-bottom: 1px solid #efefef;
  border-left: 1px solid #efefef;
  background: #fff;
  z-index: 10;
}
header nav.meta-nav ul {
  list-style: none;
}
header nav.meta-nav ul li {
  display: inline-block;
}
header nav.meta-nav ul li svg {
  height: 18px;
  width: 19px;
  margin: 0 20px 0 0;
  fill: #666;
  vertical-align: middle;
}
header nav.meta-nav ul li a, header nav.meta-nav ul li :link, header nav.meta-nav ul li :active, header nav.meta-nav ul li :visited {
  position: relative;
  display: inline-block;
  padding: 15px 20px;
  border-right: 1px solid #efefef;
}
header nav.meta-nav ul li a:hover, header nav.meta-nav ul li a.selected, header nav.meta-nav ul li :link:hover, header nav.meta-nav ul li :link.selected, header nav.meta-nav ul li :active:hover, header nav.meta-nav ul li :active.selected, header nav.meta-nav ul li :visited:hover, header nav.meta-nav ul li :visited.selected {
  color: #88bd24;
}

.login header nav.meta-nav {
  right: 85px;
}

.mob-menu {
  display: none;
}

@media (max-width: 1679px) {
  header .nav-container {
    width: 17%;
  }
}
@media (max-width: 1449px) {
  header .nav-container {
    width: 20%;
  }
}
@media (max-width: 1179px) {
  header .nav-container {
    width: 22%;
  }
}
@media (max-width: 1023px) {
  header {
    min-height: 75px;
  }
  header .nav-container {
    width: 100%;
    background: #fff;
    height: 75px;
    overflow: hidden;
  }
  header .nav-container #logo {
    padding: 8px 0 3px 13px;
    text-align: left;
    width: 90%;
    width: calc(100% - 85px);
  }
  header .nav-container #logo img {
    width: auto;
    height: 56px;
  }
  header nav.meta-nav {
    display: none;
  }
  header nav.mobile-meta {
    display: block;
  }
  header .mob-menu {
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    padding: 25px;
    z-index: 101;
  }

  .mob-nav-open {
    overflow: hidden !important;
  }
  .mob-nav-open header .nav-container {
    height: 100%;
    background: #88bd24;
  }
}
/*
header{

    .nav-container{
        background: $color-green;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        width: 230px;
    }
    #logo{
        background: #fff;
        display: block;
        padding: 20px 40px;
        width: 230px;

        img{
            width: 150px;
            height: 80px;
        }
    }

    nav{
        font-size: 16px;

        &.main-nav{
            background: $color-green;
            ul {
                list-style: none;

                li{
                    a, :link, :active, :visited{
                        padding: $space;
                        border-bottom: solid 1px $color-white;
                        display: block;
                        color: #fff;
                        background: $color-green;

                        &:hover, &.selected{
                            background: lighten($color-green, 15%);
                            color: #000;
                        }
                    }
                }
            }
            &.mt{
                margin-top: 50px;
                border-top: solid 1px $color-white;
            }
        }
        &.meta-nav{
            position: absolute;
            top: 0;
            right: 0;
            border-bottom: 1px solid #efefef;
            border-left: 1px solid #efefef;
            background: $color-white;
            z-index: 10;

            ul {
                list-style: none;

                li{
                    display: inline-block;

                    svg{
                        height: 18px;
                        width: 18px;
                        margin: 0 $space 0 0;
                        fill: $color-darkgrey;
                        vertical-align: middle;
                    }

                    a, :link, :active, :visited{
                        position: relative;
                        display: inline-block;
                        padding: 15px 20px;
                        border-right: 1px solid #efefef;

                        &:hover, &.selected{
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
    .mob-menu {
        display: none;
        width: 40px;
        height: 45px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 999;

        span {
          display: block;
          position: absolute;
          height: 7px;
          width: 100%;
          background: $color-darkgrey;
          border-radius: 7px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s ease-in-out;

            &:nth-child(1) {
              top: 0px;
            }
            &:nth-child(2),&:nth-child(3) {
              top: 12px;
            }
            &:nth-child(4) {
              top: 24px;
            }
        }
        &.open{
            span{
                &:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}
@media(max-width:1024px){
    header{
        .nav-container{
            position: absolute;
            background: $color-white;
            height: auto;
            width: 100%;
            text-align: center;
            z-index: 998;
        }
        nav{
            &.main-nav{
                display: none;
            }
            &.meta-nav{
                display: none;
            }
        }
        .mob-menu {
            display: block;
        }
    }
}
@media(max-width:530px){
    header{
        #logo{
            padding: 20px;
            width: 190px;
        }
    }
}
*/
main {
  margin: 98px 0 0 0;
  margin-left: 13%;
  min-height: calc(100% - 200px);
}
main .inner {
  overflow: hidden;
}
main section {
  margin: 0 80px;
  margin-bottom: 20px;
}
main section.full {
  margin: 0;
}
main section.full .title {
  margin: 0 80px;
  margin-bottom: 20px;
}
main nav.breadcrumb-nav {
  position: absolute;
  top: 20px;
  margin: 0 0 0 80px;
}
main nav.breadcrumb-nav svg {
  height: 10px;
  width: 10px;
  fill: #c9c8c5;
  margin: 0 10px;
}
main ul {
  margin: 0;
  padding: 20px;
}

.title {
  margin-bottom: 50px;
}
.title h1 {
  font-family: "UniversLTPro-55Roman", sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
  color: #88bd24;
  font-size: 44px;
  max-width: 70%;
  min-width: 730px;
}
.title p {
  max-width: 70%;
  min-width: 800px;
  font-size: 20px;
  color: #666;
}

.col-row {
  display: flex;
}
.col-row .col-100 {
  margin-right: 0;
  width: 100%;
  position: relative;
}
.col-row .col-50 {
  margin-right: 40px;
  width: 50%;
  width: calc(50% - 20px);
  position: relative;
}
.col-row .col-70 {
  margin-right: 40px;
  width: 70%;
  width: calc(70% - 20px);
  position: relative;
}
.col-row .col-30 {
  /*margin-right: $space*2;*/
  width: 30%;
  width: calc(30% - 20px);
  position: relative;
}
.col-row .col-27 {
  margin-right: 40px;
  width: 27%;
  width: calc(27% - 20px);
  position: relative;
}
.col-row .col-60 {
  margin-right: 40px;
  width: 60%;
  width: calc(60% - 20px);
  position: relative;
}
.col-row .col-07 {
  margin-right: 20px;
  width: 7%;
  width: calc(7% - 20px);
  position: relative;
}
.col-row .col-50, .col-row .col-70, .col-row .col-30, .col-row .col-60, .col-row .col-27, .col-row .col-07 {
  /*
  &:nth-child(2){
      margin-right: 0;
  }*/
}
.col-row .col-50 img, .col-row .col-70 img, .col-row .col-30 img, .col-row .col-60 img, .col-row .col-27 img, .col-row .col-07 img {
  max-width: 100%;
}
.col-row .col-50 img.full, .col-row .col-70 img.full, .col-row .col-30 img.full, .col-row .col-60 img.full, .col-row .col-27 img.full, .col-row .col-07 img.full {
  width: 100%;
  display: block;
}
.col-row .col-50 p.spacer, .col-row .col-70 p.spacer, .col-row .col-30 p.spacer, .col-row .col-60 p.spacer, .col-row .col-27 p.spacer, .col-row .col-07 p.spacer {
  display: block;
  height: 50px;
}
.col-row .col-50 .bottom, .col-row .col-70 .bottom, .col-row .col-30 .bottom, .col-row .col-60 .bottom, .col-row .col-27 .bottom, .col-row .col-07 .bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.col-row .col-50.nmr, .col-row .col-70.nmr, .col-row .col-30.nmr, .col-row .col-60.nmr, .col-row .col-27.nmr, .col-row .col-07.nmr {
  margin-right: 0;
}

.steps-image {
  max-width: 100%;
  max-height: 600px;
  border: solid 1px #e5e5e5;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.learnCardsContainer {
  overflow: scroll;
  transition: 1s;
  padding-top: 10px;
  border-top: solid 1px #000000;
  margin-bottom: 0px;
}
.learnCardsContainer:last-of-type {
  border-bottom: solid 1px #000000;
  margin-bottom: 20px;
}
.learnCardsContainer .learnCardsTitle {
  cursor: pointer;
}
.learnCardsContainer .learnCardsTitle section:hover {
  background-color: #e9e9e9;
}
.learnCardsContainer .learnCardsTitle span {
  font-size: 25px;
}
.learnCardsContainer .learnCardsTitle svg {
  transition: 1s;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.learnCardsContent {
  display: none;
}

@media (max-width: 1679px) {
  main {
    margin-left: 17%;
  }
}
@media (max-width: 1449px) {
  main {
    margin-left: 20%;
  }
}
@media (max-width: 1179px) {
  main {
    margin-left: 22%;
  }
}
@media (max-width: 1023px) {
  main {
    margin-left: 0;
    margin-top: 40px;
  }
}
@media (max-width: 1024px) {
  main {
    margin-top: 140px;
  }
  main section {
    margin: 0 40px;
  }
  main section.full .title {
    margin: 0 30px;
  }
}
@media (max-width: 530px) {
  main section {
    margin: 0 20px;
  }
}
footer {
  background: #fafafa;
  margin: 20px 0 0 230px;
  transition: margin-left 0.2s ease;
  overflow: hidden;
  min-height: 90px;
  font-size: 14px;
  color: #737373;
}
footer .inner {
  overflow: hidden;
  margin: 20px auto;
  overflow: hidden;
  max-width: 1200px;
  padding: 0 20px;
}
footer .right {
  float: right;
}
footer #lang-nav {
  margin-bottom: 40px;
}
footer #lang-nav li {
  float: left;
  list-style: none;
  margin-right: 30px;
  font-size: 16px;
}
footer #lang-nav li svg {
  width: 18px;
  height: 18px;
}
footer #meta-nav li {
  float: left;
  list-style: none;
  margin-right: 20px;
}
footer #meta-nav li a {
  color: #737373;
}

@media (max-width: 1679px) {
  footer {
    margin-left: 17%;
  }
}
@media (max-width: 1449px) {
  footer {
    margin-left: 20%;
  }
}
@media (max-width: 1179px) {
  footer {
    margin-left: 22%;
  }
}
@media (max-width: 1023px) {
  footer {
    margin-left: 0;
  }
}
@media (max-width: 1023px) {
  footer .right {
    float: none;
    display: block;
  }
  footer .right ul {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  footer #lang-nav, footer #meta-nav {
    margin-bottom: 20px;
  }
}
.btn {
  display: inline-block;
  /*padding: $space/2 $space;*/
  padding: 5px 15px;
  background: #88bd24;
  border: solid 1px #88bd24;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
}
.btn:hover {
  background: #adde50;
  text-decoration: none;
}
.btn.btn-grey {
  background: #c9c8c5;
  border: solid 1px #c9c8c5;
}
.btn.btn-grey:hover {
  background: #eeeeed;
  text-decoration: none;
  color: #666;
}
.btn.btn-light {
  background: none;
  color: #666;
}
.btn.btn-light:hover {
  background: #eeeeed;
  text-decoration: none;
  color: #88bd24;
}
.btn.btn-warn {
  background: #c9c8c5;
  border: solid 1px #c9c8c5;
}
.btn.btn-warn:hover {
  background: #cc0000;
}
.btn.btn-small {
  padding: 5px 15px;
  font-size: 16px;
}

input.btn {
  padding: 7px 15px;
  font-size: 16px;
}

a.inlinebutton {
  color: #fff;
  cursor: pointer;
  height: 20px;
  padding: 2px 15px 2px 15px;
  background: #88c700;
  margin: 0 0 0 5px;
  line-height: 15px;
  display: inline-block;
}

a.inlinebutton span {
  color: #fff;
}

a.inlinebutton:hover {
  background: #94d31c;
}

.action-icon svg {
  width: 25px;
  height: 25px;
  fill: #88bd24;
}

a.admin {
  background: #999999;
}
a.admin:hover {
  background: #bdbdbd;
}

.dvgui-form-group {
  position: relative;
  margin: 0 0 20px 0;
  padding-top: 20px;
  width: 100%;
}
.dvgui-form-group .dvgui-text,
.dvgui-form-group .dvgui-select,
.dvgui-form-group .dvgui-radiogroup,
.dvgui-form-group .dvgui-checkboxgroup,
.dvgui-form-group .dvgui-documentupload,
.dvgui-form-group .dvgui-imageupload {
  display: block;
  width: 100%;
  padding: 5px 15px;
  border-radius: 0;
  border: solid 1px #c9c8c5;
  background: #fff;
  color: #666;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  box-shadow: none;
  position: relative;
  font-family: "UniversLTPro-45Light", sans-serif;
  /*
   		&.edited:not([readonly]),
  &:focus:not([readonly]) {
  	~ label {
  		color: $input-focus-label-font-color;  
  		left: 0; 
  		opacity: 1;
  	}
  }  */
}
.dvgui-form-group .dvgui-text ~ label,
.dvgui-form-group .dvgui-select ~ label,
.dvgui-form-group .dvgui-radiogroup ~ label,
.dvgui-form-group .dvgui-checkboxgroup ~ label,
.dvgui-form-group .dvgui-documentupload ~ label,
.dvgui-form-group .dvgui-imageupload ~ label {
  width: 100%;
  position: absolute;
  top: 26px;
  left: 15px;
  bottom: 0;
  pointer-events: none;
  font-size: 16px;
  transition: 0.2s ease all;
  color: #c9c8c5;
  line-height: 1.5;
  margin: 0;
}
.dvgui-form-group .dvgui-text ~ .help-block,
.dvgui-form-group .dvgui-select ~ .help-block,
.dvgui-form-group .dvgui-radiogroup ~ .help-block,
.dvgui-form-group .dvgui-checkboxgroup ~ .help-block,
.dvgui-form-group .dvgui-documentupload ~ .help-block,
.dvgui-form-group .dvgui-imageupload ~ .help-block {
  color: #666;
  display: none;
}
.dvgui-form-group .dvgui-text ~ .help-block:not(:empty),
.dvgui-form-group .dvgui-select ~ .help-block:not(:empty),
.dvgui-form-group .dvgui-radiogroup ~ .help-block:not(:empty),
.dvgui-form-group .dvgui-checkboxgroup ~ .help-block:not(:empty),
.dvgui-form-group .dvgui-documentupload ~ .help-block:not(:empty),
.dvgui-form-group .dvgui-imageupload ~ .help-block:not(:empty) {
  opacity: 1;
}
.dvgui-form-group .dvgui-text:focus:not([readonly]) ~ .help-block,
.dvgui-form-group .dvgui-select:focus:not([readonly]) ~ .help-block,
.dvgui-form-group .dvgui-radiogroup:focus:not([readonly]) ~ .help-block,
.dvgui-form-group .dvgui-checkboxgroup:focus:not([readonly]) ~ .help-block,
.dvgui-form-group .dvgui-documentupload:focus:not([readonly]) ~ .help-block,
.dvgui-form-group .dvgui-imageupload:focus:not([readonly]) ~ .help-block {
  color: #666;
  display: block;
  opacity: 1;
}
.dvgui-form-group .dvgui-text[readonly] ~ label, .dvgui-form-group .dvgui-text.edited ~ label, .dvgui-form-group .dvgui-text:focus:not([readonly]) ~ label, .dvgui-form-group .dvgui-text.focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-select[readonly] ~ label,
.dvgui-form-group .dvgui-select.edited ~ label,
.dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-select.focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-radiogroup[readonly] ~ label,
.dvgui-form-group .dvgui-radiogroup.edited ~ label,
.dvgui-form-group .dvgui-radiogroup:focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-radiogroup.focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-checkboxgroup[readonly] ~ label,
.dvgui-form-group .dvgui-checkboxgroup.edited ~ label,
.dvgui-form-group .dvgui-checkboxgroup:focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-checkboxgroup.focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-documentupload[readonly] ~ label,
.dvgui-form-group .dvgui-documentupload.edited ~ label,
.dvgui-form-group .dvgui-documentupload:focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-documentupload.focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-imageupload[readonly] ~ label,
.dvgui-form-group .dvgui-imageupload.edited ~ label,
.dvgui-form-group .dvgui-imageupload:focus:not([readonly]) ~ label,
.dvgui-form-group .dvgui-imageupload.focus:not([readonly]) ~ label {
  left: 0;
  color: #7f7d76;
  top: 0;
  font-size: 13px;
}
.dvgui-form-group .dvgui-text[disabled], .dvgui-form-group .dvgui-text[readonly],
.dvgui-form-group .dvgui-select[disabled],
.dvgui-form-group .dvgui-select[readonly],
.dvgui-form-group .dvgui-radiogroup[disabled],
.dvgui-form-group .dvgui-radiogroup[readonly],
.dvgui-form-group .dvgui-checkboxgroup[disabled],
.dvgui-form-group .dvgui-checkboxgroup[readonly],
.dvgui-form-group .dvgui-documentupload[disabled],
.dvgui-form-group .dvgui-documentupload[readonly],
.dvgui-form-group .dvgui-imageupload[disabled],
.dvgui-form-group .dvgui-imageupload[readonly] {
  cursor: not-allowed;
}
.dvgui-form-group .trumbowyg-box {
  margin: 0 auto;
}
.dvgui-form-group .trumbowyg-box ~ label {
  width: 100%;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  transition: 0.2s ease all;
  line-height: 1.5;
  margin: 0;
  left: 0;
  color: #7f7d76;
  top: 0;
  font-size: 13px;
}
.dvgui-form-group .dvgui-select {
  padding: 2px 30px 2px 10px;
  appearance: none;
  text-indent: -2px;
  outline: 0;
  appearance: none;
  text-indent: -2px;
  outline: 0;
}
.dvgui-form-group .dvgui-select ~ label {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='10' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23666666' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 18px bottom 12px;
}
.dvgui-form-group .dvgui-select.edited:not([readonly]) ~ label, .dvgui-form-group .dvgui-select:focus:not([readonly]) ~ label {
  background-position: right 8px bottom 12px;
}
.dvgui-form-group .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 13px;
}
.dvgui-form-group.child {
  margin-top: -35px;
}
.dvgui-form-group.child .dvgui-text[readonly] ~ label, .dvgui-form-group.child .dvgui-text.edited ~ label, .dvgui-form-group.child .dvgui-text:focus:not([readonly]) ~ label, .dvgui-form-group.child .dvgui-text.focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-select[readonly] ~ label,
.dvgui-form-group.child .dvgui-select.edited ~ label,
.dvgui-form-group.child .dvgui-select:focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-select.focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-radiogroup[readonly] ~ label,
.dvgui-form-group.child .dvgui-radiogroup.edited ~ label,
.dvgui-form-group.child .dvgui-radiogroup:focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-radiogroup.focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-checkboxgroup[readonly] ~ label,
.dvgui-form-group.child .dvgui-checkboxgroup.edited ~ label,
.dvgui-form-group.child .dvgui-checkboxgroup:focus:not([readonly]) ~ label,
.dvgui-form-group.child .dvgui-checkboxgroup.focus:not([readonly]) ~ label {
  font-size: 0;
}
.dvgui-form-group .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0;
  filter: alpha(opacity=0);
  font-size: 13px;
}
.dvgui-form-group .dvgui-documentupload-container,
.dvgui-form-group .dvgui-imageupload-container {
  display: flex;
}
.dvgui-form-group .dvgui-documentupload-container .documentupload-button,
.dvgui-form-group .dvgui-documentupload-container .imageupload-button,
.dvgui-form-group .dvgui-imageupload-container .documentupload-button,
.dvgui-form-group .dvgui-imageupload-container .imageupload-button {
  margin-right: 50px;
  overflow: hidden;
  max-height: 35px;
}
.dvgui-form-group .dvgui-documentupload-container .documentupload-result,
.dvgui-form-group .dvgui-documentupload-container .imageupload-result,
.dvgui-form-group .dvgui-imageupload-container .documentupload-result,
.dvgui-form-group .dvgui-imageupload-container .imageupload-result {
  max-width: 390px;
  width: calc(100% - 240px);
}
.dvgui-form-group .dvgui-documentupload-container .documentupload-item,
.dvgui-form-group .dvgui-documentupload-container .imageupload-item,
.dvgui-form-group .dvgui-imageupload-container .documentupload-item,
.dvgui-form-group .dvgui-imageupload-container .imageupload-item {
  height: 35px;
  margin-bottom: 5px;
}
.dvgui-form-group .dvgui-documentupload-container .documentupload-item p,
.dvgui-form-group .dvgui-documentupload-container .imageupload-item p,
.dvgui-form-group .dvgui-imageupload-container .documentupload-item p,
.dvgui-form-group .dvgui-imageupload-container .imageupload-item p {
  text-overflow: "...";
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 70px);
  display: inline-block;
  padding-top: 5px;
}
.dvgui-form-group .dvgui-documentupload-container .documentupload-delete,
.dvgui-form-group .dvgui-documentupload-container .imageupload-delete,
.dvgui-form-group .dvgui-imageupload-container .documentupload-delete,
.dvgui-form-group .dvgui-imageupload-container .imageupload-delete {
  margin-left: 20px;
  float: right;
}
.dvgui-form-group .dvgui-documentupload,
.dvgui-form-group .dvgui-imageupload {
  display: block;
  opacity: 0;
  position: absolute;
  top: 20px;
  left: 0;
  font-size: 50px !important;
  direction: ltr;
  cursor: pointer;
  width: 185px;
  height: 35px;
}
.dvgui-form-group .dvgui-documentupload ~ label,
.dvgui-form-group .dvgui-imageupload ~ label {
  top: 0;
  color: #7f7d76;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.dvgui-form-group .dvgui-documentupload .dvgui-upload-delete,
.dvgui-form-group .dvgui-imageupload .dvgui-upload-delete {
  margin-left: 20px;
  border: 1px solid #666;
  padding: 5px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  background: #c9c8c5;
  line-height: 10px;
  cursor: pointer;
}
.dvgui-form-group .dvgui-documentupload .dvgui-upload-delete:hover,
.dvgui-form-group .dvgui-imageupload .dvgui-upload-delete:hover {
  background: #88bd24;
}
.dvgui-form-group .dvgui-documentupload .note,
.dvgui-form-group .dvgui-imageupload .note {
  margin: 0;
  margin-left: 20px;
  flex-grow: 2;
}
.dvgui-form-group .dvgui-documentupload .loading,
.dvgui-form-group .dvgui-imageupload .loading {
  width: 100px;
  height: 30px;
  margin-left: 20px;
}
.dvgui-form-group .dvgui-documentupload .dvgui-imageupload-preview,
.dvgui-form-group .dvgui-imageupload .dvgui-imageupload-preview {
  height: 60px;
  vertical-align: top;
}
.dvgui-form-group .dvgui-radio {
  position: relative;
  display: flex;
  margin: 10px 0;
  /* handling click events */
  /* when radio is checked */
}
.dvgui-form-group .dvgui-radio.inline {
  display: inline-block;
  margin-right: 20px;
}
.dvgui-form-group .dvgui-radio input[type=radio] {
  visibility: hidden;
  position: absolute;
}
.dvgui-form-group .dvgui-radio label {
  cursor: pointer;
  padding-left: 30px;
  margin-bottom: 0;
  line-height: 21px;
  position: relative;
}
.dvgui-form-group .dvgui-radio label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.dvgui-form-group .dvgui-radio label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}
.dvgui-form-group .dvgui-radio label > .box {
  top: 0px;
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  z-index: 5;
  border: solid 1px #666;
}
.dvgui-form-group .dvgui-radio label > .check {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #666;
  opacity: 0;
  z-index: 6;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}
.dvgui-form-group .dvgui-radio label > span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}
.dvgui-form-group .dvgui-radio input[type=radio]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label,
.dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvgui-radio input[type=radio]:disabled ~ label > .box,
.dvgui-form-group .dvgui-radio input[type=radio][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvgui-radio input[type=radio]:disabled:checked ~ label > .check,
.dvgui-form-group .dvgui-radio input[type=radio][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvgui-radiogroup ~ label,
.dvgui-form-group .dvgui-checkboxgroup ~ label {
  top: 0;
  color: #7f7d76;
  left: 0;
  opacity: 1;
  font-size: 13px;
}
.dvgui-form-group .dvgui-checkbox {
  position: relative;
  display: flex;
  margin: 10px 0;
  /* handling click events */
  /* when checkbox is checked */
}
.dvgui-form-group .dvgui-checkbox:first-child {
  margin-top: 8px;
}
.dvgui-form-group .dvgui-checkbox ~ label {
  top: 0;
  font-size: 13px;
  color: #7f7d76;
  width: 100%;
  position: absolute;
  left: 0;
}
.dvgui-form-group .dvgui-checkbox.md-checkbox-inline {
  display: inline-block;
}
.form-inline .dvgui-form-group .dvgui-checkbox.md-checkbox-inline {
  margin-right: 20px;
  top: 3px;
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.dvgui-form-group .dvgui-checkbox label {
  cursor: pointer;
  padding-left: 30px;
}
.dvgui-form-group .dvgui-checkbox label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.dvgui-form-group .dvgui-checkbox label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}
.dvgui-form-group .dvgui-checkbox label > .box {
  top: 4px;
  height: 20px;
  width: 20px;
  z-index: 5;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
  border: solid 1px #666;
}
.dvgui-form-group .dvgui-checkbox label > .check {
  top: 0px;
  left: 6px;
  width: 10px;
  height: 20px;
  border: 2px solid #666;
  border-top: none;
  border-left: none;
  opacity: 0;
  z-index: 5;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.dvgui-form-group .dvgui-checkbox label > span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label,
.dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled ~ label > .box,
.dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvgui-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
.dvgui-form-group .dvgui-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.dvgui-form-group .dvForm_missed {
  background: #fde3e3;
}

@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 0.5;
  }
  70% {
    background: #88bd24;
    -webkit-transform: scale(1.25);
  }
}
@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 0.5;
  }
  70% {
    background: #88bd24;
    -moz-transform: scale(1.25);
  }
}
@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 0.5;
  }
  70% {
    background: #88bd24;
    transform: scale(1.25);
  }
}
.label-group label {
  width: 140px;
  flex: 0 0 auto;
}
.label-group span {
  font-weight: 400;
}
.label-group span small {
  font-size: 70%;
  font-weight: 400;
}

.lang-switch {
  display: none;
}
.lang-switch.lang-active {
  display: block;
}

.lang-select {
  border-bottom: solid 2px #88bd24;
}
.lang-select li {
  display: inline-block;
  margin-right: 10px;
  margin-left: 10px;
  padding: 4px 8px;
  background: #c9c8c5;
  width: 50px;
  text-align: center;
  cursor: pointer;
}
.lang-select li.selected {
  background: #88bd24;
  color: #fff;
}

.marker-radio {
  vertical-align: middle;
  margin-top: -7px;
}

.form-inline {
  display: inline-block;
}
.form-inline.w-25 {
  width: 25%;
}

.dvForm_missed {
  background: #fde3e3;
}

article.forum-item {
  margin-bottom: 20px;
}
article.forum-item header {
  padding: 5px 10px;
  background: #e5e5e5;
  height: auto;
  font-size: 16px;
}
article.forum-item header h2 {
  font-size: 18px;
  margin: 0;
  font-family: "UniversLTPro-55Roman", sans-serif;
}
article.forum-item header .smaller {
  font-size: 14px;
}
article.forum-item header .profilepic {
  width: 45px;
  height: 45px;
  background: #fff;
  float: right;
}
article.forum-item header .profilepic img, article.forum-item header .profilepic svg {
  width: 45px;
  height: 45px;
  border: 1px solid #abadb0;
}
article.forum-item footer {
  margin: 0 0 20px 0;
  padding: 10px 0;
  background: none;
  border-top: solid 1px #c9c8c5;
  height: auto;
  min-height: auto;
}
article.forum-item footer .footer-note {
  background: #d0e69a;
  padding: 10px;
  display: inline-block;
}
article.forum-item.inactive header {
  background: #d0e69a;
}
article .forum-content {
  margin: 20px 10px;
}
article .forum-content .bbquote {
  padding: 10px;
  background: #fafafa;
}
article .forum-content strong {
  font-family: "UniversLTPro-65Bold", sans-serif;
  font-weight: normal;
}
article .forum-content .forum-attachment {
  display: block;
  margin-bottom: 10px;
}
article .forum-content .forum-attachment svg {
  width: 25px;
  height: 25px;
  fill: #88bd24;
  vertical-align: middle;
  margin-right: 20px;
}

.forum_thema_my {
  display: inline-block;
  width: calc(100% - 185px);
}

.forum_beitrag_my_head {
  background: #e5e5e5;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.forum_beitrag_my {
  margin-left: 15px;
}

.bbstyles {
  overflow: hidden;
}
.bbstyles .bbstyle {
  background: #c9c8c5;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  display: inline-block;
}
.bbstyles .bbemoji {
  display: inline-block;
}
.bbstyles .bbemoji .emojione {
  margin-right: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}

.note {
  margin: 0 80px;
  border: 0;
  border-left: 5px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
}
.note.note-error {
  background: #ffcbca;
  border-left: 5px solid #a6000f;
}
.note.note-success {
  background: #caffca;
  border-left: 5px solid #13a600;
}
.note.note-warning {
  background: #fffdca;
  border-left: 5px solid #ffc71a;
}
.note.inline {
  margin-left: 0;
  margin-right: 0;
}

@media (max-width: 1024px) {
  .note {
    margin: 0 40px;
  }
}
@media (max-width: 530px) {
  main {
    margin: 0 40px;
  }
}
.stepnav {
  width: 100%;
  display: table;
  margin-bottom: 20px;
  margin-left: 13px;
}
.stepnav ul {
  display: table-row;
  margin-left: -3px;
}
.stepnav ul li div {
  width: 100%;
  display: table;
  margin: 20px 0 0 12.5px;
}
.stepnav li {
  display: table-cell;
  position: relative;
}
.stepnav li a.level2 {
  display: block;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #666;
  color: #fff;
  line-height: 32px;
  font-size: 16px;
  margin-top: 60px;
}
.stepnav li a.level2:after {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  background: #666;
  width: calc(100% - 25px);
  top: 77px;
  left: 25px;
  z-index: 11;
}
.stepnav li a b {
  position: absolute;
  background: #88bd24;
  width: 20px;
  height: 20px;
  display: block;
  top: 66px;
  left: 50%;
  z-index: 80;
  color: #fff;
  line-height: 22px;
}
.stepnav li span {
  display: block;
  text-align: center;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #c9c8c5;
  line-height: 50px;
  font-size: 24px;
  position: absolute;
  margin-left: -10px;
  top: 0;
}
.stepnav li span:after {
  content: "";
  height: 10px;
  width: 4px;
  position: absolute;
  background: #c9c8c5;
  top: 60px;
  left: 23px;
  z-index: 10;
  transform: translateY(-100%);
}
.stepnav li.done a {
  background: #88bd24;
}
.stepnav li.done a:after {
  background: #88bd24;
}
.stepnav li.done span {
  background: #dff2bb;
}
.stepnav li.done span:after {
  background: #dff2bb;
}
.stepnav li.last {
  width: 55px;
}
.stepnav li.last a {
  border-radius: 20%;
}
.stepnav li.last a svg {
  width: 16px;
  height: 16px;
  fill: #fff;
}
.stepnav li.last a:after {
  display: none;
}
.stepnav li.last span {
  border-radius: 20%;
  font-size: 12px;
}

.progressbar {
  width: 100%;
  display: table;
  margin-bottom: 20px;
}
.progressbar ul {
  display: table-row;
  margin-left: -3px;
}
.progressbar ul li div {
  width: 100%;
  display: table;
  margin: 20px 0 0 12.5px;
}
.progressbar li {
  display: table-cell;
  position: relative;
}
.progressbar li a {
  display: block;
  text-align: center;
  vertical-align: middle;
  border-radius: 20%;
  width: 30px;
  height: 30px;
  background: #666;
  color: #fff;
  line-height: 32px;
  font-size: 16px;
  margin-top: 60px;
}
.progressbar li a:after {
  content: "";
  height: 4px;
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  background: #666;
  width: calc(100% - 25px);
  top: 77px;
  left: 25px;
  z-index: 11;
}
.progressbar li.done a {
  background: #88bd24;
}
.progressbar li.done a:after {
  background: #88bd24;
}
.progressbar li.last {
  width: 55px;
}
.progressbar li.last a {
  border-radius: 20%;
}
.progressbar li.last a svg {
  width: 16px;
  height: 16px;
  fill: #fff;
}
.progressbar li.last a:after {
  display: none;
}

h3.lessons {
  font-size: 20px;
}
h3.lessons a {
  display: inline-block;
  padding: 5px 15px;
  background: #88bd24;
  border: 0;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin-right: 5px;
}
h3.lessons a:hover {
  background: #adde50;
  text-decoration: none;
}
h3.lessons a.selected {
  background: #c1e67b;
  color: #666;
  text-decoration: none;
}

#validation-relevance {
  width: 100%;
  max-width: 800px;
  height: 325px;
  margin-bottom: 20px;
  margin-top: 20px;
}
#validation-relevance .validation-relevance-step {
  height: 100%;
  background: #c9c8c5;
  float: left;
  margin-right: 1px;
  position: relative;
}
#validation-relevance .validation-relevance-step .validation-relevance-label-vertical {
  writing-mode: vertical-rl;
  color: #fff;
  padding: 10px calc(50% - 8px);
  overflow: hidden;
  white-space: nowrap;
}
#validation-relevance .validation-relevance-step .validation-relevance-label {
  background: #fff;
  position: absolute;
  top: -25px;
  width: 100%;
  height: 25px;
  text-align: center;
}
#validation-relevance .validation-relevance-step .validation-relevance-bar {
  background: #88bd24;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
#validation-relevance .validation-relevance-step .validation-relevance-bar .ui-resizable-handle {
  width: 100%;
  height: 25px;
  z-index: 20;
  position: absolute;
}
#validation-relevance .validation-relevance-step .validation-relevance-bar .handle {
  background: #666;
  width: 100%;
  height: 25px;
  top: 0;
  z-index: 10;
  text-align: center;
  position: absolute;
  cursor: ns-resize;
}
#validation-relevance .validation-relevance-step .validation-relevance-bar .handle svg {
  height: 15px;
  width: 15px;
  fill: #fff;
  margin: 5px auto;
  display: block;
  vertical-align: middle;
}
#validation-relevance .validation-relevance-step .validation-relevance-bar .handle .percent {
  color: #666;
  font-size: 14px;
  top: -20px;
  position: absolute;
  transform: translateX(-50%);
}
#validation-relevance .validation-relevance-step .validation-relevance-bar .percent-calc {
  display: none;
}

#validation-profile {
  width: 100%;
}
#validation-profile td.label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 0;
}
#validation-profile td.label.tr {
  text-align: right;
}
#validation-profile td.em {
  text-align: center;
  width: 8%;
  background: #666;
}
#validation-profile td.em.bg {
  background: #88bd24;
}
#validation-profile td.em.nobg {
  background: none;
}
#validation-profile td.sp {
  width: 5%;
  text-align: right;
  padding-right: 10px;
}
#validation-profile td.sp2 {
  width: 10%;
  text-align: right;
}

.validation-final {
  text-align: center;
}
.validation-final h1 {
  font-size: 24px;
  background: #c9c8c5;
  padding: 10px;
  margin: 30px auto 30px auto;
  max-width: 450px;
  color: #666;
}
.validation-final h1 .hl {
  font-size: 40px;
  color: #88bd24;
  vertical-align: middle;
}
.validation-final .cert {
  margin: 0 20% 30px 20%;
  font-size: 20px;
}
.validation-final p {
  margin: 20px 0;
}
.validation-final table {
  margin: auto;
}
.validation-final table td {
  text-align: left;
}

.certificates {
  margin: 20px 0;
}
.certificates a {
  line-height: 20px;
}
.certificates a svg {
  height: 20px;
  width: 20px;
  fill: #88bd24;
  vertical-align: middle;
}

.step-explanation-btn {
  margin-top: 20px;
}

.step-explanation {
  margin-top: 10px;
  margin-bottom: 20px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  padding: 0 20px;
}
.step-explanation.open {
  padding: 20px;
  max-height: 800px;
  border: solid 1px #88bd24;
  background: #eeeeed;
}

.lesson-lead {
  border: solid 1px #666;
  padding: 10px;
  margin: 10px 0 20px 0;
}

h4.lesson-title {
  color: #88bd24;
  font-size: 20px;
  margin: 10px 0 20px 0;
}

.lessonstep {
  font-size: 16px;
  padding: 10px 0;
  margin: 0;
  display: none;
}
.lessonstep.show {
  display: block;
}
.lessonstep ul {
  margin-left: 0;
  padding-left: 20px;
}

.prevnext-lesson:hover {
  color: #88bd24;
}
.prevnext-lesson i {
  color: #88bd24;
  font-size: 20px;
  vertical-align: middle;
}
.prevnext-lesson.fl i {
  margin-right: 15px;
}
.prevnext-lesson.fr i {
  margin-left: 15px;
}

.lessonstep-image {
  display: none;
}
.lessonstep-image.show {
  display: block;
}

.readnext {
  margin: 20px auto 0 auto;
}

.teasers {
  width: 100%;
  margin: 0;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
}
.teasers .teaser {
  display: inline-block;
  width: calc(33% - 17px);
  margin: 0 30px 40px 0;
  background: #c9c8c5;
  position: relative;
}
.teasers .teaser h2 {
  padding: 20px;
  background: #88bd24;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 0;
  font-size: 25px;
}
.teasers .teaser p {
  padding: 20px;
}
.teasers .teaser .btn {
  position: absolute;
  bottom: 20px;
  right: 20px;
}
.teasers .teaser ul {
  padding: 0 20px 20px 20px;
  margin-left: 20px;
}
.teasers .teaser:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #ccc;
  border-width: 25px;
  margin-left: -25px;
}
.teasers .teaser:nth-child(3) {
  margin-right: 0;
}

@media (max-width: 1600px) {
  .teasers .teaser h2 {
    font-size: 20px;
  }
}
@media (max-width: 1350px) {
  .teasers .teaser {
    width: 30%;
    width: calc(33.3% - 20px);
  }
  .teasers .teaser:nth-child(3) {
    margin-right: 0;
  }
  .teasers .teaser:nth-child(4) {
    margin-right: 30px;
  }
}
@media (max-width: 800px) {
  .teasers .teaser {
    width: 48%;
    width: calc(50% - 15px);
  }
  .teasers .teaser:nth-child(2n+2) {
    margin-right: 0;
  }
  .teasers .teaser:nth-child(3) {
    margin-right: 30px;
  }
}
@media (max-width: 530px) {
  .teasers .teaser {
    width: 100%;
  }
  .teasers .teaser:nth-child(1n+1) {
    margin-right: 0;
  }
}
.tiles {
  overflow: hidden;
  width: 100%;
}
.tiles .tile {
  display: block;
  width: 24.4%;
  margin: 0.3%;
  height: 200px;
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-color: #cbea90;
}
.tiles .tile h2 {
  max-width: calc(100% - 80px);
  min-height: 30px;
  bottom: 40px;
  left: 40px;
  background: #fff;
  position: absolute;
  color: #666;
  padding: 20px;
  font-size: 20px;
  font-weight: 400;
  z-index: 10;
}
.tiles .tile p {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #88bd24;
  top: 0;
  left: 0;
  padding: 20px;
  opacity: 0.5;
  z-index: 5;
}
.tiles .tile:hover p {
  display: block;
}
.tiles.large .tile {
  display: block;
  width: 32.7%;
  margin: 0.3%;
  height: 300px;
}

@media (max-width: 1600px) {
  .tiles .tile h2 {
    font-size: 18px;
  }
}
@media (max-width: 1350px) {
  .tiles .tile {
    width: 32.7%;
  }
}
@media (max-width: 1350px) {
  .tiles.large .tile {
    width: 49.3%;
  }
}
@media (max-width: 800px) {
  .tiles .tile {
    width: 49.3%;
  }
}
@media (max-width: 680px) {
  .tiles.large .tile {
    width: 99.3%;
  }
}
@media (max-width: 530px) {
  .tiles .tile {
    width: 99.3%;
  }
}
#maps-container {
  height: 100%;
  width: 100%;
  background: #fff;
}

#maps-overlay-bg {
  background: #222222;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1008;
  display: none;
}

#maps-overlay {
  background: #fff;
  width: 620px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 1009;
  display: none;
  overflow: auto;
  max-height: 90%;
}
#maps-overlay h2 {
  font-size: 25px;
}

#maps-overlay-inner {
  margin: 20px;
  overflow: hidden;
}
#maps-overlay-inner a, #maps-overlay-inner a:link, #maps-overlay-inner a:active, #maps-overlay-inner a:visited {
  color: #88bd24;
}
#maps-overlay-inner a:hover {
  text-decoration: underline;
}
#maps-overlay-inner a.btn:hover {
  text-decoration: none;
}
#maps-overlay-inner p {
  margin-bottom: 20px;
}
#maps-overlay-inner h1 {
  font-size: 24px;
  color: #666;
  margin: 0 0 20px 0;
}
#maps-overlay-inner h2 {
  font-size: 20px;
  color: #666;
  margin: 0 0 20px 0;
}
#maps-overlay-inner h3 {
  font-size: 18px;
  color: #666;
  margin: 0 0 20px 0;
}

.maps-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  padding: 10px 15px 10px 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: 2px;
  z-index: 980;
  background: #fff;
  font-size: 14px;
}

.maps-marker-info {
  vertical-align: middle;
  margin-right: 10px;
  height: 20px;
  width: 20px;
}

.maps-switch {
  position: absolute;
  top: 80px;
  left: 10px;
  z-index: 999;
}

.maps-switch li {
  list-style: none;
}

.maps-switch li a {
  background: #88bd24;
  color: #000;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 15px;
  display: block;
  margin-top: 10px;
}

.maps-new-poi, .maps-admin {
  position: absolute;
  top: 10px;
  left: 70px;
  background: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding: 8px 15px;
  z-index: 999;
  font-size: 16px;
  cursor: pointer;
}

.maps-new-poi {
  left: 210px;
}

.maps-tooltip-icon {
  height: 16px;
  width: 16px;
  fill: #666;
  vertical-align: middle;
  margin-left: 5px;
}

#history {
  position: relative;
  left: auto;
  top: auto;
  width: 100%;
  height: auto;
  background: #fff;
}
#history.fullscreen {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  padding: 10px;
}

#history-map {
  width: 100%;
  min-height: 50px;
  background: #fff;
  position: relative;
}
#history-map .hl {
  font-size: 16px;
  background: none;
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  /*
  padding: 20px 0 0 20px;
  white-space:nowrap;
  position: relative;
  backface-visibility: */
}
#history-map .hl:before {
  display: none;
}
#history-map .timeline {
  background: rgba(255, 255, 255, 0.7);
  z-index: 999;
}

#history-nav {
  width: 100%;
  margin-bottom: 20px;
}
#history-nav i {
  width: 25px;
  display: inline-block;
}
#history-nav #history-controls {
  float: right;
}
#history-nav #history-controls i {
  width: auto;
  background-color: #c9c8c5;
  border-radius: 0;
  color: #fff;
  margin: 0 5px 5px 0;
  padding: 5px;
  cursor: pointer;
  font-size: 23px;
}
#history-nav #history-controls i.compress {
  display: none;
}
#history-nav .history-check {
  display: inline-block;
  padding: 5px 15px;
  background: #88bd24;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  margin: 0 5px 5px 0;
}

#profileimg {
  width: 100%;
}
#profileimg img {
  border: solid 1px #c9c8c5;
  width: 300px;
  height: 300px;
}
#profileimg svg {
  border: solid 1px #c9c8c5;
  width: 300px;
  height: 300px;
  fill: #c9c8c5;
}

.downloads h3 {
  padding: 5px 10px;
  background: #c9c8c5;
  font-size: 18px;
  cursor: pointer;
}
.downloads h3 span {
  float: right;
}
.downloads h3 svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  fill: #666;
  margin-right: 20px;
}
.downloads .tog-container {
  display: none;
}
.downloads article {
  margin-bottom: 10px;
}
.downloads article .downloads-row {
  display: flex;
}
.downloads article .downloads-row a {
  margin-left: 20px;
}
.downloads article .downloads-row a svg {
  width: 40px;
  height: 40px;
  fill: #88bd24;
}
.downloads article .downloads-row .downloads-icon {
  flex: 0 0 50px;
  margin-left: 0;
}
.downloads article .downloads-row h4 {
  flex-grow: 1;
}
.downloads article .tog-detail-container {
  display: none;
  margin-left: 70px;
}
.downloads article .tog-detail-container .downloads-detail {
  flex-grow: 1;
}
.downloads article .tog-detail-container .downloads-detail p {
  display: flex;
  margin-bottom: 10px;
}
.downloads article .tog-detail-container .downloads-detail p span {
  flex: 0 0 130px;
}
.downloads article .tog-detail-container .flex {
  display: flex;
}
.downloads article .tog-detail-container .flex img {
  margin: 0 20px 0 0;
  border: solid 1px #c9c8c5;
}
.downloads article .tog-detail-container .downloads-edit a {
  margin-left: 20px;
}

.meta-row {
  overflow: hidden;
  margin-bottom: 20px;
}
.meta-row .dvgui-form-group {
  margin-top: -20px;
  margin-bottom: 10px;
}
.meta-row .btn {
  margin-bottom: 20px;
}

#belvoto {
  width: 100%;
  height: 100vh;
  height: calc(100vh - 125px);
  margin-top: 1px;
}

.dossiers .block {
  margin-bottom: 30px;
}
.dossiers .block h2 {
  margin: 0 0 15px 0;
  color: #666;
  font-size: 24px;
  cursor: pointer;
}

.toggle-this.closed {
  display: none;
}

.elearning .solution,
.generator .solution {
  display: none;
  border: solid 1px #88bd24;
  margin-bottom: 20px;
  padding: 20px;
}
.elearning .solution.show,
.generator .solution.show {
  display: block;
}
.elearning .solution .red,
.generator .solution .red {
  color: #cc0000;
}
.elearning .solution .green,
.generator .solution .green {
  color: #009900;
}
.elearning .answer,
.generator .answer {
  border: solid 1px #666;
  margin-bottom: 20px;
  padding: 20px;
  background: #e5e5e5;
}
.elearning .generator-handle,
.generator .generator-handle {
  background: #e5e5e5;
  width: 140px;
  padding: 0 10px 10px 10px;
  margin-left: 20px;
  cursor: pointer;
}

.trainingstepsbar {
  margin-bottom: 40px;
}
.trainingstepsbar ul {
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.trainingstepsbar ul li {
  display: block;
  width: 20%;
  float: left;
  text-align: center;
  background: #E5F2C6;
  margin-right: 10px;
}
.trainingstepsbar ul li.step1 {
  background: #d0e69a;
}
.trainingstepsbar ul li.step2 {
  background: #c1f24b;
}
.trainingstepsbar ul li.step3 {
  background: #88bd24;
}
.trainingstepsbar ul li a {
  display: block;
  font-size: 20px;
  padding: 20px 20px 0 20px;
}
.trainingstepsbar ul li span {
  display: block;
  font-size: 12px;
  padding: 0 20px 20px 20px;
}

.unterkapitel-title {
  padding: 10px;
  margin-bottom: 10px;
  font-family: "UniversLTPro-65Bold", sans-serif;
  font-weight: 400;
  color: #000;
}
.unterkapitel-title svg {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  fill: #333;
  margin-right: 20px;
}

.unterkapitel-container {
  overflow: hidden;
}

table.dataTable thead th, table.dataTable thead td {
  text-align: left;
  padding: 8px 10px;
  font-size: 14px;
  border-bottom: solid 2px #c9c8c5;
}
table.dataTable thead .sorting {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23c6d2d7' fill-rule='evenodd' opacity='1' d='M 1.6364142,11.501244 H 13.273234 c 1.046337,0 1.569505,1.266358 0.831203,2.004662 l -5.8184106,5.81841 c -0.4596056,0.459606 -1.2027973,0.459606 -1.6575141,0 L 0.80521285,13.505906 C 0.06691005,12.767602 0.59007785,11.501244 1.6364142,11.501244 Z M 14.104437,6.3673518 8.2860264,0.54894112 c -0.4596056,-0.45960548 -1.2027973,-0.45960548 -1.6575141,0 L 0.80521285,6.3673518 C 0.06691005,7.1056545 0.59007785,8.3720142 1.6364142,8.3720142 H 13.273234 c 1.046337,0 1.569505,-1.2663597 0.831203,-2.0046624 z'/%3E%3C/svg%3E");
}
table.dataTable thead .sorting_asc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2397a5ac' fill-rule='evenodd' opacity='1' d='M 14.104437,6.3673518 8.2860264,0.54894112 c -0.4596056,-0.45960548 -1.2027973,-0.45960548 -1.6575141,0 L 0.80521285,6.3673518 C 0.06691005,7.1056545 0.59007785,8.3720142 1.6364142,8.3720142 H 13.273234 c 1.046337,0 1.569505,-1.2663597 0.831203,-2.0046624 z'/%3E%3C/svg%3E");
}
table.dataTable thead .sorting_desc {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='20' viewBox='0 0 20 15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2397a5ac' fill-rule='evenodd' opacity='1' d='M 1.6364142,11.501244 H 13.273234 c 1.046337,0 1.569505,1.266358 0.831203,2.004662 l -5.8184106,5.81841 c -0.4596056,0.459606 -1.2027973,0.459606 -1.6575141,0 l -5.82329945,-5.81841 c -0.7383028,-0.738304 -0.215135,-2.004662 0.83120135,-2.004662 z'/%3E%3C/svg%3E");
}
table.dataTable tbody td, table.dataTable tbody th {
  padding: 5px 10px;
}
table.dataTable tbody td.txt-r, table.dataTable tbody th.txt-r {
  text-align: right;
}
table.dataTable tfoot th, table.dataTable tfoot td {
  text-align: left;
  padding: 8px 10px;
  font-size: 14px;
  border-top: solid 2px #c9c8c5;
}
table.dataTable.no-footer {
  border-bottom: solid 2px #c9c8c5;
}
table.dataTable .order-param {
  display: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background: #fafafa;
  padding: 5px 10px;
  border: 0;
  color: #333;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  background: #e1e1e1;
  border: 0;
  color: #333 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  background: #666;
  border: 0;
}
.dataTables_wrapper .dataTables_filter {
  font-size: 14px;
}
.dataTables_wrapper .dataTables_filter input {
  display: inline-block;
  border-radius: 0;
  padding: 5px 10px;
  border: #999 solid 1px;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
}
.dataTables_wrapper .dataTables_length {
  font-size: 14px;
}
.dataTables_wrapper .dataTables_length select {
  display: inline-block;
  border-radius: 0;
  border: #999 solid 1px;
  background: #fff;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  box-shadow: none;
  padding: 4px 30px 4px 10px;
  appearance: none;
  text-indent: -2px;
  outline: 0;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='20' height='15' viewBox='7 10 10 5' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2397a5ac' fill-rule='evenodd' opacity='1' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px bottom 5px;
}
.dataTables_wrapper .dataTables_info {
  font-size: 14px;
}
.dataTables_wrapper .dataTables_paginate {
  font-size: 14px;
}

.table1 td {
  padding: 2px 8px;
  vertical-align: top;
}
.table1 td.bg-grey {
  background: #c9c8c5;
}
.table1 td.bg-lightgrey {
  background: #efefef;
}

.material-title {
  padding: 10px;
  margin-bottom: 10px;
  font-family: "UniversLTPro-65Bold", sans-serif;
  font-weight: 400;
  color: #000;
}
.material-title svg {
  height: 20px;
  width: 20px;
  vertical-align: middle;
  fill: #333;
  margin-right: 20px;
}

.material-container {
  overflow: hidden;
}
.material-container .material-item {
  float: left;
  margin: 0 10px 10px 0;
  display: flex;
  padding: 10px;
  background: #e5e5e5;
}
.material-container .material-item.published {
  background: #dff2bb;
}
.material-container .material-item .col {
  margin-right: 10px;
  padding: 10px;
}
.material-container .material-item .col.left {
  background: #fff;
}