.material-title{
    padding: 10px;
    margin-bottom: 10px;
    font-family: $font-bold;
    font-weight: 400;
    color: $color-black;
    
    svg{
        height: 20px;
        width: 20px;
        vertical-align: middle;
        fill: $color-lightblack;
        margin-right: 20px;
    }
}
.material-container{
    overflow: hidden;
    
    .material-item{
        float: left;
        margin: 0 10px 10px 0;
        display: flex;
        padding: 10px;
        background: $color-midgrey;
        
        &.published{
            background: lighten($color-green,40%);            
        }
        .col{
            margin-right: 10px;
            padding: 10px;
            &.left{
                background: $color-white;
            }

        }
    }
}