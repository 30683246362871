.btn{
    display: inline-block;
    /*padding: $space/2 $space;*/
    padding: 5px 15px;
    background: $color-green;
    border: solid 1px $color-green;
    color: #fff;
    cursor: pointer;
    text-decoration: none;
    
    &:hover{
        background: lighten($color-green, 15%);
        text-decoration: none;
    }
    
    &.btn-grey{
        background: $color-grey;
        border: solid 1px $color-grey;
        &:hover{
            background: lighten($color-grey, 15%);
            text-decoration: none;
            color: $color-darkgrey
        }
    }
    &.btn-light{
        background: none;
        color: $color-darkgrey;
            
        &:hover{
            background: lighten($color-grey, 15%);
            text-decoration: none;
            color: $color-green
        }
    }
    &.btn-warn{
       background: $color-grey;
       border: solid 1px $color-grey; 
       
       &:hover {
           background: $color-red;
           
       }
    }
    
    &.btn-small{
        padding: 5px 15px;
        font-size: 16px;
    }
}
input.btn{
    padding: 7px 15px;
    font-size: 16px;
}


a.inlinebutton {
    color: #fff;
    cursor: pointer;
    height: 20px;
    padding: 2px 15px 2px 15px;
    background: #88c700;
    margin: 0 0 0 5px;
    line-height: 15px;
    display: inline-block;
}
a.inlinebutton span {
    color: #fff;
}
a.inlinebutton:hover {
    background:#94d31c;
}
.action-icon{
    svg {
        width: 25px;
        height: 25px;
        fill: $color-green;
    }
}

a.admin {
    background: #999999; 

    &:hover {
        background: #bdbdbd;
    }
}


