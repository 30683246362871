
header{
    margin: 0;
    height: 0.01px;

    .nav-container{
        position: fixed;
        z-index: 99;
        left: 0;
        top: 0;
        background: $color-green;
        height: 100%;
        overflow-y: auto;
        width: 13%;

        .leftcol{
            padding: 0 30px;
            width: 100%;

            .btn{
                display: block;
                background: $color-green;
                color: $color-white;
                text-align: center;
                padding: 5px 20px;
                margin: 0 0 10px 0;
            }            
        }
    }
    #logo{
        background: #fff;
        display: block;
        padding: 40px;
        padding-bottom: 50px;
        width: 100%;
        text-align: center;

        img{
            width: 150px;
            height: 80px;
        }
    }
    .userinfo{
        background: lighten($color-green,20%);
        padding: $space/2 $space;
        margin: $space;

        h2{
            font-size: 18px;
            color: $color-black;
            margin: 0 0 $space/2 0;
        }
        .infotable{
            margin: $space/2 0;
            td{
                font-size: 14px;
                font-family: $font-bold;
                .ove{
                    width: 120px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    display: block;
                }
                &.label{
                    font-family: $font-light;
                    font-size: 12px;
                    padding-right: 10px;
                }
            }
        }
    }
    nav{
        font-size: 16px;

        &.main-nav{
            ul {
                list-style: none;

                li{
                    a, :link, :active, :visited{
                        padding: $space;
                        display: block;
                        color: $color-white;
                        position: relative;

                        &:hover, &.selected{
                            &::after {
                                transform: scaleX(1);
                            }
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            transform: scaleX(0);
                            background-color: rgba(255, 255, 255, 0.15);
                            z-index: -1;
                            transition: 400ms linear all;
                        }
                    }
                    border-bottom: 2px solid rgba(255,255,255,.2);
                }
            }
            &.mt{
                margin-top: 50px;
                border-top: 2px solid rgba(255,255,255,.2);
            }
        }
        &.mobile-meta{
            display: none;
            background-color: darken($color-green, 10%);

            ul{
                display: flex;

                li{
                    width: auto;
                    flex-grow: 1;
                    a{
                        text-align: center;
                    }
                    border-bottom: 0;
                }
            }
        }
        &.meta-nav{
            position: absolute;
            top: 0;
            right: 0;
            border-bottom: 1px solid #efefef;
            border-left: 1px solid #efefef;
            background: $color-white;
            z-index: 10;

            ul {
                list-style: none;

                li{
                    display: inline-block;

                    svg{
                        height: 18px;
                        width: 19px;
                        margin: 0 $space 0 0;
                        fill: $color-darkgrey;
                        vertical-align: middle;
                    }

                    a, :link, :active, :visited{
                        position: relative;
                        display: inline-block;
                        padding: 15px 20px;
                        border-right: 1px solid #efefef;

                        &:hover, &.selected{
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
}
.login{
    header{
        nav{
            &.meta-nav{
                right: 85px;
            }
        }
    }
}
.mob-menu{
    display: none;

}

@media(max-width:1679px){
    header{
        .nav-container{
            width: 17%;
        }
    }
}

@media(max-width:1449px){
    header{
        .nav-container{
            width: 20%;
        }
    }
}

@media(max-width:1179px){
    header{
        .nav-container{
            width: 22%;
        }
    }
}

@media(max-width:1023px){
    header{
        min-height: 75px;

        .nav-container{
            width: 100%;
            background: $color-white;
            height: 75px;
            overflow: hidden;

            #logo{
                padding: 8px 0 3px 13px;
                text-align: left;
                width: 90%;
                width: calc(100% - 85px);

                img{
                    width: auto;
                    height: 56px;
                }
            }
        }

        nav{
            &.meta-nav{
                display: none;
            }
            &.mobile-meta{
                display: block;
            }
        }
        .mob-menu{
            display: block;
            position: fixed;
            right: 0;
            top: 0;
            padding: 25px;
            z-index: 101;
        }
    }
    .mob-nav-open{
        overflow: hidden !important;

        header{
            .nav-container{
                height: 100%;
                background: $color-green;
            }
        }
    }
}


/*
header{
    
    .nav-container{
        background: $color-green;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        width: 230px;
    }
    #logo{
        background: #fff;
        display: block;
        padding: 20px 40px;
        width: 230px;
        
        img{
            width: 150px;
            height: 80px;
        }
    }
    
    nav{
        font-size: 16px;

        &.main-nav{
            background: $color-green;
            ul {
                list-style: none;

                li{
                    a, :link, :active, :visited{
                        padding: $space;
                        border-bottom: solid 1px $color-white;
                        display: block;
                        color: #fff;
                        background: $color-green;

                        &:hover, &.selected{
                            background: lighten($color-green, 15%);
                            color: #000;
                        }
                    }
                }
            }
            &.mt{
                margin-top: 50px;
                border-top: solid 1px $color-white;
            }
        }
        &.meta-nav{
            position: absolute;
            top: 0;
            right: 0;
            border-bottom: 1px solid #efefef;
            border-left: 1px solid #efefef;
            background: $color-white;
            z-index: 10;

            ul {
                list-style: none;

                li{
                    display: inline-block;

                    svg{
                        height: 18px;
                        width: 18px;
                        margin: 0 $space 0 0;
                        fill: $color-darkgrey;
                        vertical-align: middle;
                    }

                    a, :link, :active, :visited{
                        position: relative;
                        display: inline-block;
                        padding: 15px 20px;
                        border-right: 1px solid #efefef;

                        &:hover, &.selected{
                            color: $color-green;
                        }
                    }
                }
            }
        }
    }
    .mob-menu {
        display: none;
        width: 40px;
        height: 45px;
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 999;
        
        span {
          display: block;
          position: absolute;
          height: 7px;
          width: 100%;
          background: $color-darkgrey;
          border-radius: 7px;
          opacity: 1;
          left: 0;
          transform: rotate(0deg);
          transition: .25s ease-in-out;

            &:nth-child(1) {
              top: 0px;
            }
            &:nth-child(2),&:nth-child(3) {
              top: 12px;
            }
            &:nth-child(4) {
              top: 24px;
            }
        }
        &.open{
            span{
                &:nth-child(1) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }
}
@media(max-width:1024px){
    header{
        .nav-container{
            position: absolute;
            background: $color-white;
            height: auto;
            width: 100%;
            text-align: center;
            z-index: 998;
        }
        nav{
            &.main-nav{
                display: none;
            }
            &.meta-nav{
                display: none;
            }
        }
        .mob-menu {
            display: block;
        }
    }
}
@media(max-width:530px){
    header{
        #logo{
            padding: 20px;
            width: 190px;
        }
    }
}
*/