.note{
    margin: 0 80px;
    border: 0;
    border-left: 5px solid #ccc;
    padding: $space;
    margin-bottom: $space;
    
    &.note-error{
        background: #ffcbca;
        border-left: 5px solid #a6000f;
    }
    &.note-success{
        background: #caffca;
        border-left: 5px solid #13a600;
    }
    &.note-warning{
        background: #fffdca;
        border-left: 5px solid #ffc71a;
    }
    &.inline{
        margin-left: 0;
        margin-right: 0;
    }
} 
@media(max-width:1024px){
    .note{
        margin: 0 40px;
    }
}
@media(max-width:530px){
    main{
        margin: 0 40px;
    }
}