.toggle-this{
    &.closed{
        display: none;
    }
}
.elearning,
.generator{
    .solution{
        display: none;
        border: solid 1px $color-green;
        margin-bottom: $space;
        padding: $space;
        
        &.show{
            display: block;
        }
        
        .red{
            color: $color-red;
        }
        .green{
            color: $color-darkgreen;
        }
    }
    .answer{
        border: solid 1px $color-darkgrey;
        margin-bottom: $space;
        padding: $space;
        background: $color-midgrey;
    }
    .generator-handle{
        background: $color-midgrey;
        width: 140px;
        padding: 0 10px 10px 10px;
        margin-left: 20px;
        cursor: pointer;
    }
}
.trainingstepsbar{
    margin-bottom: $space*2;
    ul{
        padding: 0;
        margin: 0;
        overflow: hidden;
        
        li{
            display: block;
            width: 20%;
            float: left;
            text-align: center;
            background: #E5F2C6;
            margin-right: 10px;
            
            &.step1{
                background: #d0e69a;
            }
            &.step2{
                background: #c1f24b;
            }
            &.step3{
                background: #88bd24;
            }
            
            a{
                display: block;
                font-size: 20px;
                padding: $space $space 0 $space;
            }
            span{
                display: block;
                font-size: 12px; 
                padding: 0 $space $space $space
            }
        }
    }
}
.unterkapitel-title{
    padding: 10px;
    margin-bottom: 10px;
    font-family: $font-bold;
    font-weight: 400;
    color: $color-black;
    
    svg{
        height: 20px;
        width: 20px;
        vertical-align: middle;
        fill: $color-lightblack;
        margin-right: 20px;
    }
}
.unterkapitel-container{
    overflow: hidden;
}