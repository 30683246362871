.downloads {
    h3{
        padding: 5px 10px;
        background: $color-grey;
        font-size: 18px;
        cursor: pointer;
        span{
            float: right;
        }
        svg{
            width: 18px;
            height: 18px;
            vertical-align: middle;
            fill: $color-darkgrey;
            margin-right: 20px;
        }
    }
    .tog-container{
        display: none;
    }
    article{
        margin-bottom: 10px;
        .downloads-row{
            display: flex;
            a{
                margin-left: 20px;
                svg{
                    width: 40px;
                    height: 40px;
                    fill: $color-green;
                }
            }
            .downloads-icon{
                flex: 0 0 50px;
                margin-left: 0;
            }
            h4{
                flex-grow: 1;
            }
        }
        .tog-detail-container{
            display: none;
            margin-left: 70px;
            .downloads-detail{
                flex-grow: 1;
                p{
                    display: flex;
                    margin-bottom: 10px;
                    span{
                        flex: 0 0 130px;
                    }
                }
            }
            .flex{
                display: flex;
                img{
                    margin: 0 20px 0 0;
                    border: solid 1px $color-grey;
                }
            }
            .downloads-edit{
                a {
                    margin-left: 20px;
                }
            }
        }
    }
}