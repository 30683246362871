#profileimg {
    width: 100%;
    
    img {
        border: solid 1px $color-grey;
        width: 300px;
        height: 300px;
    }
    svg {
        border: solid 1px $color-grey;
        width: 300px;
        height: 300px;
        fill: $color-grey;
    }
}