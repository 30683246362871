.dossiers {
    .block{
        margin-bottom: 30px;
        
        h2{
            margin: 0 0 15px 0;
            color: $color-darkgrey;
            font-size: 24px;
            cursor: pointer;
        }
    }
}