* {
    box-sizing: border-box;
    @include reset_spaces;
}
html{
    width: 100%;
    height: 100%;
}

body {
    @include reset_spaces;
    @include text;
    background: $background-color;
    font-family: $font-light;
    font-size: $font-size;
    color: $color-darkgrey;
    width: 100%;
    height: 100%;
} 



h1{
    margin-bottom: $space;
    color: $color-green;
    font-size: 35px;
}
h2{
    margin-bottom: $space;
    color: $color-darkgrey;
    font-size: 30px;
}
h3{
    margin-bottom: $space;
    color: $color-darkgrey;
    font-size: 25px;
}
.fr{
    float: right;
}
.fl{
    float: left;
}
.ul{
    text-decoration: underline;
}
.sb{
    padding-bottom: 50px;
}
.bt{
    border-top: solid 1px $color-mediumgrey;
    margin-top: 30px;
    padding-top: 30px;
}
.bb{
    border-bottom: solid 1px $color-mediumgrey;
    margin-bottom: 30px;
    padding-bottom: 30px;
}
.tc{
    text-align: center;
}
.tr{
    text-align: right;
}
p{
    margin-bottom: $space;
}
.mb{
    margin-bottom: $space;
}
.ml{
    margin-left: $space;
}
.mr{
    margin-right: $space;
}
.mt{
    margin-top: $space;
}
.mt2{
    margin-top: $space*2;
}
.mt3{
    margin-top: $space*3;
}
.oh{
    overflow: hidden;
}
.tog{
    cursor: pointer;
}
