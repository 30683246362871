.teasers{
    width: 100%;
    margin: 0;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    
    .teaser{
        display: inline-block;
        width: calc(33% - 17px);
        margin: 0 30px 40px 0;
        background: $color-grey;
        position: relative;
        
        h2{
            padding: $space;
            background: $color-green;
            color: #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-bottom: 0;
            font-size: 25px;
        }
        p{
            padding: $space;
        }
        
        .btn{
            position: absolute;
            bottom: $space;
            right: $space;
        }
        ul{
            padding: 0 $space $space $space;
            margin-left: $space;
        }
        &:after {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(204, 204, 204, 0);
            border-top-color: #ccc;
            border-width: 25px;
            margin-left: -25px;
        }
        &:nth-child(3){
            margin-right: 0;
        }
    }
}
@media(max-width:1600px){
    .teasers{
        .teaser{
            h2{
                font-size: 20px;
            }
        }
    }

}
@media(max-width:1350px){
    .teasers{
        .teaser{
            width: 30%;
            width: calc(33.3% - 20px);
            &:nth-child(3){
                margin-right: 0;
            }
            &:nth-child(4){
                margin-right: 30px;
            }
        }
    }

}
@media(max-width:800px){
    .teasers{
        .teaser{
            width: 48%;
            width: calc(50% - 15px);
            &:nth-child(2n+2){
                margin-right: 0;
            }
            &:nth-child(3){
                margin-right: 30px;
            }
        }
    }

}
@media(max-width:530px){
    .teasers{
        .teaser{
            width: 100%;
            &:nth-child(1n+1){
                margin-right: 0;
            }
        }
    }

}