.dv-lazyload{
    opacity: 0.3;
}


#dv_tooltip{
    display: none;
    position: absolute;
    z-index: 1500;
}
#dv_tooltip div{
    padding: 8px 15px;
    background: #666;
    box-shadow: 0 5px 5px #ccc;
    -moz-box-shadow: 0 5px 5px #ccc;
    -webkit-box-shadow: 0 5px 5px #ccc;
    border-radius: 4px;
    color: #fff;
    overflow: hidden;
    /*text-align: center;*/
}
#dv_tooltip p, .dvForm_imagecheck p{
    display: block;
    max-width: 300px;
}
#dv_tooltip div label , .dvForm_imagecheck label{
    width: 120px;
    display: block;
    float: left;
}