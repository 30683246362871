@charset "UTF-8";

@font-face {
  font-family: 'fontawesome';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("/dist/fonts/fa-regular-400.eot");
  src: url("/dist/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), 
       url("/dist/fonts/fa-regular-400.woff2") format("woff2"), 
       url("/dist/fonts/fa-regular-400.woff") format("woff"), 
       url("/dist/fonts/fa-regular-400.ttf") format("truetype"), 
       url("/dist/fonts/fa-regular-400.svg#fontawesome") format("svg"); 
}
.far {
  font-family: 'fontawesome';
  font-weight: 400; 
}

@font-face {
  font-family: 'fontawesome';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("/dist/fonts/fa-solid-900.eot");
  src: url("/dist/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), 
       url("/dist/fonts/fa-solid-900.woff2") format("woff2"), 
       url("/dist/fonts/fa-solid-900.woff") format("woff"), 
       url("/dist/fonts/fa-solid-900.ttf") format("truetype"), 
       url("/dist/fonts/fa-solid-900.svg#fontawesome") format("svg"); 
}
.fa,
.fas {
  font-family: 'fontawesome';
  font-weight: 900; 
}


/*** main *********************************************************************/
$background-color:                      #fff;

$font-light:                            'UniversLTPro-45Light', sans-serif;
$font-standard:                         'UniversLTPro-55Roman', sans-serif;
$font-bold:                             'UniversLTPro-65Bold', sans-serif;


$font-color:                            #333333;
$font-size:                             18px;
$line-height:                           1.5;

$space:                                 20px;
$content-margin:                        8%;

$highlight-color1:                      #88bd24;
$highlight-color2:                      #333333;

$link-color:                            $highlight-color1;

/*** colors *******************************************************************/

$color-green:                           #88bd24;
$color-lightgreen:                      #d0e69a;
$color-darkgrey:                        #666;
$color-grey:                            #c9c8c5;
$color-midgrey:                         #e5e5e5;
$color-white:                           #fff;
$color-black:                           #000;
$color-lightgrey:                       #fafafa;
$color-lightgrey2:                      #efefef;
$color-darkgreen:                       #009900;
$color-red:                             #cc0000;
$color-yellow:                          #ff9933;
$color-blue:                            #0043ff;
$color-brown:                           #e0a672;
$color-darkbrown:                       #664a32;


$color-mediumgrey:                      #999;
$color-lightblack:                      #333;      