article{
    &.forum-item{
        margin-bottom: 20px;
        
        header{
            padding: 5px 10px;
            background: $color-midgrey;
            height: auto;
            font-size: 16px;
            
            h2{
                font-size: 18px;
                margin: 0;
                font-family: $font-standard;
            }
            .smaller{
                font-size: 14px;
            }
            .profilepic {
                width: 45px;
                height: 45px;
                background: $color-white;
                float: right;
                                
                img, svg{
                    width: 45px;
                    height: 45px;
                    border: 1px solid #abadb0;
                }
            }
        }
        footer{
            margin: 0 0 $space 0;
            padding: $space/2 0;
            background: none;
            border-top: solid 1px $color-grey;
            height: auto;
            min-height: auto;
            
            .footer-note{
                background: $color-lightgreen;
                padding: $space/2;
                display: inline-block;
            }
        }
        
        &.inactive{
            header{
                background: $color-lightgreen;
            }
        }
    }
    .forum-content{
        margin: $space $space/2;
        
        .bbquote{
            padding: 10px;
            background: $color-lightgrey;
        }
        
        strong{
            font-family: $font-bold;
            font-weight: normal;
        }
        .forum-attachment{
            display: block;
            margin-bottom: 10px;
            
            svg{
                width: 25px;
                height: 25px;
                fill: $color-green;
                vertical-align: middle;
                margin-right: $space;
            }
        }
    }
    
}

.forum_thema_my {
    display: inline-block;
    width: calc( 100% - 185px);
    
    
}

.forum_beitrag_my_head {
    background: #e5e5e5;
    padding: 5px 10px;
    margin-bottom: 10px;
}

.forum_beitrag_my {
    margin-left: 15px;
}
.bbstyles{
    overflow: hidden;
    
    .bbstyle{
        background: $color-grey;
        padding: 5px 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        display: inline-block;
    }
    .bbemoji{
        display: inline-block;
        
        .emojione{
            margin-right: 5px;
            margin-bottom: 10px;
            cursor: pointer;
        }
    }
}