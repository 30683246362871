#history{
    position: relative;
    left: auto;
    top: auto;
    width: 100%;
    height: auto;
    background: #fff;

    &.fullscreen{
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        padding: 10px;
    }

}
#history-map {
    width: 100%;
    min-height: 50px;
    background: #fff;
    position: relative;
    
    .hl{
        font-size: 16px;
        background: none;
        padding: 0;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        
        &:before{
            display: none;
        }
        /*
        padding: 20px 0 0 20px;
        white-space:nowrap;
        position: relative;
        backface-visibility: */
    }
    .timeline{
        background: rgba(255,255,255,0.7);
        z-index: 999;
    }
}
#history-nav{
    width: 100%;
    margin-bottom: 20px;
    
    i{
        width: 25px;
        display: inline-block;
    }
    
    #history-controls{
        float: right;
        
        i{
            width: auto;
            background-color: $color-grey;
            border-radius: 0;
            color: #fff;
            margin: 0 5px 5px 0;
            padding: 5px;
            cursor: pointer;
            font-size: 23px;
            &.compress{
                display: none
            }

        }

    }
    
    .history-check{
        display: inline-block;
        padding: 5px 15px;
        background: $color-green;
        color: #fff;
        cursor: pointer;
        text-decoration: none;
        margin: 0 5px 5px 0;
    }
}